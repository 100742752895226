import Notification from '../Notification';
import { NotificationTypes } from 'enums/notificationTypes';
import { useNavigate } from 'react-router-dom';
import { NavigationRoutes } from 'routes/routeConstants/appRoutes';
// import { AuthContext } from 'context/AuthContext';

const LogoutUser = (): void => {
	const navigate = useNavigate();
	// const { setAuthenticated } = AuthContext();
	localStorage.clear();
	// setAuthenticated({}, false);
	navigate(NavigationRoutes.LOGIN);

	// eslint-disable-next-line @typescript-eslint/no-floating-promises
	Notification({
		message: 'Logout',
		description: 'user loggedout successfully',
		type: NotificationTypes.SUCCESS,
	});
};

export default LogoutUser;
