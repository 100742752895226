import { useState, useEffect } from 'react';
import { User } from 'models/user.model';

export const userDetails = () => {
	const [user, setUser] = useState<User>();

	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem('user') as string);

		if (userData !== undefined) {
			setUser(userData);
		}
	}, []);

	return { user };
};
