import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Refunds } from '../../models/Refunds/refunds.model';
import { Pagination } from './../../models/Pagination/pagination.model';
import Notification from 'shared/components/Notification';
import { NotificationTypes } from 'enums/notificationTypes';

const RefundsService = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const [refunds, setRefunds] = useState<Refunds[]>([]);
	const [walletAmount, setWalletAmount] = useState<number>(0);
	const [otpRequestSuccess, setOtpRequestSuccess] = useState(false);
	const [otpVerificationSuccess, setOtpVerificationSuccess] = useState(false);
	// const [refundAmount, setRefundAmount] = useState<number | undefined>();
	const [refundsPagination, setRefundsPagination] =
		useState<Pagination | null>();

	const fetchRefunds = async (params?: {
		search?: string;
		page?: number;
		sort_by?: string;
		sort_dir?: string;
		month?: number;
		year?: number;
	}) => {
		try {
			setLoading(true);
			setButtonLoading(true);
			const response = await axiosInstance.get(ApiRoutes.REFUNDS, { params });
			const refunds = deserialize(
				Refunds,
				response.data.data.data
			) as Refunds[];
			const pagination = deserialize(Pagination, response.data);
			setRefundsPagination(pagination);
			setRefunds(refunds);
			setLoading(false);
			setButtonLoading(false);
		} catch (error) {
			setRefundsPagination(null);
			setRefunds([]);
			setLoading(false);
			setButtonLoading(false);
		} finally {
			setLoading(false);
			setLoading(false);
			setButtonLoading(false);
		}
	};
	const getWalletAmount = async () => {
		try {
			const res = await axiosInstance.get(ApiRoutes.DASHBOARD);
			setWalletAmount(res.data.data.wallet);
		} catch (err:any) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: err.message,
				description:'Something went wrong',
				type: NotificationTypes.ERROR,
			});
		} finally {
			console.log('err.message');
		}
	};

	const requestRefund = async (params?: { refund_amount?: number }) => {
		try {
			setButtonLoading(true);
			// setRefundAmount(params?.refund_amount);
			const res = await axiosInstance.post(ApiRoutes.REFUND_OTP, params);
			if (res.status === 200) {
				setOtpRequestSuccess(true);
				setButtonLoading(false);
			}
		} catch (error: any) {
			const { response } = error;
			setButtonLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const verifyOtp = async (params?: {
		otp?: number;
		refund_amount?: number;
	}) => {
		try {
			setButtonLoading(true);
			const res = await axiosInstance.post(ApiRoutes.REFUNDS, params);
			if (res.status === 201) {
				setOtpVerificationSuccess(true);
				setButtonLoading(false);
			}
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: res.data.status,
				description: res.data.message,
				type:
					res.status === 201
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});
		} catch (error: any) {
			setButtonLoading(false);
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	return {
		loading,
		refunds,
		buttonLoading,
		fetchRefunds,
		refundsPagination,
		getWalletAmount,
		walletAmount,
		requestRefund,
		verifyOtp,
		otpRequestSuccess,
		otpVerificationSuccess,
	};
};

export default RefundsService;
