import { Table } from 'antd';
import useResponsive from 'shared/Hooks/useResponsive';
import React from 'react';
// import TableLoader from './TableLoader';
import { TableSkeleton } from './TableSkeleton';
// import { TableSkeleton } from './TableSkeleton';

type tableProps = {
	columns: any[];
	title: JSX.Element;
	data: any;
	onchange: any;
	positionForJumpers?: ['topRight' | 'bottomRight'] | undefined;
	className?: string;
	total?: number;
	isLoading?: boolean;
	onRowClickable?: boolean;
	onRowClick?: (e: any) => void;
	showPagination?: boolean
};

const TableUpdated: React.FC<tableProps> = ({
	total,
	className,
	columns,
	title,
	onchange,
	data,
	positionForJumpers = ['bottomRight', 'topRight'],
	isLoading = false,
	onRowClick = () => { },
	showPagination = true
}) => {

	const { isMobile } = useResponsive();
	return (
		<div>
			<Table
				columns={columns}
				title={isMobile ? undefined : () => title}
				dataSource={isLoading ? [] : data}
				onChange={onchange}
				scroll={{ x: 600 }}
				showHeader={!isMobile}
				showSorterTooltip={false}
				onRow={record => ({
					onClick: e => onRowClick(record)
				})}
				pagination={!isMobile && showPagination ? {
					total,
					pageSize: 10,
					showPrevNextJumpers: true,
					showSizeChanger: false,
					position: positionForJumpers,
					size: 'small',
				} : false}
				locale={{
					emptyText: isLoading ? <TableSkeleton /> : null
				}}
				className={className}
			// loading={{ indicator: <TableSkeleton />, spinning: isLoading }}
			/>
		</div>
	);
};

export default TableUpdated;
