import { Row, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import React from 'react';
import Icon, { CloseOutlined } from '@ant-design/icons';
import { FileUpload } from 'resources/Icons';
import './uploadImage.scss';
import { RcFile } from 'antd/lib/upload';



type fileListProps = {
	length?: number;
	setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
	fileList: UploadFile[];
	allowDeletion?: boolean;
	uploadFunction?: ((file: RcFile) => string);
	showForProfile?: boolean;
	accept?: string
};
const UploadImage: React.FC<fileListProps> = ({ length = 4, setFileList, fileList, allowDeletion = true, uploadFunction, showForProfile = false, accept = '.jpg,.jpeg,.png,.heic' }) => {

	const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};
	const deleteImage = (id: any) => {
		const filteredItems = fileList.filter(i => i.uid !== id)
		setFileList(filteredItems);
	}

	const ImageForUpload = ({ url, id }: { url: string | undefined, id: any }) => {

		return (
			<div className="ant-upload-list-picture-card-container">
				<div className="ant-upload-list-item ant-upload-list-item-success ant-upload-list-item-list-type-picture-card">
					<div className="ant-upload-list-item-info">
						<span className="ant-upload-span">
							<img src={url} alt="" className="ant-upload-list-item-image" />
						</span>
						{allowDeletion && (
							<span className="ant-upload-list-item-actions">
								<Icon component={() => <CloseOutlined />} onClick={() => deleteImage(id)} />
							</span>
						)}
					</div>
				</div>
			</div>
		);
	};

	return (
		<Upload
			action={undefined}
			accept={accept}
			listType={"picture-card"}
			fileList={fileList}
			onChange={onChange}
			itemRender={(_, index) => ImageForUpload({ url: index.thumbUrl, id: index.uid })}
			className={length < 2 && !showForProfile ? 'fullWidth' : undefined}
			onRemove={(item) => console.log(item)}
			beforeUpload={uploadFunction ? (file) => {
				uploadFunction(file)
				return false
			} : () => false}
		>
			{fileList.length < length && (
				<Row justify='center' align='middle'>

					<FileUpload />
				</Row>
			)
			}
		</Upload>
	);
};

export default UploadImage;
