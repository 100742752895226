import { serializable, alias, primitive } from 'serializr';

export class Building {
	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('address', primitive()))
	address?: string;

	@serializable(alias('image_url', primitive()))
	imageUrl?: string;

	@serializable(alias('merchant_id', primitive()))
	merchantId?: string;

}
