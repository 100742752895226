import { Attachment } from 'models/Attachment/attachment.model';
import { serializable, alias, primitive, object, list } from 'serializr';
import { Statuses } from './statuses.model';

export class MaintenanceTicket {
	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('status_id', primitive()))
	statusId?: number;

	@serializable(alias('location_id', primitive()))
	locationId?: string;

	@serializable(alias('location_name', primitive()))
	locationName?: string;

	@serializable(alias('statuses', list(object(Statuses))))
	statuses?: Statuses[];

	@serializable(alias('ticket_category', primitive()))
	ticketCategory?: string;

	@serializable(alias('profile_picture', primitive()))
	profilePicture?: string;

	@serializable(alias('attachments', list(primitive())))
	attachments?: Attachment[] = [];

	// @serializable(alias('attachments', list(object([]))))
	// attachments?: Attachment;

	@serializable(alias('raised_on', primitive()))
	raisedOn?: string;

	@serializable(alias('id', primitive()))
	id?: string;

	@serializable(alias('booking_id', primitive()))
	bookingId?: string;
	
	@serializable(alias('ticket_id', primitive()))
	ticketId?: string;

	@serializable(alias('description', primitive()))
	description?: string;
}
