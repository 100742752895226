import { Row, Col, Tabs } from 'antd';
import React from 'react';
import { Image } from 'shared/components/BackgroundImage/Image';
import { VisitorInviteComponent } from './VisitorInvites';
import { VisitorLogs } from './VisitorLogs';
import './visitor.scss';
import { screenTypes } from 'enums/screenTypes';
import Navbar from 'shared/components/Navbar';
import useResponsive from 'shared/Hooks/useResponsive';
import background from "resources/images/containerBackground.png"
// const { TabPane } = Tabs;
const items = [
	{
		label: screenTypes.VISITOR_INVITES,
		key: screenTypes.VISITOR_INVITES,
		children: <VisitorInviteComponent />,
	},
	{
		label: screenTypes.VISITOR_LOGS,
		key: screenTypes.VISITOR_LOGS,
		children: <VisitorLogs />,
	},
];
export const VisitorDetails = (): JSX.Element => {
	const { isMobile } = useResponsive();
	return (
		<>
			{!isMobile && (<Image
				src={background}
			>
				<Navbar />
			</Image>)}
			<Row className="main-container-visitor">
				<Col span={24}>
					<Tabs
						defaultActiveKey={screenTypes.VISITOR_INVITES}
						moreIcon={false}
						items={items}
					/>

				</Col>
			</Row>
		</>
	);
};
