import { serializable, alias, primitive } from 'serializr';

export class Refunds {
	@serializable(alias('building_name', primitive()))
	buildingName?: string;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('is_refunded', primitive()))
	isRefunded?: number;
}
