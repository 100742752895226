import useResponsive from 'shared/Hooks/useResponsive';
import React, { useState, useEffect } from 'react';
import {
	Row,
	Col,
	Typography,
	Image as AntImage,
	Tag,
	Select,
	Space,
	Divider,
} from 'antd';
import Navbar from 'shared/components/Navbar';
import { Image } from 'shared/components/BackgroundImage/Image';
import {
	KnowYourCommunity as KnowYourCommunityIcon,
	EmailCustom,
	Building,
	CircleAdd,
	SearchIcon,
	Interests,
	Arts,
	OtherInterests,
	Sports,
} from 'resources/Icons';
// import { SearchOutlined } from '@ant-design/icons';
import './knowyourcommunity.scss';
import KnowYourCommunityImage from 'resources/images/knowYourCommunity.svg';
import ModalComponent, { ModalSize } from 'shared/components/Modal';
// import { InputFieldForEmail } from 'shared/components/InputField';
import ButtonBase from 'shared/components/Button';
import { ButtonTypes } from 'enums/buttonTypes';
import KnowyourcommunityService from 'services/KnowyourcommunityService/knowYourCommunity.service';
import { TableSkeleton } from 'shared/components/Table/TableSkeleton';
import { InputFieldWithIconForCommmunity } from 'shared/components/InputField';
import { CommonInterest } from 'models/CommonInterest/commonInterest.model';
import BookingsSkeleton from 'views/Bookings/BookingsSkeleton';
import SadAnimation from 'shared/components/Animations/SadAnimations';
import EnableDisableSwitch from 'views/AppComponents/EnableDisableSwitch';
import background from "resources/images/containerBackground.png"
import SelectInput from 'shared/components/Select/SelectInput';
const { Title, Text } = Typography;
const { Option } = Select;
export const KnowYourCommunity = () => {
	const [showInterestModal, setshowInterestModal] = useState(false);
	const [search, setSearch] = useState();
	const [showHideProfileFullModal, setShowHideProfileFullModal] =
		useState(false);
	const [tempCommonInterestData, setTempCommonInterestData] =
		useState<CommonInterest>();
	const {
		buttonLoading,
		fetchAllInterests,
		userInterests,
		getUserInterests,
		interestsList,
		updateInterests,
		interestListFlattened,
		getCommonInterests,
		commoninterests,
	} = KnowyourcommunityService();
	const showHideInterestModal = () => {
		setshowInterestModal(!showInterestModal);
		if (!interestsList) {
			void fetchAllInterests();
		}
	};
	const changeShowHideProfileModal = () =>
		setShowHideProfileFullModal(!showHideProfileFullModal);
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	function debounce<F extends (...params: any[]) => void>(
		fn: F,
		delay: number
	) {
		let timeoutID: number = 0;
		return function (this: any, ...args: any[]) {
			clearTimeout(timeoutID);
			timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
		} as F;
	}

	const processChange = debounce((e) => onSearchChange(e), 300);

	const onSearchChange = (e: any) => {
		setSearch(e);
	};
	useEffect(() => {
		if (search) {
			const params = {
				search,
			};
			void getCommonInterests(params);
		}
	}, [search]);

	useEffect(() => {
		void getCommonInterests();
		void getUserInterests();
	}, []);

	const tagRender = (props: any) => {
		// eslint-disable-next-line react/prop-types
		const { label, value, closable, onClose } = props;
		const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				color={value}
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				className="tagWithGreyText"
			>
				{label}
			</Tag>
		);
	};
	const [selectedInterests, setSelectedInterests] = useState<any[]>([]);

	const addToSelectedInterests = (i: { name: string; type_id: number }) => {
		if (selectedInterests.find((j: any) => j.name === i.name)) {
			const filteredArrayItems = selectedInterests.filter(
				(j: any) => j.name !== i.name
			);
			setSelectedInterests(filteredArrayItems);
		} else {
			setSelectedInterests([...selectedInterests, i]);
		}
	};
	const getColor = (i: any): string => {
		const isSelected = selectedInterests.find((j) => j.name === i.name);
		const color = isSelected ? '#AEB29B' : '#F7F7F7';
		return color;
	};
	const TitleComponentForInterest = () => {
		return (
			<Space direction="horizontal">
				<Interests />
				<Text>Add Your Interest</Text>
			</Space>
		);
	};

	const handleSelectChangeForUpdate = (i: any) => {
		const newItems = [];
		for (const j of i) {
			console.log(i[0]);
			const item = interestListFlattened?.find((k) => k.name === j);

			if (!item) {
				newItems.push({ name: i[0] });
			} else {
				newItems.push(item);
			}
		}
		setSelectedInterests(newItems);
	};

	const updateInterestsForUser = async () => {
		const data = selectedInterests.map((i: any) => {
			return { name: i?.name, type: i?.type_id };
		});

		const params = {
			interests: data,
		};
		const response = await updateInterests(params);
		if (response === 200) {
			showHideInterestModal();
			void getUserInterests();
		}
	};
	const InterestPersoncard = ({ data }: { data: CommonInterest }) => {
		return (
			<div className="interest-personal-card">
				<Space direction="vertical" className="w-100">
					<Row
						style={{ width: '100%' }}
						align={isMobile?"middle":"bottom"}
						justify="start"
						gutter={[16,0]}
					>
						<Col
							span={8}
							xs={8}
							sm={8}
							lg={8}
							xl={24}
							xxl={12}
							className="interest-card-image-container"
						>
							<Row align="middle" justify="center" style={{ width: '100%' }}>
								<AntImage
									src={data.profile_picture}
									width={'100%'}
									className={isMobile ? "card-image-interest-card2-mobile preview" : "card-image-interest-card2 preview"}
									preview={false}
								/>
							</Row>
						</Col>
						<Col
							span={16}
							xs={16}
							sm={16}
							lg={16}
							xl={24}
							xxl={12}
							className="interest-card-content-container"
						>
							<Row gutter={[16, 8]} justify="start" align="bottom">
								<Col span={24}>
									<Text className="blueColor">{data.name}</Text>
								</Col>
								<Col span={24}>
									<Row>
										<Col span={3} className="mt-7">
											<Building />
										</Col>
										<Col span={21}>
											<Text className="greyText">{data.building}</Text>
										</Col>
									</Row>
								</Col>
								<Col span={24}>
									<Row>
										<Col span={3} className="mt-7">
											<EmailCustom />
										</Col>
										<Col span={21}>
											<Text ellipsis={isMobile}>{data.email}</Text>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
					<Divider className="no-color" />
					<Row gutter={[8, 8]} justify="start">
						{data?.interest?.slice(0, 3).map((i: any, j) => {
							const key = Object.keys(i)[0];
							// ts-ignore
							return (
								<Col key={j}>
									<Tag
										key={j}
										className={`booking-card-tag-success-community ${
											i[key] === 1 ? 'tagWithWhiteText' : 'tagWithGreyText'
										}`}
									>
										{key}
									</Tag>
								</Col>
							);
						})}
					</Row>
					<Divider className="no-color" />
					<Row>
						{(data?.interest !== undefined && data?.interest?.length >= 4) ||
						isMobile ? (
							<>
								<Tag
									className={`booking-card-tag-success-community tagWithGreenText`}
									onClick={() => {
										setTempCommonInterestData(data);
										changeShowHideProfileModal();
									}}
								>
									View More...
								</Tag>
							</>
						) : (
							<></>
						)}
					</Row>
					<Divider className="no-color" />
					{/* <Row gutter={[16, 16]} justify="start" align='middle'>
                    <Col span={3}>
                        <Email />
                    </Col>
                    <Col span={19}>
                        <Text className="blackText" ellipsis={true}>{data.email}</Text>
                    </Col>



                </Row> */}
				</Space>
			</div>
		);
	};
	const HobbyCard = ({
		imageUrl,
		name,
		tags,
		data,
	}: {
		imageUrl: string;
		name: string;
		tags: [{ [key: string]: number }];
		data: CommonInterest,
	}) => {
		return (
			<Row align="middle" justify="start" className={isMobile ? "hobby-card-mobile-section": ""}>
				<Col  xs={18} xl={7} xxl={5} style={{ width: '100%' }}>
					<AntImage
						width={'90%'}
						src={imageUrl}
						preview={false}
						className={isMobile ? "hobby-card-image-mobile": "hobby-card-image"}
					/>
				</Col>
				<Col  xs={24} xl={16} xxl={18} offset={1} className={isMobile ? "hobby-card-mobile__details" : ""}>
					<Space direction="vertical">
						<Text className={isMobile ? "hobby-card-mobile__name blueColor" : "blueColor"}>{name}</Text>
						<Row gutter={[16, 16]} className={isMobile ? "hobby-card-mobile__interests":""} >
							{tags.slice(0,5).map((i, j) => {
								const key = Object.keys(i)[0];
								
								return (
									<Tag key={j} className="tagWithGreyText hobby-card-tag-space">
										{key}
									</Tag>
									
								);
							})}
							{data?.interest !== undefined && data?.interest?.length >= 5 ? ( 
								<>
									<Tag
										className={`booking-card-tag-success-community tagWithGreenText`}
										onClick={() => {
										setTempCommonInterestData(data);
										changeShowHideProfileModal();
									}}
							>
								View More...
							</Tag></>
								) : (<> </>)
							}

						</Row>
					</Space>
				</Col>
			</Row>
		);
	};
	const { isMobile } = useResponsive();
	return (
		<>
			<ModalComponent
				visible={showHideProfileFullModal}
				okButtonText={''}
				shouldModalOpen={changeShowHideProfileModal}
				showFooter={false}
			>
				<div className={isMobile ? "interest-personal-card-modal-mobile": "interest-personal-card-modal"}>
					<Space direction="vertical">
						<Row
							style={{ width: '100%' }}
							align="middle"
							justify="start"
							gutter={[8, 16]}
						>
							<Col span={9}>
								<Row align="middle" justify="center" style={{ width: '100%' }}>
									<AntImage
										src={tempCommonInterestData?.profile_picture}
										width={'100%'}
										className="card-image-interest-card preview"
										preview={false}
									/>
								</Row>
							</Col>
							<Col xs={15} sm={15} md={15}>
								<Row  justify="start" align="bottom">
									<Col span={24}>
										<Text className="blueColor">
											{tempCommonInterestData?.name}
										</Text>
									</Col>
									<Col span={24}>
										<Space direction="horizontal">
											<Building />
											<Text className="greyText">
												{tempCommonInterestData?.building}
											</Text>
										</Space>
									</Col>
									<Col span={24}>
										<Row>
											<Col span={3}>
											<EmailCustom />
											</Col>
											<Col span={21}>
											<Text className="blackText">
											   {tempCommonInterestData?.email}
											</Text>
											</Col>
										</Row>
										
									</Col>
								</Row>
							</Col>
						</Row>
						<Divider className="no-color" />
						<Row gutter={[8, 8]} justify="start">
							{tempCommonInterestData?.interest?.map((i: any, j) => {
								const key = Object.keys(i)[0];
								// ts-ignore
								return (
									<Col key={j}>
										<Tag
											key={j}
											className={`booking-card-tag-success-community ${
												i[key] === 1 ? 'tagWithWhiteText' : 'tagWithGreyText'
											}`}
										>
											{key}
										</Tag>
									</Col>
								);
							})}
						</Row>
						<Row></Row>
						<Divider className="no-color" />
					</Space>
				</div>
			</ModalComponent>

			{!isMobile && (
				<Image
					src={background}
				>
					<Navbar />
				</Image>
			)}
			<Row className="main-container-community">
				{userInterests && userInterests.length <= 0 ? (
					<>
						<Col
							span={24}
							xs={24}
							md={12}
							className="general_info_col_community_components"
						>
							<Space direction="vertical">
								<Space direction="horizontal">
									<KnowYourCommunityIcon color="#65BCB0" />
									<Title level={5} className="primary__text">
										Connect with people through interest
									</Title>
								</Space>
								<Text type="secondary" className="secondary__text">
									Start connecting with people by adding interest and enabling
									public view
								</Text>

								<Row gutter={[32, 42]}>
									<Col span={24}>
										<div
											className="interest-row"
											onClick={showHideInterestModal}
										>
											<Row justify="center" align="middle" gutter={8}>
												<Col>
													<CircleAdd />
												</Col>
												<Col>
													<Title level={5} className="greenText">
														Add Interest
													</Title>
												</Col>
											</Row>
										</div>
									</Col>
									<Col span={24}>
										<Row>
											<Col span={22}>
												<Text className="primary__text">
													{' '}
													Share My contacts
												</Text>
											</Col>
											<Col span={2}>
												<EnableDisableSwitch />
											</Col>
											<Col span={24} className='share-contacts-content'>
												<Text type="secondary" className="secondary__text">
												By enabling, only your Name, Property Details and Interests will be shared publicly
												</Text>
											</Col>
										</Row>
									</Col>
									<Col span={24}>
										<Row justify="center">
											<AntImage src={KnowYourCommunityImage} preview={false} />
										</Row>
									</Col>
								</Row>
							</Space>
						</Col>

						{isMobile && <Divider className="no-color-community" />}

						<Col
							span={24}
							md={11}
							xl={10}
							xs={24}
							offset={`{isMobile?0:1}`}
							className="general_info_col_community_components rightCard"
						>
							<Space direction="vertical">
								<Text type="secondary" className="secondary__text">
									There are {commoninterests?.length ?? 0} people in your place
									ready to share a hobby with you
								</Text>
								<Row gutter={[16, 16]}>
									{commoninterests?.map((i, j) => {
										return (
											<Col span={24} key={j} className={isMobile ? "hobby-card-container" : ""}>
												<HobbyCard
													key={j}
													imageUrl={i.profile_picture ?? ''}
													name={i.name ?? ''}
													tags={i.interest ?? [{}]}
													data={i}
												/>{' '}
											</Col>
										);
									})}
								</Row>
							</Space>
						</Col>
					</>
				) : (
					<>
						<Row
							className=".general_info_col_community_components"
							gutter={[0, 16]}
						>
							

							<Row style={{ width: '100%' }}>
								<Col span={17} md={17} xs={24}>
									<Row>
										<Space direction="horizontal">
											<KnowYourCommunityIcon color={'#65BCB0'} />
											<Title level={5} className="blackText">
												List of people in your place with the common interest
											</Title>
										</Space>
										{
											!isMobile ? <Text className='edit-interest-btn'  onClick={showHideInterestModal}>Edit Interest
											</Text> : ""
										}
									</Row>
								</Col>
								<Col span={7} md={7} xs={24}>
									<InputFieldWithIconForCommmunity
										type="text"
										name="search"
										prefix={<SearchIcon />}
										placeholder="Search by interest"
										allowClear={true}
										onChange={(e: any) => processChange(e.target.value)}
									/>
								</Col>
							</Row>
							<Row>
								<Space direction={isMobile ? 'vertical' : 'horizontal'}>
									<Space direction="horizontal">
										<Text className="primary__text">Share my contacts</Text>
										<EnableDisableSwitch />
										{
											isMobile ? <Text className='edit-interest-btn'  onClick={showHideInterestModal}>Edit Interest
											</Text> : ""
										}
									</Space>
									<Text className="secondary__text">
										(By enabling, only your Name, Property Details and Interests
										will be shared publicly)
									</Text>
								</Space>
							</Row>	
							{commoninterests === undefined ||
								(commoninterests.length <= 0 && (
									<Row
										className='sad-animation-container'
										justify="center"
										align="middle"	
									>
										<Col md={10} xs={24} sm={24}>
											<Row justify={isMobile?"center":"end"}>
												<SadAnimation />
											</Row>
										</Col>
										<Col md={14} xs={24} sm={24}  className="sad-animation-text">
											<Row justify={isMobile?'center':'start'} align={"middle"}>
												<Text>
													Sorry, we dont find any people matching your interest
												</Text>
											</Row>
										</Col>
										
									</Row>
								))}	
							<Col span={24}>
								{buttonLoading ? (
									<BookingsSkeleton />
								) : (
									<Row
										style={{ width: '100%' }}
										gutter={[32, 8]}
										justify={isMobile ? 'center' : undefined}
									>
										{commoninterests?.map((i, j) => {
											return (
												<Col xs={24} sm={24} md={20} lg={12} xl={7} xxl={7} key={j}>
												
													
													<InterestPersoncard data={i} />
												</Col>
											);
										})}
									</Row>
								)}
							</Col>
							</Row>
						
					</>
				)}
			</Row>
			<ModalComponent
				showFooter={false}
				okButtonText="Search"
				onButtonOkClick={() => {}}
				cancelButtonText="Cancel"
				visible={showInterestModal}
				shouldModalOpen={showHideInterestModal}
				title={<TitleComponentForInterest />}
				size={ModalSize.MEDIUM}
			>
				{buttonLoading ? (
					<>
						<TableSkeleton />
					</>
				) : (
					<Row
						gutter={[32, 16]}
						style={{ paddingLeft: '1rem', alignItems: 'left' }}
					>
						<Col span={19} xs={24}>
							<SelectInput
								mode="tags"
								className="knowYourCommunitySelect"
								tagRender={tagRender}
								disabled={selectedInterests.length >= 5}
								style={{ width: '100%' }}
								value={selectedInterests.map((i) => i.name)}
								placeholder="Select upto 5 tags"
								onChange={handleSelectChangeForUpdate}
							>
								{interestListFlattened?.map((i: any) => {
									return (
										<Option key={i?.name} value={i.name}>
											{i?.name}
										</Option>
									);
								})}
							</SelectInput>
						</Col>
						<Col span={4}>
							<ButtonBase
								type={ButtonTypes.PRIMARY}
								loading={buttonLoading}
								text="Update"
								onclick={() => {
									void updateInterestsForUser();
								}}
							/>
						</Col>
						{interestsList?.map((i, j) => {
							return (
								<Row key={i.id} gutter={[8, 8]} >
									<Space direction="horizontal">
										{j === 0 ? (
											<Sports />
										) : j === 1 ? (
											<Arts />
										) : (
											<OtherInterests />
										)}
										<Text className="primary__text">{i.name}</Text>
									</Space>
									<Col span={24}>
										<Row gutter={[20, 16]}>
											{i.interests.map((j) => {
												const color = getColor(j);
												return (
													<Col key={j.name}>
														<Tag
															key={j.id}
															color={color}
															className="tagWithGreyText"
															style={{ cursor: 'pointer' }}
															onClick={() => addToSelectedInterests(j)}
														>
															{j.name}
														</Tag>
													</Col>
												);
											})}
										</Row>
									</Col>
								</Row>
							);
						})}
					</Row>
				)}
			</ModalComponent>
		</>
	);
};
