import React, { memo, useEffect } from 'react';
import { Row, Col, Divider } from 'antd';
import NoDataAnimation from 'shared/components/Animations';
import BookingImageCard, { TagText } from 'shared/components/Cards';
import WaitingListService from 'services/WaitinglistService/waitingList.service';
import BookingsSkeleton from './BookingsSkeleton';
import moment from 'moment';
import { usdollar } from 'views/Home';
import { DateFormats } from 'enums/dateFormat';

const statusText: { [x: number]: TagText } = {
	1: TagText.Cancelled,
	0: TagText.Pending,
};
const getStatusText = (statusCode: number | undefined) => {
	if (statusCode === undefined) return;
	return statusText[statusCode];
};

const WaitingListView = () => {
	const { fetchWaitinglists, waitingLists, loading } = WaitingListService();
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		fetchWaitinglists();
	}, []);

	return loading ? (
		<BookingsSkeleton />
	) : waitingLists !== null && waitingLists?.length >= 1 ? (
		<>
			<Row gutter={[12, 15]}>
				{waitingLists.map((item, index) => {
					return (
						<>
						<Col xs={24} md={24} xl={24} xxl={24} key={index} >
							<BookingImageCard
								title={item.residence}
								tagText={getStatusText(item.status)}
								bookingId={item.bookingId}
								roomType={item.roomType}
								startDate={moment(item.actualCheckinDate).format(DateFormats.SECONDARY)}
								endDate={moment(item.actualCheckoutDate).format(DateFormats.SECONDARY)}
								monthlyRent={usdollar.format(item?.amount ?? 0)}
								roomNo={item.roomNumber}
							/>
							
						</Col>
						<Divider />
						</>
					);
				})}
			</Row>
			
		</>
	) : (
		<>
			<NoDataAnimation />
		</>
	);
};

export default memo(WaitingListView);
