import React from 'react';
import { Image } from 'shared/components/BackgroundImage/Image';
import { Row, Col, Collapse, Typography } from 'antd';
import { InputFieldWithIcon } from 'shared/components/InputField';
import { SearchOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const { Title } = Typography;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
export default function FAQ() {
	return (
		<>
			<Image
				src={
					'https://images.unsplash.com/photo-1661895613468-137da35b01e3?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb'
				}
			></Image>
			<Row>
				<Col span={24}>
					<Row></Row>
				</Col>
				<Col
					span={22}
					style={{
						marginLeft: '2rem',
						marginRight: '2rem',
						borderRadius: '1rem',
						backgroundColor: '#fff',
						padding: '1.5rem',
						boxShadow: '8px 10px 35px #0000000A',
						zIndex: '99',
						marginTop: '-1rem',
					}}
				>
					<Row>
						<Col span={14}>
							<Title level={4}>Frequently Asked Questions(FAQ)</Title>
						</Col>
						<Col span={10}>
							<InputFieldWithIcon
								type="text"
								name="search"
								prefix={<SearchOutlined />}
								placeholder="search"
								allowClear={true}
							/>
						</Col>
					</Row>
					<Collapse defaultActiveKey={['1']} ghost accordion>
						<Panel header="This is question one" key="1">
							<p>{text}</p>
						</Panel>
						<Panel header="This is question two" key="2">
							<p>{text}</p>
						</Panel>
						<Panel header="This isquestion three" key="3">
							<p>{text}</p>
						</Panel>
					</Collapse>
				</Col>
			</Row>
		</>
	);
}
