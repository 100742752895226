import React, { ReactNode } from 'react';
import { Modal, Row, Col } from 'antd';

import './modal.scss';
import ButtonBase from '../Button';
import { ButtonTypes } from 'enums/buttonTypes';
export enum ModalSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
}
type modalProps = {
	title?: ReactNode;
	children: ReactNode;
	shouldModalOpen: () => void;
	okButtonText: string;
	cancelButtonText?: string;
	onButtonOkClick?: () => any;
	showFooter?: boolean;
	visible: boolean;
	size?: ModalSize;
	buttonLoading?: boolean;
	buttonLoadingBlock?: boolean;
	buttonDisabled?: boolean;
	buttonType?: 'button' | 'submit' | 'reset' | undefined;
	className?: string;
};
export default function ModalComponent({
	buttonLoading = false,
	size = ModalSize.SMALL,
	visible,
	okButtonText,
	cancelButtonText,
	onButtonOkClick,
	shouldModalOpen,
	showFooter = true,
	title,
	children,
	buttonLoadingBlock = false,
	buttonDisabled = false,
	buttonType = 'button',
	className,
}: modalProps) {
	const getSize =
		size === ModalSize.SMALL
			? 500
			: size === ModalSize.MEDIUM
			? 700
			: size === ModalSize.LARGE
			? 1000
			: 500;

	return (
		<Modal
			title={title}
			visible={visible}
			footer={null}
			onCancel={() => shouldModalOpen()}
			width={getSize}
			className={`modal-changed ${className}`}
			centered={true}
		>
			{children}
			{showFooter && (
				<div className="modal-footer">
					<Row>
						<Col>
							<ButtonBase
								type={ButtonTypes.PRIMARY}
								text={okButtonText}
								onclick={onButtonOkClick}
								loading={buttonLoading}
								block={buttonLoadingBlock}
								disabled={buttonDisabled}
								htmlType={buttonType}
							/>
						</Col>
						<Col>
							<ButtonBase
								type={ButtonTypes.TERTIARY}
								text={cancelButtonText ?? ''}
								onclick={shouldModalOpen}
								ClassName="cancel__button"
							/>
						</Col>
					</Row>
				</div>
			)}
		</Modal>
	);
}
