/* eslint-disable @typescript-eslint/no-misused-promises */
import { Button, Col, Popover, Row, TableProps, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { memo, useEffect, useState } from 'react';
import TableUpdated from 'shared/components/Table';
import useResponsive from 'shared/Hooks/useResponsive';
import Tags from 'shared/components/Tag';
import { TagText } from 'shared/components/Cards';
import { useTitleContext } from 'context/TitleComponentContext';
import { screenTypes } from 'enums/screenTypes';
import InvoicesService from 'services/InvoicesService/invoices.service';
import { Invoices as InvoicesModel } from 'models/Invoices/invoices.model';
import { DownloadOutlined } from '@ant-design/icons';
import { TitleComponentPayments } from './TitleComponentPayments';
import './navAbsolute.scss';
import { ButtonTypes } from 'enums/buttonTypes';
import { usdollar } from 'views/Home';
import moment from 'moment';
import { DateFormats } from 'enums/dateFormat';
import { TableSkeleton } from 'shared/components/Table/TableSkeleton';

const { Text } = Typography;
type DataType = {
	key: React.Key;
	id: number;
	invoiceName: string;
	amount: number;
	invoiceDueDate: string;
	invoiceStatus: string;
};
  

const MobileResponsiveCards = ({ data, loading, downloadStatementPerInvoice}: { data: InvoicesModel[],loading:Boolean, downloadStatementPerInvoice: any}) => {
	const popoverContent = (record: InvoicesModel) => (
		<div>
			<Button
				type={'text'}
				onClick={async () => {
					const params = { id: record?.id };
					void downloadStatementPerInvoice(params);
				}}
			>
				Download
			</Button>
		</div>
	);
	return (loading ? (
		<TableSkeleton />
	) :
		<>
			{data?.map((item, index) => (
				<Row key={index} align="middle" style={{ paddingBottom: '0.5rem' }}>
					<Col span={18} xs={15}>
						<Row gutter={[16, 5]} justify="start">
							<Col span={24}>
								<Text>{item?.invoiceName}</Text>
							</Col>
							<Col span={24}>
								<Text type="secondary">{item?.amount}</Text>
							</Col>
						</Row>
					</Col>
					<Col span={6} xs={9}>
						<Row justify="end" align="middle" gutter={[16, 5]}>
							<Col>
								<Col>
									<Tags tagText={item?.invoiceStatus as TagText} />
									<Popover placement="bottomLeft" content={popoverContent(item)} trigger={"click"} overlayClassName='download-popover-mobile'>
										<Text className='download-dropdown-mobile'>...</Text>
									</Popover>
								</Col>							
							</Col>
							<Col>
								<Text type="secondary">{moment(item?.invoiceDueDate).format(DateFormats.SECONDARY)}</Text>
							</Col>
						</Row>
					</Col>
				</Row>
			))}
		</>
	);
};

enum columnsNames {
	invoiceName = 'invoice',
	amount = 'amount',
	invoiceDueDate = 'due_date',
	invoiceStatus = 'status',
}

enum SorterDirection {
	ascend = 'asc',
	descend = 'desc',
}
const Invoices = () => {
	const { isMobile } = useResponsive();
	const { date, search, selectedTab } = useTitleContext();
	const {
		invoices,
		fetchInvoices,
		loading,
		downloadStatement,
		buttonLoading,
		DownloadInvoiceDisabled,
		downloadStatementPerInvoice,
	} = InvoicesService();
	const [currentPage, setCurrentPage] = useState<number | undefined>();
	const [order, setOrder] = useState<string>();
	const [orderField, setOrderField] = useState<string | undefined>();

	const columns: ColumnsType<DataType> = [
		{
			title: 'ID',
			key: 'index',
			render: (text, record, index) => index + 1,
		},
		{
			title: 'Invoice',
			dataIndex: 'invoiceName',
			sorter: true,
			// sorter: {
			// 	compare: (a, b) => a.chinese - b.chinese,
			// 	//   multiple: 3,
			// },
		},
		{
			title: 'Amount(KSh)',
			dataIndex: 'amount',
			className: 'text-right',
			sorter: true,
			render: (text, record, index) => <>{usdollar.format(record.amount)}</>,

			// sorter: {
			// 	compare: (a, b) => a.math - b.math,
			// 	//   multiple: 2,
			// },
		},
		{
			title: 'Due Date',
			dataIndex: 'invoiceDueDate',
			sorter: true,
			render: (text) => <>{moment(text).format(DateFormats.SECONDARY)}</>,

			// sorter: {
			// 	compare: (a, b) => a.english - b.english,
			// 	//   multiple: 1,
			// },
		},
		{
			title: 'Status',
			dataIndex: 'invoiceStatus',
			sorter: true,
			render: (text) => <Tags tagText={text as TagText} />,
			// render: ({ _, invoiceStatus }) => {
			// 	return <>{invoiceStatus}</>;
			// },
			// sorter: {
		},
		{
			title: 'Statement',
			dataIndex: 'downloadStatement',
			sorter: true,
			render: (text, record, index) => (
				<Button
					type={ButtonTypes.PRIMARY}
					disabled={DownloadInvoiceDisabled}
					onClick={async () => {
						const params = { id: record.id };
						void downloadStatementPerInvoice(params);
					}}
					className="table-title-btn"
				>
					Download
				</Button>
			),
			// render: ({ _, invoiceStatus }) => {
			// 	return <>{invoiceStatus}</>;
			// },
		},
	];

	const onChange: TableProps<DataType>['onChange'] = (
		pagination,
		filters,
		sorter: any,
		extra
	) => {
		setCurrentPage(pagination.current);
		// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
		// @ts-ignore
		setOrder(SorterDirection[sorter.order]);
		// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
		// @ts-ignore
		const columns: any = columnsNames;
		setOrderField(sorter.order !== undefined ? columns[sorter.field] : null);
	};
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		fetchInvoices();
	}, []);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		if (
			order !== undefined ||
			orderField !== undefined ||
			search !== undefined ||
			currentPage !== undefined
		) {
			void fetchInvoices({
				page: currentPage,
				sort_by: orderField,
				sort_dir: order,
				search,
			});
		}
	}, [order, orderField, currentPage, search]);

	useEffect(() => {
		if (selectedTab !== screenTypes.INVOICES) return;
		let year, month;
	  
		if (date) {
		  const parsedDate = moment(date);
		  year = parsedDate.year();
		  month = parsedDate.month() + 1; 
		  void fetchInvoices({ year, month });
		} else {
			void fetchInvoices();
		}
	  }, [date]);

	return invoices !== null ? (
		isMobile ? (
			<>
				<TitleComponentPayments
					buttonText={<DownloadOutlined color="white" />}
					onclick={downloadStatement}
					placeholder='Search by invoices'
				/>
				<MobileResponsiveCards data={invoices ?? ''}  loading={loading} downloadStatementPerInvoice={downloadStatementPerInvoice} />
			</>
		) : (
			<TableUpdated
				columns={columns}
				data={invoices ?? ''}
				title={
					<TitleComponentPayments
						buttonText="Download Statament"
						onclick={downloadStatement}
						loading={buttonLoading}
					/>
				}
				onchange={onChange}
				isLoading={loading}
			/>
		)
	) : (
		<></>
	);
};
export default memo(Invoices);
