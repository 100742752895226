import { Col, Image, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import QcareLogo from 'resources/images/newLogo.svg';
import { useNavigate } from 'react-router-dom';
import useResponsive from 'shared/Hooks/useResponsive';
import UserService from 'services/AuthService/auth.service';
import ButtonBase from 'shared/components/Button';
import { ButtonTypes } from 'enums/buttonTypes';
import InputField from 'shared/components/InputField';
import { Formik, FormikValues, Form } from 'formik';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import './verifyEmail.scss';
import { verifyEmailValidationSchema } from '../AuthWrapper/validations';

const { Text } = Typography;
const VerifyEmail = () => {
	const navigate = useNavigate();
	const { isTablet, isMobile } = useResponsive();
	const { verifyEmail, forgotPasswordGenerateOtp } = UserService();
	const [email, setEmail] = useState('');
	const initialValue = {
		otp: '',
	};

	useEffect(() => {
		const storedEmail = localStorage.getItem('verify-email');
		if (storedEmail) {
			setEmail(storedEmail);
		}
	}, []);

	const submitHandler = async (values?: FormikValues) => {
		const response = await verifyEmail({ otp: values?.otp, qcareEmail: email });
		if (response) {
			navigate(AppRoutes.RESET_PASSWORD);
		}
	};

	const resendOtpHandler = async () => {
		await forgotPasswordGenerateOtp({ qcareEmail: email });
	};

	const handleBackClick = () => {
		navigate(-1);
	};

	return (
		<div>
			<Row
				className="verify-email"
				justify="center"
				align="middle"
				gutter={[16, 32]}
			>
				<Col
					span={isTablet ? 20 : isMobile ? 24 : 9}
					offset={isTablet ? 8 : isMobile ? 2 : 13}
				>
					<Row>
						<Col
							span={15}
							xs={20}
							md={18}
							xl={20}
							className="verify-email__container"
						>
							<Row align="middle" justify="center" gutter={[16, 22]}>
								<Col span={24} className="verify-email__container-logo">
									<Image
										src={QcareLogo}
										className="verify-email__container-image"
										preview={false}
									/>
								</Col>
								<Col className="verify-email-header">
									<Text
										className={
											isMobile
												? 'verify-email__heading-mobile'
												: 'verify-email__heading'
										}
									>
										Verify your email id
									</Text>
									<Text
										className={
											isMobile
												? 'verify-email__text-mobile'
												: 'verify-email__text'
										}
									>
										We’ve sent you a code to your email id
									</Text>
								</Col>
							</Row>
							<Formik
								initialValues={initialValue}
								onSubmit={submitHandler}
								validationSchema={verifyEmailValidationSchema}
							>
								<Form className="verify-email-form">
									<label className={'floating-label-otp '}>Enter The OTP</label>
									<InputField
										type="string"
										name="otp"
										placeholder="Enter OTP"
										showLabel={false}
									/>
									<div
										className={
											isMobile
												? 'verify-email-form__resend-otp-mobile'
												: 'verify-email-form__resend-otp'
										}
									>
										<Text className={isMobile ? 'resend-otp__text' : ''}>
											Didn’t receive the OTP?
										</Text>
										<ButtonBase
											type={ButtonTypes.TERTIARY}
											ClassName={
												isMobile
													? 'verify-email-form__resend-btn-mobile'
													: 'verify-email-form__resend-btn'
											}
											text="Re-send"
											onclick={() => {
												void resendOtpHandler();
											}}
										/>
									</div>
									<ButtonBase
										type={ButtonTypes.PRIMARY}
										block={true}
										text="Verify OTP"
										htmlType="submit"
										ClassName="verify-email-form__otp-btn"
									/>
									<ButtonBase
										type={ButtonTypes.TERTIARY}
										text="Back"
										ClassName="verify-email-form__back-btn"
										block={true}
										onclick={handleBackClick}
									/>
								</Form>
							</Formik>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default VerifyEmail;
