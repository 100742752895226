import React from 'react';
import { Avatar } from 'antd';
import { CameraFilled } from '@ant-design/icons';
import './avatar.scss';
type AvatarProps = {
	imageUrl?: string | null;
	firstName?: string;
	lastName?: string;
	onAvatarClick?: () => void;
	isEditing?: boolean;
	isProfile?: boolean;
};
export default function AvatarComponent({
	imageUrl,
	firstName,
	lastName,
	onAvatarClick,
	isEditing = false,
	isProfile = false
}: AvatarProps) {
	const firstCharacters = firstName && lastName ? firstName.split('')[0] + lastName.split('')[0] : '';
	return imageUrl !== null ? (
		<span onClick={onAvatarClick} className="avatar-actions large">
			<Avatar src={imageUrl} size="large" className={isProfile ? 'profile' : undefined} />
			{isEditing && (
				<div className="float">
					<CameraFilled size={2} />
				</div>
			)}
		</span>
	) : (
		<span onClick={onAvatarClick} className="avatar-actions">
			<Avatar>{firstCharacters.toUpperCase()}</Avatar>
		</span>
	);
}
