import React, { useEffect, useState } from 'react';
import { TableProps, Row, Col, Typography, Button, Popover } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableUpdated from 'shared/components/Table';
import PaymentsService from 'services/PaymentsService/Payments.service';
import { TitleComponentPayments } from './TitleComponentPayments';
import { useTitleContext } from 'context/TitleComponentContext';
import { screenTypes } from 'enums/screenTypes';
import { Payments as PaymentsModel } from 'models/PaymentsLog/paymentslog.model';
import DashboardService from 'services/DashboardService/Dashboard.service';
// import { TableSkeleton } from 'shared/components/Table/TableSkeleton';
import useResponsive from 'shared/Hooks/useResponsive';
import { TableSkeleton } from 'shared/components/Table/TableSkeleton';
import PaymentsModal from './PaymentsModal';
import { ButtonTypes } from 'enums/buttonTypes';
import moment from 'moment';
import { usdollar } from 'views/Home';
import { DateFormats } from 'enums/dateFormat';
import "./navAbsolute.scss"
// import { TableSkeleton } from 'shared/components/Table/TableSkeleton';
const { Text } = Typography;
type DataType = {
	// key: React.Key;
	amount: string;
	id: string;
	// Id: number;
	transactionDate: string;
	transactionId: string;
	paymentMethod: string;
};

// const columns: ColumnsType<DataType> = [
// 	// {
// 	// 	title: 'SI.No',
// 	// 	dataIndex: 'Id',
// 	// },
// 	{
// 		title: 'Amount',
// 		dataIndex: 'amount',
// 		sorter: true

// 	},
// 	{
// 		title: 'Transaction Date',
// 		dataIndex: 'transactionDate',
// 		sorter: true

// 	},
// 	{
// 		title: 'Transaction ID',
// 		dataIndex: 'transactionId',
// 		sorter: true

// 	},
// 	{
// 		title: 'Payment Method',
// 		dataIndex: 'paymentMethod',
// 		sorter: true

// 	},
// ];

enum Columns {
	transactionId = 'transaction_id',
	transactionDate = 'transaction_date',
	paymentMethod = 'payment_method',
	amount = 'amount',
}

export enum SorterDirection {
	ascend = 'asc',
	descend = 'desc',
}

const MobileResponsiveCards = ({
	data,
	loading,
	downloadPaymentReceipt
}: {
	data: PaymentsModel[] | undefined;
	loading: boolean;
	downloadPaymentReceipt: any;
}) => {
	const popoverContent = (record: PaymentsModel) => (
		<div>
			<Button
				type={'text'}
				onClick={() => {
					const params = { id: record.id };
					void downloadPaymentReceipt(params);
				}}
			>
				Download
			</Button>
		</div>
	);
	return loading ? (
		<TableSkeleton />
	) : (
		<div style={{ paddingBottom: '1rem' }}>
			{data?.map((item, index) => (
				<Row
					key={index}
					gutter={16}
					align="middle"
					style={{ paddingBottom: '0.5rem' }}
				>
					<Col span={17} xs={12}>
						<Row gutter={[16, 5]} justify="start">
							<Col span={24}>
								<Text>{item?.transactionId}</Text>
							</Col>
							<Col span={24}>
								<Text type="secondary">{item?.amount}</Text>
							</Col>
						</Row>
					</Col>
					<Col span={7} xs={12} style={{ minWidth: '40px' }}>
						<Row align="middle" justify="end" gutter={[16, 5]}>
							<Col>
								<Text>{item?.paymentMethod}</Text>
								<Popover
									placement="bottomLeft"
									content={popoverContent(item)}
									trigger={'click'}
									overlayClassName="download-popover-mobile"
								>
									<Text className="download-dropdown-mobile">...</Text>
								</Popover>
							</Col>
							<Col>
								<Text type="secondary">{item?.transactionDate}</Text>
							</Col>
						</Row>
					</Col>
				</Row>
			))}
		</div>
	);
};
export const Payments = () => {
	const { fetchPayments, Payments, pages, loading, downloadPaymentReceipt } =
		PaymentsService();
	const {DashboardData,fetchDashboards} = DashboardService();	
	const [currentPage, setCurrentPage] = useState<number | undefined>();
	const [order, setOrder] = useState<string>();
	const [orderField, setOrderField] = useState<string | undefined>();
	const [makePayment, setMakePayment] = useState(false);
	const changeMakePayment = () => setMakePayment(!makePayment);
	// const [year, setYear] = useState<number | undefined>();
	// const [month, setMonth] = useState<number | undefined>();
	const { isMobile } = useResponsive();
	const { date, search, selectedTab } = useTitleContext();
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		fetchPayments();
		
		
		if(!DashboardData){
			void fetchDashboards();
		}
	}, []);


	const columns: ColumnsType<DataType> = [
		{
			title: 'ID',
			key: 'index',
			render: (text, record, index) => index + 1,
		},
		// {
		// 	title: 'SI.No',
		// 	dataIndex: 'Id',
		// },
		{
			title: 'Amount',
			dataIndex: 'amount',
			sorter: true,
			render: (text) => (
				<div className="text-right">{usdollar.format(text)}</div>
			),
			// render: ({ _, data }) => {
			// 	return loading ? <Skeleton.Button /> : { data }
			// }
		},
		{
			title: 'Transaction Date',
			dataIndex: 'transactionDate',
			sorter: true,
			render: (text) => <>{moment(text).format(DateFormats.SECONDARY)}</>,
		},
		{
			title: 'Transaction ID',
			dataIndex: 'transactionId',
			sorter: true,
		},
		{
			title: 'Payment Method',
			dataIndex: 'paymentMethod',
			sorter: true,
		},
		{
			title: 'Receipt',
			dataIndex: 'receipt',
			sorter: false,
			render: (text, record, index) => (
				<Button
					type={ButtonTypes.PRIMARY}
					className="table-title-btn"
					onClick={() => {
						const params = { id: record.id };
						void downloadPaymentReceipt(params);
					}}
				>
					Download
				</Button>
			),
		},
	];

	useEffect(() => {
		if (selectedTab !== screenTypes.PAYMENTS) return;
		let year, month;
		if (date) {
			const parsedDate = moment(date);
			year = parsedDate.year();
			month = parsedDate.month() + 1;
			void fetchPayments({ year, month });
		} else {
			void fetchPayments();
		}
	}, [date]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		if (
			order !== undefined ||
			orderField !== undefined ||
			search !== undefined ||
			currentPage !== undefined
		) {
			void fetchPayments({
				page: currentPage,
				sort_by: orderField,
				sort_dir: order,
				search,
			});
		}
	}, [order, orderField, currentPage, search]);


	
	const onChange: TableProps<DataType>['onChange'] = (
		pagination,
		filters,
		sorter,
		extra
	) => {
		setCurrentPage(pagination.current);
		// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
		// @ts-ignore
		setOrder(SorterDirection[sorter.order]);
		// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
		// @ts-ignore
		setOrderField(sorter.order !== undefined ? Columns[sorter.field] : null);
	};


	return isMobile ? (
		<>
			<TitleComponentPayments
				onclick={() => console.log('inside payments')}
				showButton={false}
				placeholder='Search by payments'
			/>
			<MobileResponsiveCards data={Payments} loading={loading} downloadPaymentReceipt={downloadPaymentReceipt}  />
		</>
	) : (
		<>
			<PaymentsModal
				makePayment={makePayment}
				changeMakePayment={changeMakePayment}
				isMobile={isMobile}
				dueAmount={DashboardData?.amountDue ?? 0}
				merchant={DashboardData?.building?.merchantId}
				callback={`${window.location.href}`}
			/>
			<TableUpdated
				columns={columns}
				data={Payments}
				total={pages}
				title={
					<TitleComponentPayments
						onclick={changeMakePayment}
						showButton={true}
						buttonText={'Make Payment'}
						placeholder='Search by payment details'
					/>
				}
				onchange={onChange}
				isLoading={loading}
			/>
		</>
	);
};
