import React, { FC, ReactNode } from 'react';
import './image.scss';

type ImageProps = {
	src: string;
	children?: ReactNode;
};

export const Image: FC<ImageProps> = ({ src, children }) => {
	console.log(src);
	
	return (
		<div style={{ backgroundImage: `url(${src})` }} className="backgroundImage">
			{children}
		</div>
	);
};
