import { serializable, alias, primitive } from 'serializr';

export class GuardingDetails {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('user_id', primitive()))
	userId?: string;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('mobile_number', primitive()))
	mobileNumber?: number;

	@serializable(alias('country_id', primitive()))
	countryId?: number;

	@serializable(alias('calling_code', primitive()))
	callingCode?: string;
}
