import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import Icon, { ArrowRightOutlined } from '@ant-design/icons';
import { Layout, Menu, Row, Col, Typography, Divider } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
	BookingsLayout,
	ChangePassword,
	DocumentsLayout,
	ElectricityTokenLayout,
	FinancialsLayout,
	Home as HomeIcon,
	KnowYourCommunityLayout,
	Logout as LogoutIcon,
	MaintenanceLayout,
	ProfileLayout,
	VukaIcon,
	NoticeLayout,
	VisitorDetailsTwo,
	NoticeMobile,
} from 'resources/Icons';

import newLogo from 'resources/images/newLogo.svg';

import { AppRoutes, NavigationRoutes } from 'routes/routeConstants/appRoutes';
import useResponsive from 'shared/Hooks/useResponsive';
import ReferAFriend from '../ReferAFriend';
import ButtonBase from '../Button';
import { ButtonTypes } from 'enums/buttonTypes';
import SidebarLogo from '../SidebarLogo';

import Notification from '../Notification';
import { NotificationTypes } from 'enums/notificationTypes';
import { userDetails } from 'shared/Hooks/user';
import { userCheckInStatus } from 'enums/userStatusTypes';

const { Header, Footer, Sider, Content } = Layout;
const { Text } = Typography;

export type menuItemTypes = {
	menuName: string;
	menuText: string;
	MenuIcon: any;
};

export const MenuItemMobile = ({
	menuName,
	menuText,
	MenuIcon,
}: menuItemTypes): JSX.Element => {
	const { pathname } = location;
	return (
		<Row align="middle" justify="center" gutter={[0, 0]}>
			<Col>
				<Icon
					component={() => (
						<MenuIcon color={pathname === menuName ? '#fff' : null} />
					)}
					className={pathname === menuName ? 'menu-selected-icon' : 'menu-icon'}
				/>
			</Col>
			<Col>
				<span>{menuText}</span>
			</Col>
		</Row>
	);
};
type layoutProps = {
	showMenu?: boolean;
	closeDrawer?: Dispatch<SetStateAction<boolean>>;
	setShowReferFriendModal?: Dispatch<SetStateAction<boolean>>;
	showPasswordModal?: () => void;
};
const LayoutMain: React.FC<layoutProps> = ({
	showMenu = false,
	closeDrawer = () => {},
	setShowReferFriendModal = () => {},
	showPasswordModal = () => {},
}) => {
	const location = useLocation();
	const { pathname = '/app/home' } = location;
	const [hovered, setHovered] = useState(false);
	const { user } = userDetails();
	const navigate = useNavigate();
	const MenuItem = useCallback(
		({ menuName, menuText, MenuIcon }: menuItemTypes): JSX.Element => {
			// const { pathname } = location;
			const { isDesktop } = useResponsive();
			console.log(pathname,menuName)
			
			return (
				<Row align="middle">
					<Col span={6} lg={5} sm={3} xs={4}>
						<Icon
							component={() => (
								<MenuIcon
									color={pathname === `/app/${menuName}` ? '#fff' : '#6E6F71'}
								/>
							)}
							className={
								pathname === `/app/${menuName}`
									? 'menu-selected-icon'
									: 'menu-icon'
							}
						/>
					</Col>
					<Col span={10} md={14} xs={14} sm={15}>
						<Text
							className={
								pathname === `/app/${menuName}`
									? 'text-selected-menu'
									: 'text-unselected-menu'
							}
						>
							{menuText}
						</Text>
					</Col>
					{!isDesktop && (
						<Col span={2} xs={6}>
							<Row justify="end">
								<Icon
									component={() => (
										<ArrowRightOutlined
											color={
												pathname === `/app/${menuName}` ? '#fff' : '#6E6F71'
											}
										/>
									)}
								/>
							</Row>
						</Col>
					)}
				</Row>
			);
		},
		[pathname]
	);

	const MenuItemMobile = useCallback(
		({ menuName, menuText, MenuIcon }: menuItemTypes): JSX.Element => {
			const { pathname } = location;
			return (
				<Row align="middle" justify="center" gutter={[0, 0]}>
					<Col>
						<Icon
							component={() => (
								<MenuIcon
									color={pathname === `/app/${menuName}` ? '#fff' : null}
								/>
							)}
							className={
								pathname === `/app/${menuName}`
									? 'menu-selected-icon'
									: 'menu-icon'
							}
						/>
					</Col>
					<Col>
						<span className='menuMobile'>{menuText}</span>
					</Col>
				</Row>
			);
		},
		[pathname]
	);
	const handleLogout = (): void => {
		localStorage.clear();
		navigate(NavigationRoutes.LOGIN);
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		Notification({
			message: 'Logout',
			description: 'User Logged Out Successfully',
			type: NotificationTypes.SUCCESS,
		});
	};

	const SIDER_WIDTH = 275;
	const { isDesktop } = useResponsive();

	const referAFriendModal = () => {
		setShowReferFriendModal(true);
		closeDrawer(false);
	};
	const changePasswordModal = () => {
		showPasswordModal();
		closeDrawer(false);
	};
	return !isDesktop ? (
		<>
			<Menu
				mode={showMenu ? 'horizontal' : 'vertical'}
				defaultSelectedKeys={[pathname.split('/')[2]]}
				defaultOpenKeys={[pathname.split('/')[2]]}
				onSelect={(item) => {
					closeDrawer(false);
				}}
			>
				{showMenu ? (
					<>
						<Row>
							<ReferAFriend onClick={referAFriendModal} />
							<Menu.Item key={AppRoutes.PROFILE}>
								<Link to={AppRoutes.PROFILE}>
									<MenuItem
										menuName={AppRoutes.PROFILE}
										menuText="My Profile"
										MenuIcon={ProfileLayout}
									/>
								</Link>
							</Menu.Item>
							<Menu.Item key={AppRoutes.IMPORTANT_DOCUMENTS}>
								<Link to={AppRoutes.IMPORTANT_DOCUMENTS}>
									<MenuItem
										menuName={AppRoutes.IMPORTANT_DOCUMENTS}
										menuText="Important Documents"
										MenuIcon={DocumentsLayout}
									/>
								</Link>
							</Menu.Item>
							<Menu.Item key={AppRoutes.BOOKING}>
								<Link to={AppRoutes.BOOKING}>
									<MenuItem
										menuName={AppRoutes.BOOKING}
										menuText="Bookings"
										MenuIcon={BookingsLayout}
									/>
								</Link>
							</Menu.Item>
							{user?.status?.name === userCheckInStatus.CheckedIn && (
							<Menu.Item key={AppRoutes.VISITOR}>
								<Link to={AppRoutes.VISITOR}>
									<MenuItem
										menuName={AppRoutes.VISITOR}
										menuText="Visitor Details"
										MenuIcon={VisitorDetailsTwo}
									/>
								</Link>
							</Menu.Item>)}
							{user?.status?.name === userCheckInStatus.CheckedIn && (
							<Menu.Item key={AppRoutes.ELECTRICITY}>
								<Link to={AppRoutes.ELECTRICITY}>
									<MenuItem
										menuName={AppRoutes.ELECTRICITY}
										menuText="Electricity Token"
										MenuIcon={ElectricityTokenLayout}
									/>
								</Link>
							</Menu.Item>)}
							{user?.status?.name === userCheckInStatus.CheckedIn && (
							<Menu.Item key={AppRoutes.NOTICE}>
								<Link to={AppRoutes.NOTICE}>
									<MenuItem
										menuName={AppRoutes.NOTICE}
										menuText="Notice"
										MenuIcon={NoticeMobile}
									/>
								</Link>
							</Menu.Item>)}
							<Divider />
							<Col span={24}>
								<ButtonBase
									type={ButtonTypes.PRIMARY}
									text="Get Skillshare Access"
									block={true}
								/>
							</Col>
							<Menu.Item onClick={changePasswordModal} key="ChangePassword">
								<MenuItem
									menuName={'ChangePassword'}
									menuText="Change Password"
									MenuIcon={ChangePassword}
								/>
							</Menu.Item>
							{/* <Menu.Item key={AppRoutes.FAQ}>
								<Link to={AppRoutes.ELECTRICITY}>
									<MenuItem
										menuName={AppRoutes.ELECTRICITY}
										menuText="FAQ"
										MenuIcon={FAQ}
									/>
								</Link>
							</Menu.Item> */}
							<Menu.Item key={AppRoutes.LOGIN} onClick={handleLogout}>
								<MenuItem
									menuName={AppRoutes.LOGIN}
									menuText="Logout"
									MenuIcon={LogoutIcon}
								/>
							</Menu.Item>
							{/* <Footer style={{ position: 'sticky', bottom: 0, zIndex: 1, width: '100%' }} className="logo-bottom" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
					<div className="logo" >
						<p>Start Investing Now with</p>
						<VukaIcon color={hovered ? 'white' : undefined} />

					</div>
				</Footer> */}
						</Row>
					</>
				) : (
					<Row align='middle' justify='center'>
						<Menu.Item key={AppRoutes.HOME} className="mobile_bottom_nav">
							<Link to={AppRoutes.HOME}>
								<MenuItemMobile
									menuName={AppRoutes.HOME}
									menuText="My Home"
									MenuIcon={HomeIcon}
								/>
							</Link>
						</Menu.Item>
						<Menu.Item key={AppRoutes.FINANCIALS} className="mobile_bottom_nav">
							<Link to={AppRoutes.FINANCIALS}>
								<MenuItemMobile
									menuName={AppRoutes.FINANCIALS}
									menuText={'Finance'}
									MenuIcon={FinancialsLayout}
								/>
							</Link>
						</Menu.Item>
						{user?.status?.name === userCheckInStatus.CheckedIn && (
						<Menu.Item
							key={AppRoutes.MAINTENANCE}
							className="mobile_bottom_nav"
						>
							<Link to={AppRoutes.MAINTENANCE}>
								<MenuItemMobile
									menuName={AppRoutes.MAINTENANCE}
									menuText="Maintenance"
									MenuIcon={MaintenanceLayout}
								/>
							</Link>
						</Menu.Item>
						)}
						{
							user?.status?.name!==userCheckInStatus.CheckedOut &&(

						<Menu.Item key={AppRoutes.COMMUNITY} className="mobile_bottom_nav" >
							<Link to={AppRoutes.COMMUNITY}>
								<MenuItemMobile

									menuName={AppRoutes.COMMUNITY}
									menuText="Community"
									MenuIcon={KnowYourCommunityLayout}
								/>
							</Link>
						</Menu.Item>
							)
						}
					</Row>
				)}
			</Menu>
		</>
	) : (
		<Sider width={SIDER_WIDTH} theme="light" className="menu-sider">
			<Layout className="sider-layout">
				<Header className="menu-header">
					<SidebarLogo src={newLogo} />
				</Header>
				<Content>
					<Menu
						mode="inline"
						defaultSelectedKeys={[pathname.split('/')[2]]}
						defaultOpenKeys={[pathname.split('/')[2]]}
						selectedKeys={[pathname.split('/')[2]]}
					>
						<Menu.Item key={AppRoutes.HOME}>
							<Link to={AppRoutes.HOME}>
								<MenuItem
									menuName={AppRoutes.HOME}
									menuText="My Home"
									MenuIcon={HomeIcon}
								/>
							</Link>
						</Menu.Item>
						<Menu.Item key={AppRoutes.PROFILE}>
							<Link to={AppRoutes.PROFILE}>
								<MenuItem
									menuName={AppRoutes.PROFILE}
									menuText="My Profile"
									MenuIcon={ProfileLayout}
								/>
							</Link>
						</Menu.Item>
						<Menu.Item key={AppRoutes.IMPORTANT_DOCUMENTS}>
							<Link to={AppRoutes.IMPORTANT_DOCUMENTS}>
								<MenuItem
									menuName={AppRoutes.IMPORTANT_DOCUMENTS}
									menuText="Important Documents"
									MenuIcon={DocumentsLayout}
								/>
							</Link>
						</Menu.Item>
						<Menu.Item key={AppRoutes.FINANCIALS}>
							<Link to={AppRoutes.FINANCIALS}>
								<MenuItem
									menuName={AppRoutes.FINANCIALS}
									menuText="Financials"
									MenuIcon={FinancialsLayout}
								/>
							</Link>
						</Menu.Item>
						<Menu.Item key={AppRoutes.BOOKING}>
							<Link to={AppRoutes.BOOKING}>
								<MenuItem
									menuName={AppRoutes.BOOKING}
									menuText="Bookings"
									MenuIcon={BookingsLayout}
								/>
							</Link>
						</Menu.Item>
						{user?.status?.name === userCheckInStatus.CheckedIn && (
							<Menu.Item key={AppRoutes.MAINTENANCE}>
								<Link to={AppRoutes.MAINTENANCE}>
									<MenuItem
										menuName={AppRoutes.MAINTENANCE}
										menuText="Maintenance"
										MenuIcon={MaintenanceLayout}
									/>
								</Link>
							</Menu.Item>
						)}
						{user?.status?.name === userCheckInStatus.CheckedIn && (
							<Menu.Item key={AppRoutes.NOTICE} className="notice-icon-wrapper">
								<Link to={AppRoutes.NOTICE}>
									<MenuItem
										menuName={AppRoutes.NOTICE}
										menuText="Notice"
										MenuIcon={NoticeLayout}
									/>
								</Link>
							</Menu.Item>
						)}
						{user?.status?.name === userCheckInStatus.CheckedIn && (
							<Menu.Item key={AppRoutes.COMMUNITY}>
								<Link to={AppRoutes.COMMUNITY}>
									<MenuItem
										menuName={AppRoutes.COMMUNITY}
										menuText="Know Your Community"
										MenuIcon={KnowYourCommunityLayout}
									/>
								</Link>
							</Menu.Item>
						)}
						{user?.status?.name === userCheckInStatus.CheckedIn && (
							<Menu.Item key={AppRoutes.VISITOR}>
								<Link to={AppRoutes.VISITOR}>
									<MenuItem
										menuName={AppRoutes.VISITOR}
										menuText="Visitor Details"
										MenuIcon={VisitorDetailsTwo}
									/>
								</Link>
							</Menu.Item>
						)}

						{user?.status?.name === userCheckInStatus.CheckedIn && (
							<Menu.Item key={AppRoutes.ELECTRICITY}>
								<Link to={AppRoutes.ELECTRICITY}>
									<MenuItem
										menuName={AppRoutes.ELECTRICITY}
										menuText="Electricity Token"
										MenuIcon={ElectricityTokenLayout}
									/>
								</Link>
							</Menu.Item>
						)}
					</Menu>
				</Content>
				<Footer
					className="logo-bottom"
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
				>
					<a href="https://vuka.co.ke/" target="_blank" rel="noreferrer">
						<div className="logo">
							<p className="bottom">Start investing now with</p>
							<VukaIcon color={hovered ? 'white' : undefined} />
						</div>
					</a>
				</Footer>
			</Layout>
		</Sider>
	);
};

export default LayoutMain;
