import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Dashboard } from 'models/Dashboard/dashboard.model';
import Notification from 'shared/components/Notification';
import { NotificationTypes } from 'enums/notificationTypes';
import { PaymentsForHome } from 'models/PaymentsLog/PaymentsForHome.model';
import { TicketsModelForDashboard } from 'models/Dashboard/ticketsmodelfordashboard.model';
import { PaymentMethodAndOptions } from 'enums/paymentTypes';
import { PaymentTypeListType } from 'enums/creditCardAcceptRejectTypes';

const DashboardService = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingPayments, setLoadingPayments] = useState(false);
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const [DashboardData, setDashboardData] = useState<Dashboard | undefined>();
	const [payments, setPayments] = useState<PaymentsForHome[]>();
	const [tickets, setTickets] = useState<TicketsModelForDashboard[]>();

	const fetchDashboards = async (params?: {
		search?: string;
		page?: number;
	}) => {
		try {
			setLoading(true);
			const response = await axiosInstance.get(
				ApiRoutes.RESIDENT_DASHBOARD_ROOM,
				{ params }
			);
			const DashboardData = deserialize(Dashboard, response.data.data);
			setDashboardData(DashboardData);
		} catch (error) {
			setButtonLoading(false);
			setDashboardData(undefined);
		} finally {
			setLoading(false);
		}
	};

	const fetchPayments = async (params?: { month?: string; year?: string }) => {
		try {
			// setButtonLoading(true);
			setLoadingPayments(true);
			const response = await axiosInstance.get(
				ApiRoutes.RESIDENT_DASHBOARD_CONTENT,
				{ params }
			);
			const responseDataParsedForPayments = deserialize(
				PaymentsForHome,
				response.data.data.payments
			) as PaymentsForHome[];

			const responseDataParsedForTickets = deserialize(
				TicketsModelForDashboard,
				response.data.data.tickets
			) as TicketsModelForDashboard[];

			setPayments(responseDataParsedForPayments);
			setTickets(responseDataParsedForTickets);
			// setButtonLoading(false);
			setLoadingPayments(false);
		} catch (error) {
			setLoadingPayments(false);
			setPayments(undefined);
		} finally {
			// setButtonLoading(false);
			setLoadingPayments(false);
		}
	};

	const checkIfUserPaymentSuccess = (decision: string) => {
		if (decision === PaymentTypeListType.Success) {
			void Notification({
				message: 'Succcess',
				description: 'Payment successful',
				type: NotificationTypes.SUCCESS,
			});
		} else if (decision === PaymentTypeListType.Failed) {
			void Notification({
				message: 'Payment Failed',
				description: 'Something went wrong - please try again',
				type: NotificationTypes.ERROR,
			});
		}
		else if(decision === PaymentTypeListType.CANCEL){
			void Notification({
				message: 'Payment Cancelled',
				description: 'Payment has been cancelled',
				type: NotificationTypes.WARNING,
			});
		}
	};

	const makeCreditCardPayment = async (params?: {
		booking_id?: string;
		payment_for?: number;
		payment_method?: PaymentMethodAndOptions;
		phone_number?: string;
		to_be_paid?: number;
		merchant?: string;
		callback: string;
	}) => {
		if (params) {
			setButtonLoading(true);
			const user = JSON.parse(localStorage.getItem('user') as string);
			try {
				const data = {
					amount: params.to_be_paid,
					merchant: params.merchant,
					account_number: user.id,
					email: user?.email,
					phone_number: user.mobileNumber
				};
					const redirectUrl = await axiosInstance.post('/payments/generate-long-link',data)
					setButtonLoading(false);
					window.open(redirectUrl.data.data.shortlink, '_self');
					return redirectUrl.status;
			} catch (err: any) {
				setButtonLoading(false);
				void Notification({
					message: 'Something went wrong',
					description: err.message,
					type: NotificationTypes.ERROR,
				});
			}
		}
	};

	const makePayments = async (params?: {
		booking_id?: string;
		payment_for?: number;
		payment_method?: PaymentMethodAndOptions;
		phone_number?: string;
		to_be_paid?: number;
	}) => {
		try {
			setButtonLoading(true);
			const res = await axiosInstance.post(ApiRoutes.DASHBOARD, params);
			setButtonLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: 'Success',
				description: 'Successfully created the payment',
				type: NotificationTypes.SUCCESS,
			});
			setButtonLoading(false);
			return res.status;
		} catch (error: any) {
			setButtonLoading(false);
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const changeCheckout = async (params?: {
		checkout_date?: string;
		reason?: string;
	}) => {
		try {
			setButtonLoading(true);
			const res = await axiosInstance.post(ApiRoutes.BOOKINGS, params);
			setButtonLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: 'Success',
				description: 'Successfully changed the checkout date',
				type: NotificationTypes.SUCCESS,
			});
			setButtonLoading(false);
			return res.status;
		} catch (error: any) {
			setButtonLoading(false);
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	// const createDashboard = async (Dashboard: Dashboard) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { Dashboard: serialize(Dashboard, Dashboard) }
	//         const response = await axiosInstance.post(ApiRoutes.DASHBOARDS, payload)
	//         const data = deserialize(Dashboard, response.data["dashboard"]) as Dashboard;
	//         setDashboard(data)
	//     } catch (error) {
	//         setDashboard(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const updateDashboard = async (Dashboard: Dashboard) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { Dashboard: serialize(Dashboard, Dashboard) }
	//         const API_URL = generatePath(ApiRoutes.DASHBOARDS_SPECIFIC, { DashboardId: String(Dashboard.id) })
	//         const response = await axiosInstance.put(API_URL, payload)
	//         const data = deserialize(Dashboard, response.data["dashboard"]) as Dashboard;
	//         setDashboard(data)
	//     } catch (error) {
	//         setDashboard(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const deleteDashboard = async (DashboardId: string) => {
	//     try {
	//         setButtonLoading(true)
	//         const API_URL = generatePath(ApiRoutes.DASHBOARDS_SPECIFIC, { DashboardId })
	//         await axiosInstance.delete(API_URL)
	//         return true
	//     } catch (error) {
	//         return false
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const showDashboard = async (DashboardId: string) => {
	//     try {
	//         setLoading(true)
	//         const API_URL = generatePath(ApiRoutes.DASHBOARDS_SPECIFIC, { DashboardId })
	//         const response = await axiosInstance.get(API_URL)
	//         const data = deserialize(Dashboard, response.data["dashboard"]) as Dashboard;
	//         setDashboard(data)
	//     } catch (error) {
	//         setDashboard(null)
	//     } finally {
	//         setLoading(false)
	//     }
	// }

	const getSkillShareAccess = async () => {
		try {
			setButtonLoading(true);
			const response = await axiosInstance.post(
				ApiRoutes.GET_SKILL_SHARE_ACCESS
			);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type:
					response.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});
			setButtonLoading(false);
			return response.status;
		} catch (error: any) {
			setButtonLoading(false);
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};
	const referAFriend = async (params?: {
		name?: string;
		dial_code?: number;
		mobile_number?: string;
		email?: string;
	}) => {
		try {
			setButtonLoading(true);
			const response = await axiosInstance.post(
				ApiRoutes.REFER_A_FRIEND,
				params
			);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type:
					response.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});
			setButtonLoading(false);
			return response.status;
		} catch (error: any) {
			setButtonLoading(false);
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};
	return {
		loading,
		Dashboard,
		DashboardData,
		buttonLoading,
		fetchDashboards,
		makePayments,
		changeCheckout,
		fetchPayments,
		payments,
		loadingPayments,
		tickets,
		referAFriend,
		getSkillShareAccess,
		checkIfUserPaymentSuccess,
		makeCreditCardPayment
	};
};

export default DashboardService;
