import { notification, message as MobileNotification } from 'antd';
import { ArgsProps } from 'antd/lib/message';

type INotification = {
	message: string;
	description: string;
	type: string;
};

type notificationType = 'success' | 'error' | 'warning' | 'info' | 'open';

const Notification = ({ message, description, type }: INotification) => {
	const content: ArgsProps = { content: description };
	if (window.innerWidth <= 768) {
		return MobileNotification[type as notificationType](content);
	}
	return notification[type as notificationType]({
		message,
		description,
	});
};

export default Notification;
