import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Profile } from '../../models/Profile/profile.model';
import { Pagination } from './../../models/Pagination/pagination.model';
import { GuardingDetails } from 'models/GuardingDetails/guardingDetails.model';
import Notification from 'shared/components/Notification';
import { NotificationTypes } from 'enums/notificationTypes';
import heic2any from 'heic2any';
import useSWR from 'swr';
import { useProfileContext } from 'context/ProfileContext';

type generalProfileInfo = {
	birth_date: string;
	title: number;
	college: {
		id: number;
		name: string;
	};
	email: string;
	first_name: string;
	id: number;
	mobile_number: number;
	dial_code: number;
	nationality: {
		citizenship: string;
		id: number;
		calling_code?: string;
	};
	profile_picture: string;
	year_of_study: {
		id: number;
		name: string;
	};
	user: {
		resident_account: {
			account_holder_name?: string;
			account_number?: string;
			account_type?: string;
			bank_code?: string;
			bank_name?: string;
			branch_code?: string;
			swift_code?: string;
			credit_currency?: string;
			country_id?: number;
			country: {
				citizenship?: string;
				country_code?: number;
			};
		};
		share_interest?: number;
		status_id?: number;
	};
};
type countriesListType = Array<{
	calling_code: string;
	citizenship: string;
	currency_code: string;
	id: number;
	name: string;
}>;

const fetcher = async (url: string, params: {}) =>
	await axiosInstance.get(url, { params }).then((res) => res.data);
export const useCountriesList = () => {
	const { data: response, error } = useSWR([ApiRoutes.COUNTRIES_LIST], fetcher);
	let countriesList;
	// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
	if (response) {
		const dataFormated = [].concat(...response.data);
		countriesList = dataFormated as countriesListType;
	}

	return {
		countriesList,
		error,
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		isLoading: !error && !response,
	};
};

const ProfileService = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);
	const [profiles, setProfiles] = useState<Profile[]>([]);
	const [guardianDetails, setGuardianDetails] = useState<GuardingDetails>();
	const [generalInfo, setGeneralInfo] = useState<generalProfileInfo>();
	const { setCountriesList, setSalutationList } = useProfileContext();
	const [changePasswordButtonLoading, setChangePasswordButtonLoading] =
		useState(false);
	const [showLoadingForOtp, setShowLoadingForOtp] = useState(false);
	const [yearOfStudyList, setYearOfStudyList] = useState<
		Array<{
			created_at: string;
			id: number;
			is_deleted: Number;
			name: string;
		}>
	>();
	
	const [currienciesList, setCurrienciesList] = useState<string[]>();
	const [institutionList, setInstitutionList] = useState<
		Array<{
			id: number;
			name: string;
		}>
	>();
	const [profilesPagination, setProfilesPagination] =
		useState<Pagination | null>();

	const [profile, setProfile] = useState<Profile | null>();

	const fetchGuardianDetails = async (params?: {
		search?: string;
		page?: number;
	}) => {
		try {
			setLoading(true);
			const response = await axiosInstance.get(ApiRoutes.GUARDIAN_DETAILS, {
				params,
			});
			const guardingdetails = deserialize(GuardingDetails, response.data.data);
			const pagination = deserialize(Pagination, response.data.meta);
			setProfilesPagination(pagination);
			setGuardianDetails(guardingdetails);
		} catch (error) {
			setProfilesPagination(null);
			setProfiles([]);
		} finally {
			setLoading(false);
		}
	};

	const createProfile = async (profile: Profile) => {
		try {
			setButtonLoading(true);
			const payload = { profile: serialize(Profile, profile) };
			const response = await axiosInstance.post(ApiRoutes.PROFILE, payload);
			const data = deserialize(Profile, response.data.profile);
			setProfile(data);
		} catch (error) {
			setProfile(null);
		} finally {
			setButtonLoading(false);
		}
	};

	const updateGuarantor = async (params: {
		email?: string;
		mobile_number?: string;
		name?: string;
		userId: string;
		countryCode?: number;
	}) => {
		try {
			setButtonLoading(true);
			// eslint-disable-next-line @typescript-eslint/naming-convention
			const { email, mobile_number, name, userId, countryCode } = params;
			const response = await axiosInstance.put(
				`${ApiRoutes.PROFILES}/${userId}${ApiRoutes.UPDATE_GUARANTOR}`,
				{ email, mobile_number, name, country_id: countryCode }
			);

			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response?.data.message,
				type:
					response.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});
			setButtonLoading(false);
			return response.status;
		} catch (error: any) {
			const { response } = error;
			setButtonLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		} finally {
			setButtonLoading(false);
		}
	};

	const getGeneralProfileInfo = async () => {
		try {
			const userId: number = JSON.parse(
				localStorage.getItem('user') as string
			).id;
			const res = await axiosInstance.get(
				`${ApiRoutes.USER_PROFILE}/${userId}`
			);
			setGeneralInfo(res.data.data);
		} catch (error: any) {
			const { response } = error;
			setButtonLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};
	const getSalutations = async () => {
		try {
			const salutation = await axiosInstance.get(ApiRoutes.SALUTATION);
			if (salutation.status === 200) {
				setSalutationList(salutation.data.data);
			}
		} catch (error: any) {
			console.log(error);
		}
	};
	const getCountriesList = async () => {
		try {
			const countriesListData = await axiosInstance.get(
				ApiRoutes.COUNTRIES_LIST
			);
			if (countriesListData.status === 200) {
				setCountriesList([].concat(...countriesListData.data.data));
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getProfileRelatedData = async () => {
		try {
			setLoading(true);
			await Promise.all([
				await axiosInstance.get(ApiRoutes.INSTITUTIONS),
				await axiosInstance.get(ApiRoutes.YEAR_OF_STUDY),
				await axiosInstance.get(ApiRoutes.CURRENCY_LIST),
			]).then(
				([institutionResponse, yearOfStudyResponse, currencyListResponse]) => {
					if (institutionResponse.status === 200) {
						const data = institutionResponse.data.data;

						const flatArray = [].concat(...data);
						setInstitutionList(flatArray);
					}
					if (yearOfStudyResponse.status === 200) {
						setYearOfStudyList(yearOfStudyResponse.data.data);
					}
					if (currencyListResponse.status === 200) {
						setCurrienciesList(currencyListResponse.data.data);
					}
				}
			);
			// const institutions = await axiosInstance.get(ApiRoutes.INSTITUTIONS);
			// if (institutions.status === 200) {
			// 	const data = institutions.data.data;

			// 	const flatArray = [].concat(...data);
			// 	setInstitutionList(flatArray);
			// }
			// const yearOfStudyList = await axiosInstance.get(ApiRoutes.YEAR_OF_STUDY);
			// if (yearOfStudyList.status === 200) {
			// 	setYearOfStudyList(yearOfStudyList.data.data);
			// }
			// const salutation = await axiosInstance.get(ApiRoutes.SALUTATION);
			// if (salutation.status === 200) {
			// 	setSalutationList(salutation.data.data);
			// }
			// void getCountriesList();
			// void getSalutations();
			// const currencyListData = await axiosInstance.get(ApiRoutes.CURRENCY_LIST);
			// if (currencyListData.status === 200) {
			// 	setCurrienciesList(currencyListData.data.data);
			// }
			setLoading(false);
		} catch (error: any) {
			const { response } = error;
			setButtonLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const updateRefundDetails = async (params: {
		account_holder_name?: string;
		account_number?: string;
		account_type?: string;
		bank_code?: string;
		bank_name?: string;
		branch_code?: string;
		country_id?: number;
		credit_currency?: string;
		swift_code?: string;
	}) => {
		try {
			setButtonLoading(true);
			const userId: number = JSON.parse(
				localStorage.getItem('user') as string
			).id;
			const response = await axiosInstance.put(
				`${ApiRoutes.PROFILES}/${userId}${ApiRoutes.UPDATE_RESIDENT_ACCOUNT}`,
				params
			);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.statusText,
				description: 'Success',
				type: NotificationTypes.SUCCESS,
			});
			setButtonLoading(false);
			return response.status;
		} catch (error: any) {
			const { response } = error;
			setButtonLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const sendOTP = async (params: {
		country_code: string;
		mobile_number: string;
	}) => {
		try {
			setShowLoadingForOtp(true);
			const response = await axiosInstance.post(ApiRoutes.GET_OTP, params);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.statusText,
				description: 'Success',
				type: NotificationTypes.SUCCESS,
			});

			setShowLoadingForOtp(false);
			return response.status;
		} catch (error: any) {
			const { response } = error;
			setShowLoadingForOtp(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const verifyOTP = async (params: { otp: string }) => {
		try {
			setShowLoadingForOtp(true);
			const response = await axiosInstance.post(ApiRoutes.VERIFY_OTP, params);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.statusText,
				description: 'Success',
				type: NotificationTypes.SUCCESS,
			});

			setShowLoadingForOtp(false);
			return response.status;
		} catch (error: any) {
			const { response } = error;
			setShowLoadingForOtp(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const updateGeneralInfo = async (params: {
		birth_date: string;
		college: string;
		first_name: string;
		nationality: string;
		title: string;
		year_of_study: string;
	}) => {
		try {
			setButtonLoading(true);
			const userId: number = JSON.parse(
				localStorage.getItem('user') as string
			).id;
			const res = await axiosInstance.put(
				`${ApiRoutes.PROFILES}/${userId}`,
				params
			);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: res.statusText,
				description: res.data.message,
				type:
					res.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});
			setButtonLoading(false);
			return res.status;
		} catch (error: any) {
			setButtonLoading(false);
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const uploadProfilePicture = async (params: { file: any }) => {
		try {
			const { file } = params;
			if (file !== undefined) {
				const { id = '' }: { id: string } = JSON.parse(
					localStorage.getItem('user') ?? ''
				);
				const reader = new FileReader();
				reader.readAsDataURL(file);

				let fileToUpload = file;
				// const mimeType = fileToUpload.type;

				if (fileToUpload.size > 3000000) {
					//   this.toastrService.error('Size of the selected file must be less than 3MB.');// = ;
					//   this.fileToUpload = null;
					//   this.loading = false;
					//   return;
				} else {
					if (/image\/hei(c|f)/.test(file?.type)) {
						try {
							const jpgBlob = (await heic2any({
								blob: file,
								toType: 'image/jpg',
								quality: 100,
							})) as Blob;

							const newName = file.name.replace(/\.[^/.]+$/, '.jpg');
							const fileNew = new File([jpgBlob], newName);
							fileToUpload = fileNew;
						} catch (error) {
							console.log('Unable to convert', error);
						}
					}
					const formData = new FormData();
					formData.append('_method', 'PUT');
					formData.append('document', fileToUpload);

					const response = await axiosInstance.post(
						`${ApiRoutes.PROFILES}/${id}${ApiRoutes.UPDATE_PROFILE_PICTURE}`,
						formData
					);
					// eslint-disable-next-line @typescript-eslint/no-floating-promises
					Notification({
						message:
							response.status === 200 ? 'Success' : 'Something went wrong',
						description: response?.statusText,
						type:
							response.status === 200
								? NotificationTypes.SUCCESS
								: NotificationTypes.ERROR,
					});
					return response;
				}
			} else {
				console.log('error');
			}
		} catch (error: any) {
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const changePassword = async (params: {
		current_password: string;
		new_password: string;
		new_password_confirmation: string;
	}) => {
		try {
			setChangePasswordButtonLoading(true);
			const response = await axiosInstance.post(
				ApiRoutes.CHANGE_PASSWORD,
				params
			);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.statusText,
				description: response.data.message,
				type:
					response.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});
			setChangePasswordButtonLoading(false);
			return response.status;
		} catch (error: any) {
			setChangePasswordButtonLoading(false);
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	return {
		loading,
		profile,
		profiles,
		getGeneralProfileInfo,
		buttonLoading,
		createProfile,
		updateGuarantor,
		fetchGuardianDetails,
		guardianDetails,
		generalInfo,
		profilesPagination,
		getProfileRelatedData,
		// salutationList,
		institutionList,
		yearOfStudyList,
		updateRefundDetails,
		// countriesList,
		currienciesList,
		sendOTP,
		showLoadingForOtp,
		verifyOTP,
		uploadProfilePicture,
		updateGeneralInfo,
		changePasswordButtonLoading,
		changePassword,
		getCountriesList,
		getSalutations,
	};
};

export default ProfileService;
