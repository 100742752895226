import { Typography, Form, Row, Col, Select } from 'antd';
import { countriesListType } from 'context/ProfileContext';
import { useCountriesAndSalutations } from 'shared/Hooks/useCountriesAndSalutations';

import React, { useEffect, useState } from 'react';
import { ReferAFriendIcon, SolidArrow } from 'resources/Icons';
import DashboardService from 'services/DashboardService/Dashboard.service';
// import { useCountriesList } from 'services/ProfileService/profile.service'
import { Helmet } from "react-helmet";
import { InputFieldForEmail } from '../InputField';
import ModalComponent from '../Modal';
import './referafriend.scss';
import SelectInput from '../Select/SelectInput';
const { Text } = Typography;
const { Option } = Select;
type showModalProps = {
	isModalVisible: boolean;
	changeModalState: any;
};
export default function ReferAFriendModal({
	isModalVisible = false,
	changeModalState,
}: showModalProps) {
	// const { getCountriesList } = ProfileService();
	const { referAFriend, buttonLoading } = DashboardService();
	// const { countriesList } = useCountriesList();
	const {
		countriesListProvider,
	}: { countriesListProvider: countriesListType } =
		useCountriesAndSalutations();
	const [referFriendDetails, setreferFriendDetails] = useState({
		firstName: '',
		lastName: '',
		countryCode: '111',
		phoneNumber: '',
		email: '',
		referalCode: 12,
	});

	useEffect(() => {
		const user: number = JSON.parse(localStorage.getItem('user') as string)?.id;
		if (user) {
			setreferFriendDetails({
				...referFriendDetails,
				referalCode: user,
			});
		}
		return () => {
			setreferFriendDetails({
				firstName: '',
				lastName: '',
				countryCode: '111',
				phoneNumber: '',
				email: '',
				referalCode: 12,
			});
		};
	}, []);
	const referAFriendRequest = async () => {
		const fullName = [referFriendDetails.firstName, referFriendDetails.lastName].filter(Boolean).join(' ');
		const params = {
			name: fullName,
			mobile_number: referFriendDetails.phoneNumber,
			email: referFriendDetails.email,
			dial_code: Number(referFriendDetails.countryCode),
			referral_code: referFriendDetails.referalCode.toString(),
			firstname: referFriendDetails.firstName,
			lastname: referFriendDetails.lastName,
		};

		const res = await referAFriend(params);
		if (res === 200) {
			changeModalState();
		}
	};
	return (
		<>
			<Helmet>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/4365291.js"></script>
            </Helmet>
			<ModalComponent
				shouldModalOpen={changeModalState}
				visible={isModalVisible}
				title={
					<>
						{' '}
						<ReferAFriendIcon /> Refer A Friend
					</>
				}
				okButtonText="Send Invite"
				cancelButtonText="Cancel"
				onButtonOkClick={referAFriendRequest}
				buttonType="submit"
				buttonLoading={buttonLoading}
			>
				<Form>
					<Form.Item>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Row gutter={[8, 0]}>
									<Col span={24}>
										<label>
											<Text type="secondary" className="secondary__text">
												First Name
											</Text>
										</label>
									</Col>
									<Col span={24}>
										<InputFieldForEmail
											type="text"
											id="firstname"
											placeholder="Enter First Name"
											onChange={(e: any) =>
												setreferFriendDetails({
													...referFriendDetails,
													firstName: e.target.value,
												})
											}
										/>
									</Col>
								</Row>
							</Col>
							<Col span={12}>
								<Row gutter={[8, 0]}>
									<Col span={24}>
										<label>
											<Text type="secondary">Last Name</Text>
										</label>
									</Col>
									<Col span={24}>
										<InputFieldForEmail
											type="text"
											id="lastname"
											placeholder="Enter Last Name"
											onChange={(e: any) =>
												setreferFriendDetails({
													...referFriendDetails,
													lastName: e.target.value,
												})
											}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Form.Item>
					<Form.Item>
						<Row gutter={[8, 0]}>
							<Col span={24}>
								<Text type="secondary">Email ID</Text>
							</Col>
							<Col span={24}>
								<InputFieldForEmail
									type="email"
									id="email"
									placeholder="Enter Email ID"
									onChange={(e: any) =>
										setreferFriendDetails({
											...referFriendDetails,
											email: e.target.value,
										})
									}
								/>
							</Col>
						</Row>
					</Form.Item>
					<Form.Item>
						<Row gutter={[8, 0]}>
							<Col span={24}>
								<Text type="secondary">Phone Number</Text>
							</Col>
							<Col span={24}>
								<InputFieldForEmail
									placeholder="Enter Phone Number"
									id="phone"
									onChange={(e: any) =>
										setreferFriendDetails({
											...referFriendDetails,
											phoneNumber: e.target.value,
										})
									}
									value={referFriendDetails?.phoneNumber}
									prefix={
										<SelectInput
											// style={{ width: 70 }}
											style={{ width: 70 }}
											listHeight={150}
											showSearch
											className="profile-select-phone-number"
											suffixIcon={<SolidArrow />}
											dropdownStyle={{ minWidth: 300, maxWidth: 500 }}
											optionLabelProp="label"
											filterOption={(input, option) => {
												if (option?.children) {
													return !!option.value
														?.toString()
														?.toLowerCase()
														.startsWith(input?.toLowerCase());
												} else return false;
											}}
											defaultValue={
												countriesListProvider.filter((i) =>
													i.name.startsWith('Kenya')
												)[0]?.calling_code
											}
											onChange={(e: any) => {
												const id = countriesListProvider.filter(
													(i) => i.name === e
												)[0].id;
												setreferFriendDetails({
													...referFriendDetails,
													countryCode: id.toString(),
												});
											}}
										>
											<>
												{countriesListProvider?.map((i, j) => {
													return (
														<Option
															value={i.name}
															key={j}
															label={i.calling_code}
														>
															{`${i.name}(+${i.calling_code})`}
														</Option>
													);
												})}
											</>
										</SelectInput>
									}
								/>
							</Col>
						</Row>

						<InputFieldForEmail
							type="email"
							id="referral_code"
							value={referFriendDetails.referalCode}
							style={{ display: 'none' }}
						/>
					</Form.Item>
				</Form>
			</ModalComponent>
		</>
	);
}
