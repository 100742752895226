import React, { useEffect, useState } from 'react'
import { Switch } from 'antd'
import KnowyourcommunityService from 'services/KnowyourcommunityService/knowYourCommunity.service';
import ProfileService from 'services/ProfileService/profile.service';
export default function EnableDisableSwitch({ isEnabled,disabled = false }: { disabled?: boolean ,isEnabled?:boolean}) {
    const {getGeneralProfileInfo, generalInfo} = ProfileService();
    const [interestEnabled, setInterestEnabled] = useState<boolean>();
    const { enableInterest } = KnowyourcommunityService();

    const enableDisableInterest = async (i: boolean) => {
        disabled = true;
        const params = {
            share: i ? 1 : 0
        }
        const response = await enableInterest(params);
        if (response === 200) {
            // change the state back to its original state if the update didnt went through
            setInterestEnabled(i);
        } else {

            setInterestEnabled(!i);
        }
        disabled = false;
    }

    useEffect (() => {
        void getGeneralProfileInfo();
    }, [])

    useEffect (() => {
        if(generalInfo){
            setInterestEnabled(generalInfo?.user?.share_interest === 1);
        }
    }, [generalInfo])


    return (
        <Switch
            checked={interestEnabled}
            size="small"
            onChange={(i) => {
                if (!disabled) {
                    void enableDisableInterest(i)
                }
            }}
            disabled={disabled}
        />
    )
}
