import { serializable, alias, primitive } from 'serializr';

export class Maintenance {
	@serializable(alias('id', primitive()))
	id?: string;

	@serializable(alias('raised_by', primitive()))
	raisedBy?: string;

	@serializable(alias('ticket_id', primitive()))
	ticketId?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('ticket_category', primitive()))
	ticketCategory?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('raised_on', primitive()))
	raisedOn?: string;
}
