import { serializable, alias, primitive } from 'serializr';

export class DocumentsList {
	@serializable(alias('id', primitive()))
	id?: string;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('document_type', primitive()))
	documentType?: string;
}
