import React from 'react';
import { Image } from 'shared/components/BackgroundImage/Image';
import { ElectricityTokenPurchase } from './ElectricityTokenPurchase';
import './electricity.scss';
import RequireAlert from 'shared/components/HOC/showAlert';
import Navbar from 'shared/components/Navbar';
import { TitleProvider } from 'context/TitleComponentContext';
import useResponsive from 'shared/Hooks/useResponsive';
import background from "resources/images/containerBackground.png"

export const ElectricityToken = () => {
	const { isMobile } = useResponsive();
	return (
		<>
			{!isMobile && (<Image
				src={background}
			>
				<Navbar />
			</Image>)}

			<RequireAlert>
				<TitleProvider>
					<ElectricityTokenPurchase />
				</TitleProvider>
			</RequireAlert>
		</>
	);
};
