import { User } from 'models/user.model';
import React, {
	useContext,
	createContext,
	useMemo,
	useState,
	Dispatch,
	SetStateAction,
} from 'react';


export type AuthState = {
	authenticated?: boolean;
	user?: User;
};

type SetAuthState = Dispatch<SetStateAction<AuthState>>;

type AuthContentProps = [AuthState, SetAuthState];
let user;


const initialValues: AuthState = {
	// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
	authenticated: false,
	user,
};

// Create the context
const AuthContent: any = createContext({});

// Create method to use context
const AuthContext = () => {
	const context = useContext<AuthContentProps>(AuthContent);
	// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
	if (!context) {
		throw new Error(`useMeContext must be used within a MeContextProvider`);
	}
	const [auth, setAuth] = context;


	const setAuthenticated = (user?: User, authenticated?: boolean) => {

		setAuth((auth) => ({
			...auth,
			authenticated,
			user,
		}));
	};

	return {
		...auth,
		setAuthenticated,

	};
};

// Create the context provider
const AuthProvider = (ownProps: any) => {
	const [auth, setAuth] = useState<AuthState>(initialValues);

	const value = useMemo(() => [auth, setAuth], [auth]);

	return <AuthContent.Provider value={value} {...ownProps} />;
};

export { AuthProvider, AuthContext };
