import { serializable, alias, primitive, object } from 'serializr';
import { Statuses } from './Maintenance/statuses.model';

export class User {
	@serializable(alias('email', primitive()))
	email?: string;
	
	@serializable(alias('qcareEmail', primitive()))
	qcareEmail?: string;

	@serializable(alias('password', primitive()))
	password?: string;

	@serializable(alias('profile_picture', primitive()))
	profilePicture?: string;

	@serializable(alias('mobile_number', primitive()))
	mobileNumber?: string;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('id', primitive()))
	id?: string;

	@serializable(alias('status_id', object(Statuses)))
	status?: Statuses;

	@serializable(alias('share_interest', primitive()))
	shareInterest?: number;
}
