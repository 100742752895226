import React, { createContext, ReactNode, useState } from 'react';
const TitleContext = createContext<any>(null);
export function TitleProvider({ children }: { children: ReactNode }): any {
    const [search, setSearch] = useState<string>();
    const [date, setDate] = useState<string>();
    const [selectedTab, setSelectedTab] = useState<string>();
    return (
        <TitleContext.Provider
            value={{
                search,
                setSearch,
                date,
                setDate,
                selectedTab,
                setSelectedTab
            }}
        >
            {children}
        </TitleContext.Provider>
    );
}

export const useTitleContext = (): any => React.useContext(TitleContext);
