import React from 'react';
import { Formik, Form as FormikForm } from 'formik';
import InputField from 'shared/components/InputField';
import { validationSchema } from './LoginValidation';

import ButtonBase from 'shared/components/Button';
import UserService from 'services/AuthService/auth.service';

// styles
import './login.scss';

import { Row, Col, Image, Typography } from 'antd';
import { ButtonTypes } from 'enums/buttonTypes';
import QcareLogo from 'resources/images/newLogo.svg';
import useResponsive from 'shared/Hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'routes/routeConstants/appRoutes';


const { Text } = Typography;
type User = {
	qcareEmail: string; 
	password: string;
};


const initialValue = {
	qcareEmail: '',
	password: '',
};
// export const Title = (): JSX.Element => (
// 	<div>
// 		<ButtonBase type={ButtonTypes.PRIMARY} text="Login" />
// 		Login
// 	</div>
// );
const LoginForm = (): JSX.Element => {
	const { loading, loginUser } = UserService();
	const { isTablet, isMobile } = useResponsive();
	const onSubmit = async (user: User): Promise<void> => {
		await loginUser(user);
	};
	const navigate = useNavigate();
	const handleForgetPasswordClick = () => {
		navigate(AppRoutes.FORGOT_PASSWORD);
	};

	return (
		<div>
			<Row
				className="loginPageRow"
				justify="center"
				align="middle"
				gutter={[16, 32]}
			>
				<Col span={isTablet ? 20 : isMobile ? 24 : 9} offset={isTablet ? 8 : isMobile ? 2 : 13}>
					<Row>
						<Col span={15} xs={20} md={18} xl={20} className="login-form">
							<Row align="middle" justify="center" gutter={[16, 22]}>
								<Col span={24} style={{ textAlign: 'center' }}>
									<Image
										src={QcareLogo}
										style={{ height: '4rem' }}
										preview={false}
									/>
								</Col>
								<Col>
								<Text className={isMobile ? "sign-in-string-mobile" : "sign-in-string"}>{"Sign in"}</Text>
								</Col>
								<Col span={24}></Col>
							</Row>

							<Formik
								initialValues={initialValue}
								onSubmit={onSubmit}
								validationSchema={validationSchema}
							>
								<FormikForm>
									<label className={'floating-label-password '}>Email</label>
									<InputField type="email" name="qcareEmail" showLabel={false}/>
									<div className="margin__top__two">

									<InputField type="password" name="password" />
									</div>
								

									{/* <Input.Password name="password" /> */}




									<Row>
										<Col span={24} className='login-form__forget-password'>
											<Text className='login-form__forget-password-text' onClick={handleForgetPasswordClick}> Forget Password?</Text>
										</Col>
									</Row>
									<Row>
										<Col span={24}>
											<ButtonBase
												type={ButtonTypes.PRIMARY}
												loading={loading}
												block={true}
												text="Sign in"
												htmlType="submit"
												ClassName='login-btn'

											/>
										</Col>
									</Row>
								</FormikForm>
							</Formik>
						</Col>
					</Row>
				</Col>
			</Row>
		</div >
	);
};

export default LoginForm;
