import { useState } from 'react';
import { deserialize } from 'serializr';

import axiosInstance from 'interceptor/axiosInstance';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { Agreement } from 'models/Agreement/agreement.model';
import Notification from 'shared/components/Notification';
import { NotificationTypes } from 'enums/notificationTypes';
const AgreementService = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [buttonLoading, setButtonLoading] = useState<boolean>(false);
	const [generationlinkloading, setGenerationlinkloading] = useState(false)

	const [agreements, setAgreements] = useState<Agreement>();

	const fetchAgreements = async (params?: {
		search?: string;
		page?: number;
	}) => {
		try {
			setLoading(true);
			const response = await axiosInstance.get(ApiRoutes.AGREEMENT_STATUS, {
				params,
			});
			const agreement = deserialize(Agreement, response.data.data.data);
			
			setAgreements(agreement);
			setLoading(false);
		} catch (error) {
			setAgreements(undefined);
			setLoading(false);
		} finally {
			setLoading(false);
		}
	};

	const downloadAgreement = async (filePath:any) => {
		try {
			setButtonLoading(true);
			if(filePath){
				 window.open(filePath);
				 setButtonLoading(false);
			}else{
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				axiosInstance
					.get(ApiRoutes.DOWNLOAD_AGREEMENT, { responseType: 'blob' })
					.then((response) => {
						window.open(URL.createObjectURL(response.data));
						setButtonLoading(false);
					});
			}
		} catch (error) {
			console.log('error.message');
		} finally {
			console.log('error');
		}
	};

	const generateDocusignLink=()=>{
		setGenerationlinkloading(true);
		axiosInstance.get(ApiRoutes.GENERATE_DOCUSIGN_LINK)
		.then((response)=>{
				void Notification({
					message: 'Success',
					description: response.data.message,
					type: NotificationTypes.SUCCESS
				});
			
		}).catch((error:any) => {

			void Notification({
			message: 'Something went wrong',
			description: error.message,
			type: NotificationTypes.ERROR
			});
		}).finally(()=>{
			setGenerationlinkloading(false)
		})

}





	return {
		loading,
		agreements,
		buttonLoading,
		fetchAgreements,
		downloadAgreement,
		generateDocusignLink,
		generationlinkloading
	};
};

export default AgreementService;
