import React from 'react'
import { Row, Skeleton, Col } from 'antd';
// import useResponsive from 'shared/Hooks/useResponsive';
export const TableSkeleton = () => {
    // const { isMobile } = useResponsive();
    return (<>
        {/* {!isMobile && <><Row gutter={[16, 32]}>
            <Col span={12}>
                <Skeleton.Input active={true} block={true} />
            </Col>
            <Col span={5}>
                <Skeleton.Input active={true} block={true} />
            </Col>
            <Col span={4}>
                <Skeleton.Button active={true} block={true} />
            </Col>

        </Row>
            <br /></>} */}
        <Row gutter={[16, 32]}>
            <Col span={24}>
                <Skeleton.Button active={true} block={true} />
            </Col>
            <Col span={24}>
                <Skeleton.Button active={true} block={true} />
            </Col>    <Col span={24}>
                <Skeleton.Button active={true} block={true} />
            </Col>    <Col span={24}>
                <Skeleton.Button active={true} block={true} />
            </Col>    <Col span={24}>
                <Skeleton.Button active={true} block={true} />
            </Col>
            <Col span={24}>
                <Skeleton.Button active={true} block={true} />
            </Col> <Col span={24}>
                <Skeleton.Button active={true} block={true} />
            </Col> <Col span={24}>
                <Skeleton.Button active={true} block={true} />
            </Col>
        </Row>
    </>
    )
}

