import { Value } from '../Value/value.model';
import { serializable, alias, object, primitive } from 'serializr';

export class VisitorDetails {
	@serializable(alias('id', primitive()))
	id?: string;

	@serializable(alias('value', object(Value)))
	value?: Value;
}
