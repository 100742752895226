import { Button, Form, Space, Typography, Row, Col } from 'antd';
import { ButtonTypes } from 'enums/buttonTypes';
import { Formik, Form as FormikForm } from 'formik';
import React from 'react';
import { ChangePassword } from 'resources/Icons';
import ProfileService from 'services/ProfileService/profile.service';
import ButtonBase from '../Button';
import InputField from '../InputField';
import ModalComponent from '../Modal';
import { changePasswordValidation } from './changePasswordValidation';
import "./changePassword.scss"
const { Text } = Typography;
export default function ChangePasswordModal({
	changeModalState,
	showModal,
}: {
	changeModalState: any;
	showModal: boolean;
}) {
	const { changePasswordButtonLoading, changePassword } = ProfileService();
	// const [showModal, setShowModal] = useState(false);
	const initialValues = {
		password: '',
		changePassword: '',
		confirmPassword: '',
	};
	// const changeModalState = () => setShowModal(!showModal);
	const onSubmit = async (data: typeof initialValues): Promise<void> => {
		const params = {
			current_password: data.password,
			new_password: data.changePassword,
			new_password_confirmation: data.confirmPassword,
		};
		const response = await changePassword(params);
		if (response === 200) {
			changeModalState();
		}
	};
	return (
		<ModalComponent
			shouldModalOpen={changeModalState}
			visible={showModal}
			title={
				<Space direction="horizontal">
					<ChangePassword color="#65BCB0" />
					<Text>Change Password</Text>
				</Space>
			}
			okButtonText="Update"
			cancelButtonText="Cancel"
			onButtonOkClick={() => console.log('clicked')}
			showFooter={false}
			buttonType="submit"
		>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validationSchema={changePasswordValidation}
			>
				{({values}) => (
				<FormikForm>
					<Form.Item className="passwordChange">
						<label className={'floating-label-password '}>Password</label>
						<InputField type="password" name="password" showLabel={false} />
					</Form.Item>
					<Form.Item className="passwordChange">
						<label className={'floating-label-password '}>Change Password</label>
						<InputField type="password" name="changePassword" showLabel={false}  />
					</Form.Item>
					<Form.Item className="passwordChange">
						<label className={'floating-label-password'}>Confirm Password</label>
						<InputField type="password" name="confirmPassword" showLabel={false} />
					</Form.Item>
					<Row>
						<Col>
							<Button
								type="primary"
								htmlType="submit"
								loading={changePasswordButtonLoading}
								// eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={async()=> await onSubmit({
                                    password: values.password,
                                    changePassword: values.changePassword,
                                    confirmPassword: values.confirmPassword,
                                })}
							>
								Change Password
							</Button>
						</Col>
						<Col>
							<ButtonBase
								type={ButtonTypes.TERTIARY}
								onclick={changeModalState}
								text="Close"
								ClassName="cancel__button"
							/>
						</Col>
					</Row>
				</FormikForm>
				)}
			</Formik>
		</ModalComponent>
	);
}
