import { useState } from 'react';
import { deserialize } from 'serializr';

import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { OnboardingDocuments } from 'models/OnboardingDocuments/onboardingDocuments.model';
import { Pagination } from 'models/Pagination/pagination.model';
import { DocumentsList } from 'models/OnboardingDocuments/documentList.model';
import Notification from 'shared/components/Notification';
import { NotificationTypes } from 'enums/notificationTypes';
import { AgreementStatusId } from 'models/AgreementStatusId/AgreementStatusId.model';

const DocumentsService = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const [documents, setDocuments] = useState<OnboardingDocuments[]>([]);
	const [documentNameList, setdocumentNameList] = useState<DocumentsList[]>();
	const [agreementStatusId, setAgreementStatusId] = useState<AgreementStatusId>()

	const [documentsPagination, setDocumentsPagination] =
		useState<Pagination | null>();

	const fetchDocuments = async (params?: {
		search?: string;
		page?: number;
	}) => {
		try {
			setLoading(true);
			setButtonLoading(true);
			const response = await axiosInstance.get(ApiRoutes.ONBOARDING_DOCUMENT, {
				params,
			});
			const dataResponse = response.data.data;
			const documents = deserialize(
				OnboardingDocuments,
				dataResponse.Student
			);
			if(dataResponse.agreements){
				const agreementStatus = deserialize(AgreementStatusId,dataResponse.agreements[0]);
				setAgreementStatusId(agreementStatus);
			}
			setButtonLoading(false);
			setLoading(false);
			setDocuments(documents as OnboardingDocuments[]);
		} catch (error) {
			setLoading(false);
			setDocumentsPagination(null);
			setDocuments([]);
		} finally {
			setLoading(false);
		}
	};

	const getDocumentNameList = async () => {
		try {
			setLoading(true);
			const res = await axiosInstance.get(ApiRoutes.DOCUMENT_NAME_LIST);
			const documentsList = deserialize(DocumentsList, res.data.data);
			setdocumentNameList(documentsList as DocumentsList[]);
			setLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const uploadDocuments = async (params: {
		document?: any;
		documentName?: number;
		documentFor?: string;
		idNumber?: string;
		documentType?: string;
		expiryDate?: string;
		isUploaded?: boolean;
		documentId?: string;
	}) => {
		try {
			setLoading(true);
			setButtonLoading(true);
			const formData = new FormData();
			const {
				document,
				documentName = '',
				documentFor = '',
				idNumber = '',
				documentType = '',
				expiryDate = '',
				isUploaded = false,
				documentId,
			} = params;
			if (document !== undefined) {
				formData.append(`document`, document);
				formData.append(`document_name`, `${documentName}`);
				formData.append(`document_for`, documentFor);
				formData.append(`id_number`, idNumber);
				formData.append(`document_type`, documentType);
				formData.append(`expiry_date`, expiryDate);
				formData.append(`is_uploaded`, String(isUploaded));
			} else {
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				Notification({
					message: 'Try again',
					description: 'Document is missing',
					type: NotificationTypes.ERROR,
				});
			}
			if (documentId !== undefined) {
				const response = await axiosInstance.post(
					`${ApiRoutes.DOCUMENT_UPDATE}/${documentId}`,
					formData
				);

				if (response.status === 200) {
					// eslint-disable-next-line @typescript-eslint/no-floating-promises
					Notification({
						message: 'Success',
						description: 'Successfully uploaded the documennt',
						type: NotificationTypes.SUCCESS,
					});
				} else {
					// eslint-disable-next-line @typescript-eslint/no-floating-promises
					Notification({
						message: 'Success',
						description: response.data,
						type: NotificationTypes.ERROR,
					});
					setLoading(false);
				}
			} else {
				const response = await axiosInstance.post(
					`${ApiRoutes.ONBOARDING_DOCUMENT}`,
					formData
				);

				if (response.status === 200) {
					// eslint-disable-next-line @typescript-eslint/no-floating-promises
					Notification({
						message: 'Success',
						description: 'Successfully uploaded the documennt',
						type: NotificationTypes.SUCCESS,
					});
				} else {
					// eslint-disable-next-line @typescript-eslint/no-floating-promises
					Notification({
						message: 'Success',
						description: response.data,
						type: NotificationTypes.ERROR,
					});
					setLoading(false);
				}
			}
			setButtonLoading(false);
		} catch (error: any) {
			const { response } = error;
			setButtonLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	return {
		loading,
		documents,
		buttonLoading,
		fetchDocuments,
		documentsPagination,
		getDocumentNameList,
		documentNameList,
		uploadDocuments,
		agreementStatusId
	};
};

export default DocumentsService;
