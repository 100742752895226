import React from "react";
import { Row, Col, DatePicker, Button } from 'antd';
import { InputFieldWithIcon } from "shared/components/InputField";

import type { DatePickerProps } from 'antd';
import { useTitleContext } from "context/TitleComponentContext";
// import ButtonBase from "shared/components/Button";
import { ButtonTypes } from "enums/buttonTypes";
import useResponsive from "shared/Hooks/useResponsive";
import './navAbsolute.scss';
import moment from "moment";
import { SearchIcon } from "resources/Icons";
type titleComponentProps = {
    onclick: () => void,
    buttonText?: string | JSX.Element,
    showButton?: boolean,
    loading?: boolean,
    buttonDisabled?: boolean,
    placeholder?: string;
}
export const TitleComponentPayments = React.memo(function Test({placeholder="Search by invoice details", onclick, buttonText = 'Download Statement', showButton = true, loading = false, buttonDisabled = false }: titleComponentProps) {
    const { setDate, setSearch } = useTitleContext();
    const { isMobile } = useResponsive();
    const onChange: DatePickerProps['onChange'] = (_, dateString) => {
        // console.log(dateString);

        if (!dateString) {
            setDate();
            return;
        }
        setDate(dateString);
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function debounce<F extends (...params: any[]) => void>(fn: F, delay: number) {
        let timeoutID: number = 0;
        return function (this: any, ...args: any[]) {
            clearTimeout(timeoutID);
            timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
        } as F;
    }

    const processChange = debounce((e) => onSearchChange(e), 300);


    const onSearchChange = (e: any) => {
        setSearch(e);
    }


    return <Row align="middle" gutter={[12, 0]} className={isMobile ? 'navAbsolute' : undefined}>

        <Col span={10} xs={16} xl={10}>
            <InputFieldWithIcon
                type="text"
                name="search"
                prefix={<SearchIcon />}
                placeholder={placeholder}
                allowClear={true}
                onChange={(e: any) => processChange(e.target.value)}
            />
        </Col>
        <Col span={14} xs={8} xl={14} >
      <Row gutter={{xs:46,sm:26,md:24}}>
        <Col xs={10} xl={10}>

                <DatePicker picker="month"
                 disabledDate={(current) => current.isAfter(moment().subtract(1, "day"))} placeholder='Select month'  format={(value)=>moment(value).format('MMM YYYY')} onChange={onChange}
                 className={isMobile ? "datepicker-mobile" : ""}
                 />
        </Col>
        <Col xs={5} xl={12}>

                {showButton && (
                    // <Col span={8}>
                    <Button
                        type={ButtonTypes.PRIMARY}
                        onClick={onclick}
                        className="table-title-btn table-download-btn"
                        loading={loading}
                        disabled={buttonDisabled}
                    >
                        {buttonText}
                    </Button>
                    //  </Col>
                )}
        </Col>
      </Row>
                {/* <Col span={6}> */}



                {/* <InputFieldWithIcon type="date" name="" placeholder="Select month" /> */}
                {/* </Col> */}
       
        </Col>
    </Row>
});

