import React from 'react';
import { Row, Col, Skeleton } from 'antd';
export default function BookingsSkeleton() {
	return (
		<>
			<Col className='skeleteon-col-container'>
			<Col span={24} md={8}>
				<Row gutter={[16, 16]}>
					<Skeleton.Image />

					<Skeleton paragraph={true} />
				</Row>
			</Col>

			<Col span={24} md={8}>
				<Row gutter={[16, 16]}>
					<Skeleton.Image />

					<Skeleton paragraph={true} />
				</Row>
			</Col>
			<Col span={24} md={8}>
				<Row gutter={[16, 16]}>
					<Skeleton.Image />

					<Skeleton paragraph={true} />
				</Row>
			</Col>
			</Col>
		</>
	);
}
