import * as Yup from 'yup';
import { REGEX } from 'shared/utils/regex';
export const forgotPasswordValidationSchema = Yup.object().shape({
	qcareEmail: Yup.string()
		.email('E-mail is not valid!')
		.required('E-mail is required!'),
});

export const verifyEmailValidationSchema = Yup.object().shape({
	otp: Yup.string().required('OTP is required'),
});

export const resetPasswordValidationSchema = Yup.object({
	otp: Yup.string().required('OTP is required'),
	newPassword: Yup.string()
		.min(8, 'Password has to be longer than 8 characters!')
		.required('Password is required!')
		.matches(REGEX.PASSWORD, "One Uppercase, One Lowercase, One Number and one special case Character"),
	confirmPassword: Yup.string()
		.required('Confirm password is required!')
		.oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});
