import { useElectricityTokenContext } from 'context/ElectricityTokenContext';

import { useEffect } from 'react';
import ElectricitytokenService from 'services/ElectricitytokenService/ElectricityToken.service';

export const useElectricityToken = () => {
	const { electricityTokenProvider, setElectricityTokenProvider } =
		useElectricityTokenContext();
	const { fetchElectricitytokens, ElectricityTokens } =
		ElectricitytokenService();
	// useEffect(() => {
	// 	if (
	// 		electricityTokenProvider === undefined ||
	// 		electricityTokenProvider?.length <= 0
	// 	) {
	// 		void fetchElectricitytokens();
	// 	}

	// 	return () => {};
	// }, []);

	useEffect(() => {
		if (
			electricityTokenProvider === undefined ||
			electricityTokenProvider?.length <= 0
		) {
			void fetchElectricitytokens();
		}
	}, [electricityTokenProvider]);

	useEffect(() => {
		if (ElectricityTokens?.length > 0) {
			setElectricityTokenProvider(ElectricityTokens);
		} else if (electricityTokenProvider?.length > 0) {
			setElectricityTokenProvider(electricityTokenProvider);
		} else {
			setElectricityTokenProvider();
		}
	}, [ElectricityTokens]);

	return {
		electricityTokenProvider,
	};
};
