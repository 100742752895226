import { Building } from '../Building/building.model';

import { StatusId } from '../StatusId/statusId.model';
import { ElectricityToken } from 'models/ElectricityToken/electricityToken.model';

import { serializable, alias, object, primitive } from 'serializr';

export class Dashboard {
	@serializable(alias('id', primitive()))
	id?: string;

	@serializable(alias('booking_id', primitive()))
	bookingId?: string;

	@serializable(alias('building_id', primitive()))
	buildingId?: string;

	@serializable(alias('status_id', object(StatusId)))
	statusId?: StatusId;

	@serializable(alias('checkin', primitive()))
	checkin?: string;

	@serializable(alias('checkout', primitive()))
	checkout?: string;

	@serializable(alias('deposit_paid', primitive()))
	depositPaid?: number;

	@serializable(alias('first_month_rent', primitive()))
	firstMonthRent?: number;

	@serializable(alias('amount_due', primitive()))
	amountDue?: number;

	@serializable(alias('building', object(Building)))
	building?: Building;

	@serializable(alias('room_type', primitive()))
	roomType?: string;

	@serializable(alias('room_type_id', primitive()))
	roomTypeId?: number;

	@serializable(alias('room_number', primitive()))
	roomNumber?: string;

	@serializable(alias('meter_number', primitive()))
	meterNumber?: string;

	@serializable(alias('discount_percentage', primitive()))
	discountPercentage?: string;

	@serializable(alias('skill_share_access', primitive()))
	skillShareAccess?: number;

	@serializable(alias('electricity', object(ElectricityToken)))
	electricity?: ElectricityToken;
}
