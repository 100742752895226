import { CommonInterest } from 'models/CommonInterest/commonInterest.model';
import { useState } from 'react';
import Notification from 'shared/components/Notification';
import { NotificationTypes } from 'enums/notificationTypes';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';

const KnowyourcommunityService = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [buttonLoading, setButtonLoading] = useState<boolean>(false);
	const [commoninterests, setCommoninterests] = useState<CommonInterest[]>();
	const [userInterests, setUserInterests] = useState<
		[
			{
				name: string;
				pivot: {
					user_id: number;
					interest_id: number;
				};
			}
		]
	>();
	const [interestsList, setInterestsList] = useState<
		[
			{
				id: number;
				name: string;
				interests: [{ id: string; name: string; type_id: number }];
			}
		]
	>();

	const [interestListFlattened, setInterestListFlattened] = useState<any[]>();
	const getUserInterests = async () => {
		try {
			const response = await axiosInstance.get(ApiRoutes.GET_USER_INTERESTS);
			if (response.status === 200) {
				setUserInterests(response.data.data);
			}
		} catch (error: any) {
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};
	const fetchAllInterests = async (params?: {
		search?: string;
		page?: number;
	}) => {
		try {
			setButtonLoading(true);
			setLoading(true);
			const response = await axiosInstance.get(ApiRoutes.GET_ALL_INTERESTS);

			setInterestsList(response.data.data);
			setInterestListFlattened([
				...response.data.data.filter((i: any) => i.id === 1)[0].interests,
				...response.data.data.filter((i: any) => i.id === 2)[0].interests,
				...response.data.data.filter((i: any) => i.id === 3)[0].interests,
			]);

			setButtonLoading(false);
		} catch (error) {
			setButtonLoading(false);
		} finally {
			setLoading(false);
		}
	};
	const getCommonInterests = async (params?: { search?: string }) => {
		try {
			setButtonLoading(true);
			const response = await axiosInstance.get(ApiRoutes.COMMON_INTERESTS, {
				params,
			});
			if (response.status === 200) {
				setCommoninterests(response.data.data.data);
			}
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			// Notification({
			// 	message: response.data.status,
			// 	description: response?.data.message,
			// 	type:
			// 		response.status === 200
			// 			? NotificationTypes.SUCCESS
			// 			: NotificationTypes.ERROR,
			// });
			setButtonLoading(false);
		} catch (error: any) {
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const enableInterest = async (params: { share?: number }) => {
		try {
			const response = await axiosInstance.post(
				ApiRoutes.ENABLE_INTEREST,
				params
			);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: 'Success',
				description: response?.data.message,
				type:
					response.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});
			return response.status;
		} catch (error: any) {
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: 'Something went wrong',
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
			console.log(error);
		}
	};
	const updateInterests = async (params: {
		interests: Array<{ name: string; type: number }>;
	}) => {
		try {
			setButtonLoading(true);
			const response = await axiosInstance.put(
				ApiRoutes.UPDATE_INTERESTS,
				params
			);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: 'Success',
				description: response?.data.message,
				type:
					response.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});
			setButtonLoading(false);
			return response.status;
		} catch (error: any) {
			setButtonLoading(false);
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: 'Something went wrong',
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
			console.log(error);
		}
	};

	return {
		loading,
		fetchAllInterests,
		buttonLoading,
		interestsList,
		interestListFlattened,
		updateInterests,
		getCommonInterests,
		commoninterests,
		enableInterest,
		userInterests,
		getUserInterests,
	};
};

export default KnowyourcommunityService;
