import React from 'react';
// import Alert from 'shared/components/Alert/ShowAlert';
const RequireAlert = ({ children }: { children: JSX.Element }) => {
	return (
		<>
			{children}
			{/* <Alert /> */}
		</>
	);
};

export default RequireAlert;
