import { serializable, alias, primitive } from 'serializr';

export class Value {
	@serializable(alias('checkinDate', primitive()))
	checkinDate?: string;

	@serializable(alias('checkoutDate', primitive()))
	checkoutDate?: string;

	@serializable(alias('name', primitive()))
	name?: string;
}
