import React, { createContext, ReactNode, useState } from 'react';
const ProfileContext = createContext<any>(null);
export type countriesListType = Array<{
    calling_code: string;
    citizenship: string;
    currency_code: string;
    id: number;
    name: string;
}>;
export type salutationListType = Array<{
    created_at: string;
    id: number;
    salutation: string;
    updated_at: string;
}>
export function ProfileCountriesProvider({ children }: { children: ReactNode }): any {

    const [countriesListProvider, setCountriesList] = useState<countriesListType>([])
    const [salutationListProvider, setSalutationList] = useState<salutationListType>([]);


    return (
        <ProfileContext.Provider
            value={{
                countriesListProvider,
                setCountriesList,
                salutationListProvider,
                setSalutationList
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
}

export const useProfileContext = (): any => React.useContext(ProfileContext);
