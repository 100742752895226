import React from 'react';
import Lottie from 'react-lottie';
import animationData from 'Animations/sadface.json';
export default function SadAnimation() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        renderer: 'svg',
    };
    return (
        <div>
            <Lottie options={defaultOptions} height={80} width={70} />
        </div>
    );
}