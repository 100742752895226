import { serializable, alias, primitive } from 'serializr';

export class VisitorValue {
	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('phone', primitive()))
	phone?: string;

	@serializable(alias('createdAt', primitive()))
	createdAt?: string;

	@serializable(alias('expectedAt', primitive()))
	expectedAt?: string;
}
