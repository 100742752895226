import React from 'react'
import './sidebarlogo.scss'
type logoProps = {
    src?: string | any,
}
export default function SidebarLogo({ src }: logoProps) {
    return (
        <div className="logo">
            <img src={src} className="logo-image" />

        </div>
    )
}
