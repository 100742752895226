import { useState } from 'react';
import { deserialize } from 'serializr';
// import { generatePath } from 'react-router-dom';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { VisitorDetails } from 'models/VisitorDetails/visitorDetails.model';
import { VisitorInvites } from 'models/VisitorInvites/VisitorInvites.model';
// import { Pagination } from './../../models/Pagination/pagination.model';
import Moment from 'moment';
import { TagText } from 'shared/components/Cards';
import Notification from 'shared/components/Notification';
import { NotificationTypes } from 'enums/notificationTypes';
const VisitorlogsService = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const [VisitorLogs, setVisitorlogs] = useState<VisitorDetails[] | any[]>([]);
	const [VisitorInvitesData, setVisitorInvitesData] = useState<
		VisitorInvites[]
	>([]);

	// const [VisitorLogsPagination, setVisitorlogsPagination] =
	// 	useState<Pagination | null>();

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const fetchVisitorlogs = async (params?: {
		search?: string;
		page?: number;
	}) => {
		try {
			setLoading(true);
			setButtonLoading(true);
			const response = await axiosInstance.get(ApiRoutes.VISITOR_LOGS, {
				params,
			});
			const VisitorLogs = deserialize(
				VisitorDetails,
				response.data.data
			) as VisitorDetails[];
			// const pagination = deserialize(Pagination, response.data.meta);

			// setVisitorlogsPagination(pagination);
			// eslint-disable-next-line array-callback-return
			const data = VisitorLogs.map((item, index) => {
				if (item !== null) {
					const { checkinDate, checkoutDate } = item.value ?? {};
					const checkedDate = Moment(checkinDate ?? '1996/11/05').format(
						'MMMM Do YYYY, h:mm:ss a'
					);
					const checkedoutDate = Moment(checkoutDate).format(
						'MMMM Do YYYY, h:mm:ss a'
					);
					// if current date is passed the checkoutdate and time then the status would be checkedout
					// if the checkindate is greater or equal to current date time but less than checkout date then the status
					// would be checked in
					const currentMoment = Moment().format('MMMM Do YYYY, h:mm:ss a');
					const isCheckedIn = Moment(currentMoment).isBetween(
						checkedDate,
						checkedoutDate
					);
					// console.log(Moment().format('MMMM Do YYYY, h:mm:ss a'))
					const { name } = item.value ?? {};
					const status = !checkoutDate ? TagText.CheckedIn : isCheckedIn ? TagText.CheckedIn : TagText.CheckedOut;
					return { checkinDate, checkoutDate, name, status };
				}
			});
			setVisitorlogs(data);
		} catch (error) {
			// setVisitorlogsPagination(null);
			setVisitorlogs([]);
			return;
		} finally {
			setLoading(false);
		}
	};
	const fetchVisitorInvites = async (params?: {
		search?: string;
		page?: number;
	}) => {
		try {
			setLoading(true);

			const response = await axiosInstance.get(ApiRoutes.VISITOR_INVITES, {
				params,
			});
			const VisitorInvitesDataFetched = deserialize(
				VisitorInvites,
				response.data.data
			) as VisitorInvites[];
			// const pagination = deserialize(Pagination, response.data.meta);

			// setVisitorlogsPagination(pagination);
			// eslint-disable-next-line array-callback-return
			// const data = VisitorInvitesData.map((item, index) => {
			// 	if (item !== null) {
			// 		const { createdAt, expectedAt } = item.value ?? {};
			// 		const createdAtChanged = Moment(createdAt ?? '1996/11/05').format(
			// 			'MMMM Do YYYY, h:mm:ss a'
			// 		);
			// 		const expectedAtChanged = Moment(expectedAt).format(
			// 			'MMMM Do YYYY, h:mm:ss a'
			// 		);
			// 		// if current date is passed the checkoutdate and time then the status would be checkedout
			// 		// if the checkindate is greater or equal to current date time but less than checkout date then the status
			// 		// would be checked in
			// 		// const currentMoment = Moment().format('MMMM Do YYYY, h:mm:ss a');
			// 		// const isCheckedIn = Moment(currentMoment).isBetween(
			// 		// 	checkedDate,
			// 		// 	checkedoutDate
			// 		// );
			// 		// console.log(Moment().format('MMMM Do YYYY, h:mm:ss a'))
			// 		// const { name } = item.value ?? {};
			// 		// const status = isCheckedIn ? TagText.CheckedIn : TagText.CheckedOut;
			// 		return { value:{createdAt:createdAtChanged, expectedAt:expectedAtChanged,...item.value} };
			// 	}
			// });
			setVisitorInvitesData(VisitorInvitesDataFetched);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			// setVisitorlogsPagination(null);
			setVisitorlogs([]);
			// setLoading(true);
			return;
		} finally {
			setLoading(false);
		}
	};

	const inviteGuest = async (params: {
		dialCode: string;
		email: string;
		expected_at: string;
		name: string;
		phone: string;
	}) => {
		try {
			setButtonLoading(true);
			const response = await axiosInstance.post(
				ApiRoutes.VIZMO_INVITES,
				params
			);
			void Notification({
				message: response.data.status,
				description: response.data.message,
				type:
					response.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});
			setButtonLoading(false);
			return response.status;
		} catch (error: any) {
			setButtonLoading(false);
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	// const createVisitorlogs = async (VisitorLogs: Visitorlogs) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { VisitorLogs: serialize(Visitorlogs, VisitorLogs) }
	//         const response = await axiosInstance.post(ApiRoutes.VISITORLOGS, payload)
	//         const data = deserialize(Visitorlogs, response.data["visitorlogs"]) as Visitorlogs;
	//         setVisitorlogs(data)
	//     } catch (error) {
	//         setVisitorlogs(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const updateVisitorlogs = async (VisitorLogs: Visitorlogs) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { VisitorLogs: serialize(Visitorlogs, VisitorLogs) }
	//         const API_URL = generatePath(ApiRoutes.VISITORLOGS_SPECIFIC, { VisitorLogsId: String(VisitorLogs.id) })
	//         const response = await axiosInstance.put(API_URL, payload)
	//         const data = deserialize(Visitorlogs, response.data["visitorlogs"]) as Visitorlogs;
	//         setVisitorlogs(data)
	//     } catch (error) {
	//         setVisitorlogs(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const deleteVisitorlogs = async (VisitorLogsId: string) => {
	//     try {
	//         setButtonLoading(true)
	//         const API_URL = generatePath(ApiRoutes.VISITORLOGS_SPECIFIC, { VisitorLogsId })
	//         await axiosInstance.delete(API_URL)
	//         return true
	//     } catch (error) {
	//         return false
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const showVisitorlogs = async (VisitorLogsId: string) => {
	//     try {
	//         setLoading(true)
	//         const API_URL = generatePath(ApiRoutes.VISITORLOGS_SPECIFIC, { VisitorLogsId })
	//         const response = await axiosInstance.get(API_URL)
	//         const data = deserialize(Visitorlogs, response.data["visitorlogs"]) as Visitorlogs;
	//         setVisitorlogs(data)
	//     } catch (error) {
	//         setVisitorlogs(null)
	//     } finally {
	//         setLoading(false)
	//     }
	// }

	return {
		loading,
		VisitorLogs,
		buttonLoading,
		fetchVisitorlogs,
		inviteGuest,
		fetchVisitorInvites,
		VisitorInvitesData,
	};
};

export default VisitorlogsService;
