import React from 'react'
import { Col, Divider, Row, Skeleton, Space } from 'antd';
import useResponsive from 'shared/Hooks/useResponsive';
// import BookingsSkeleton from 'views/Bookings/BookingsSkeleton';
import './home.scss'
import HomeBookings from 'views/Bookings/HomeBookings';
import { TableSkeleton } from 'shared/components/Table/TableSkeleton';
export default function HomeLoader() {
    const { isMobile } = useResponsive();
    return (<Row gutter={[36, 60]}>
        <Col span={isMobile ? 24 : 16} md={16} lg={17} xl={21} className={`fixedColumn h-100`} style={{ paddingLeft: '4rem', paddingTop: '1rem', paddingBottom: '1rem' }}>
            <Row gutter={[36, 36]}>
                <Col span={24}>
                    <Skeleton.Input active={true} />
                </Col>
                <Col span={24}>
                    <Space direction="horizontal">
                        <Skeleton.Input active={true} />
                        <Skeleton.Input active={true} />
                    </Space>
                </Col>
                <Col span={24}>
                    <Row gutter={[46, 46]}>
                        <Col span={24}>
                            <HomeBookings />
                        </Col>
                        {/* <Col span={24}>
                            <HomeBookings />
                        </Col> */}
                        {/* <Col span={12}>
                            <BookingsSkeleton />
                        </Col>
                        <Col span={12}>
                            <BookingsSkeleton />
                        </Col> */}
                        {/* <Col span={12}>
                            <BookingsSkeleton />
                        </Col> */}
                    </Row>
                </Col>
                <Col span={19}>
                    <TableSkeleton />
                </Col>
            </Row>

        </Col>
        {!isMobile && (
            <Col span={5} md={7} lg={5} xl={6} className="right-part-home">
                <Row gutter={[24, 24]} style={{ paddingTop: '0.5rem' }}>
                    <Col span={24}>
                        <Skeleton.Button active={true} size="small" block={true} />
                    </Col>
                    <Col span={24}>
                        <Skeleton.Button active={true} size="small" block={true} />
                    </Col>
                    <Col span={24}>
                        <Skeleton.Image />
                    </Col>

                    <Col span={24}>
                        <Skeleton paragraph={true} />
                    </Col>
                    <Divider />
                    <Col span={24}>
                        <Skeleton paragraph={true} />
                    </Col>

                </Row>
            </Col>)}
    </Row>
    )
}
