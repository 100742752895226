import React from 'react';

import { AuthProvider } from './context/AuthContext';

import 'antd/dist/antd.css';
import './styles/_main.scss';
import { ProfileCountriesProvider } from 'context/ProfileContext';

import AuthRouterWrapper from 'routes/AppRouterWrapper';
import { ElectricityTokenProvider } from 'context/ElectricityTokenContext';
import { NavbarNotificationsProvider } from 'context/NavbarNoticesContext';

const App = (): JSX.Element => {
	return (
		<AuthProvider>
			<ProfileCountriesProvider>
				<ElectricityTokenProvider>
					<NavbarNotificationsProvider>
						<AuthRouterWrapper />
					</NavbarNotificationsProvider>

				</ElectricityTokenProvider>

			</ProfileCountriesProvider>

		</AuthProvider>
	);
};

export default App;
