import React from 'react'
import {Row,Col} from 'antd';
import { VukaIcon, VukaIconMobile } from 'resources/Icons';

const VukaFooter=({hovered, isMobile}:{hovered:Boolean, isMobile?: boolean}) =>{
  return (
    <Row className="logo" gutter={[8, 8]} align="middle" justify="center">
					{/* <Col span={24}>
						<p>Start Investing Now with</p>
					</Col> */}
					<Col span={24}>
					
					<a href="https://vuka.co.ke/" target="_blank" rel="noreferrer">
						<div className="logo">
							<p className="bottom">Start investing now with</p>
							{isMobile ? <VukaIconMobile color={hovered ? 'white' : undefined}  /> : <VukaIcon color={hovered ? 'white' : undefined} />}
						</div>
					</a>
					</Col>
				</Row>
  )
}

export default VukaFooter;
