import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Home from '../views/Home';
import { RouterProps } from '../shared/types/route.type';
import { AppRoutes } from './routeConstants/appRoutes';

import Profile from 'views/AppComponents/Profile';
import  ImportantDocuments  from 'views/ImportantDocuments';
import { Notices } from 'views/Notices';
import { Bookings } from 'views/Bookings';
import { VisitorDetails } from 'views/VisitorDetails';
import { ElectricityToken } from 'views/ElectricityToken';
import Financials from 'views/Financials';
import Maintenance from 'views/Maintenance';

import FAQ from 'views/ FAQ';
import { KnowYourCommunity } from 'views/KnowYourCommunity';
// import { Agreement } from 'views/ImportantDocuments/Agreement';
// import Documents from 'views/ImportantDocuments/Documents';
export const appHistory = createBrowserHistory();

const AppRouter = () => {

	const routes: RouterProps[] = [
		{ path: AppRoutes.HOME, component: <Home /> },
		{ path: AppRoutes.PROFILE, component: <Profile /> },
		{ path: AppRoutes.IMPORTANT_DOCUMENTS, component: <ImportantDocuments /> },
		{ path: AppRoutes.IMPORTANT_DOCUMENTS_EXTENSIONS, component: <ImportantDocuments /> },
		{ path: AppRoutes.NOTICE, component: <Notices /> },
		{ path: AppRoutes.BOOKING, component: <Bookings /> },
		{ path: AppRoutes.FINANCIALS, component: <Financials /> },
		{ path: AppRoutes.VISITOR, component: <VisitorDetails /> },
		{ path: AppRoutes.ELECTRICITY, component: <ElectricityToken /> },
		{ path: AppRoutes.MAINTENANCE, component: <Maintenance /> },
		{ path: AppRoutes.FAQ, component: <FAQ /> },
		{ path: AppRoutes.COMMUNITY, component: <KnowYourCommunity /> },
	];


	return (


		<>
			<Routes>
			
				{routes?.map((route, index) => {
					return (
						<Route
							key={index}
							path={route?.path}
							element={route?.component}
						/>
					);
				})}
				
				{/* </Route> */}
				{/* <Route path="*" element={<Navigate to={appRoutes.AUTH} />} /> */}
			</Routes>
		</>


	);
};

export default AppRouter;
