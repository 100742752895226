export const ApiRoutes = {
	BASE_URL: process.env.REACT_APP_API_BASE_URL,
	BASE_URL_PAYMENTS: process.env.REACT_APP_API_BASE_URL_PAYMENT_GATEWAY,
	USER_LOGIN: '/login',
	PROFILE: '/profile',
	PROFILES: '/profiles',
	GUARDIAN_DETAILS: '/guardian-details',
	NOTICES: '/notices',
	BOOKINGS: '/user-bookings',
	WAITING_LIST: '/waiting-list',
	VISITOR_LOGS: '/vizmo-visitors',
	ELECTRICITY_TOKEN: '/electricity-tokens',
	PAYMENTS: '/payment-log',
	INVOICES: '/user-payments-bookings',
	MAINTENANCE: '/tickets',
	REFUNDS: '/refunds',
	DOWNLOAD_STATEMENT: '/accounts/download-statement',
	DOWNLOAD_STATEMENT_PER_INVOICE: '/accounts/download-invoice',
	DOWNLOAD_PAYMENT_RECEIPT: '/download-payment-receipt/',
	DASHBOARD: '/dashboard',
	REFUND_OTP: '/send-refund-otp',
	TICKET_CATEGORIES: '/ticket-categories',
	TICKET_LOCATIONS: '/ticket-locations',
	TICKETS: '/tickets',
	RESIDENT_DASHBOARD_ROOM: 'resident-room',
	UPDATE_GUARANTOR: '/update-guarantor',
	RESIDENT_DASHBOARD_CONTENT: '/resident-dashboard-content',
	USER_PROFILE: '/user-profile',
	UPDATE_TICKET_ATTACHMENTS: '/update-ticket-attachments',
	UPDATE_TICKET_STATUS: '/update-ticket-status',
	INSTITUTIONS: '/institutions',
	YEAR_OF_STUDY: '/year-of-study-list',
	COUNTRIES_LIST: '/countries-list',
	SALUTATION: '/salutation',
	ONBOARDING_DOCUMENT: '/onboarding-document',
	DOCUMENT_NAME_LIST: '/document-name-list',
	DOCUMENT_UPDATE: '/onboarding-document/update',
	AGREEMENT_STATUS: '/agreement-status',
	DOWNLOAD_AGREEMENT: '/user-agreement/download',
	GENERATE_DOCUSIGN_LINK :'/authorize-docusign',
	DOCUSIGN_SEND_MAIL:'/docusign-access-token',
	CURRENCY_LIST: '/currencies-list',
	UPDATE_RESIDENT_ACCOUNT: '/update-resident-account',
	GET_OTP: '/account-settings/get-otp',
	VERIFY_OTP: '/account-settings/verify-otp',
	UPDATE_PROFILE_PICTURE: '/update-profile-pic',
	CHANGE_PASSWORD: '/change-password',
	REFER_A_FRIEND: '/refer-a-friend',
	VIZMO_INVITES: '/vizmo-invites',
	GET_ALL_INTERESTS: '/interests/all-interest',
	COMMON_INTERESTS: '/interests/common-interest',
	UPDATE_INTERESTS: '/interests/update-interest',
	ENABLE_INTEREST: '/interests/enable-interest',
	APP_NOTIFICATIONS: '/notifications',
	MARK_ALL_AS_READ: '/notifications/mark-all-read',
	MARK_READ: '/notifications/mark-read',
	GET_SKILL_SHARE_ACCESS: '/skill-share-access',
	GET_USER_INTERESTS: '/interests',
	VISITOR_INVITES: '/vizmo-invites',
	FORGOT_PASSWORD_GENERATE_OTP: '/forgotpass-otp',
	FORGOT_PASSWORD_VERIFY_OTP: '/verify-otp',
	RESET_PASSWORD: '/update-password',
};
