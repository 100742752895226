import React, { FC ,memo} from 'react';
import AppRouter from 'routes';
import SiderWrapperLayout from '../SiderWrapper';

type AppWrapperProps = {};

const AppWrapper: FC<AppWrapperProps> = (props): JSX.Element => {
	return (
		<SiderWrapperLayout>
			<AppRouter />
		</SiderWrapperLayout>
	);
};

export default memo(AppWrapper);
