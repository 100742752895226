import { useState } from 'react';
import { deserialize } from 'serializr';
// import { generatePath } from 'react-router-dom';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { WaitingList as WaitinglistModel } from 'models/WaitingList/waitingList.model';
import { Pagination } from './../../models/Pagination/pagination.model';

const WaitinglistService = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const [waitingLists, setWaitinglists] = useState<WaitinglistModel[]>([]);

	const [waitingListsPagination, setWaitinglistsPagination] =
		useState<Pagination | null>();

	const fetchWaitinglists = async (params?: {
		search?: string;
		page?: number;
	}) => {
		try {
			setLoading(true);
			setButtonLoading(true);
			const response = await axiosInstance.get(ApiRoutes.WAITING_LIST, {
				params,
			});
			const waitingList = deserialize(
				WaitinglistModel,
				response.data.data
			) as WaitinglistModel[];
			const pagination = deserialize(Pagination, response.data.meta);
			setWaitinglistsPagination(pagination);
			setWaitinglists(waitingList);
			setLoading(false);
			setButtonLoading(false);
		} catch (error) {
			setWaitinglistsPagination(null);
			setWaitinglists([]);
		} finally {
			setLoading(false);
		}
	};

	// const createWaitinglist = async (waitingList: WaitinglistModel) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { waitingList: serialize(Waitinglist, waitingList) }
	//         const response = await axiosInstance.post(ApiRoutes.WAITINGLISTS, payload)
	//         const data = deserialize(Waitinglist, response.data["waitinglist"]) as Waitinglist;
	//         setWaitinglist(data)
	//     } catch (error) {
	//         setWaitinglist(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const updateWaitinglist = async (waitingList: Waitinglist) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { waitingList: serialize(Waitinglist, waitingList) }
	//         const API_URL = generatePath(ApiRoutes.WAITINGLISTS_SPECIFIC, { waitingListId: String(waitingList.id) })
	//         const response = await axiosInstance.put(API_URL, payload)
	//         const data = deserialize(Waitinglist, response.data["waitinglist"]) as Waitinglist;
	//         setWaitinglist(data)
	//     } catch (error) {
	//         setWaitinglist(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const deleteWaitinglist = async (waitingListId: string) => {
	//     try {
	//         setButtonLoading(true)
	//         const API_URL = generatePath(ApiRoutes.WAITINGLISTS_SPECIFIC, { waitingListId })
	//         await axiosInstance.delete(API_URL)
	//         return true
	//     } catch (error) {
	//         return false
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const showWaitinglist = async (waitingListId: string) => {
	//     try {
	//         setLoading(true)
	//         const API_URL = generatePath(ApiRoutes.WAITINGLISTS_SPECIFIC, { waitingListId })
	//         const response = await axiosInstance.get(API_URL)
	//         const data = deserialize(Waitinglist, response.data["waitinglist"]) as Waitinglist;
	//         setWaitinglist(data)
	//     } catch (error) {
	//         setWaitinglist(null)
	//     } finally {
	//         setLoading(false)
	//     }
	// }

	return {
		loading,
		waitingLists,
		buttonLoading,
		fetchWaitinglists,
		waitingListsPagination,
	};
};

export default WaitinglistService;
