import { useState } from 'react';
import { deserialize } from 'serializr';
// import { generatePath } from 'react-router-dom';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { ElectricityToken as ElectricityTokenModal } from 'models/ElectricityToken/electricityToken.model';
import { Pagination } from './../../models/Pagination/pagination.model';
import Notification from 'shared/components/Notification';
import { NotificationTypes } from 'enums/notificationTypes';
import useSWR from 'swr';
// import { useAlertContext } from 'context/AlertContext';
const fetcher = async (url: string, params: {}) =>
	await axiosInstance.get(url, { params }).then((res) => res.data);
export const useElectricityData = (params?: {
	search?: string;
	page?: number;
	month?: number;
	year?: number;
	sort_by?: string;
	sort_dir?: string;
}) => {
	const { data: response, error } = useSWR(
		[ApiRoutes.ELECTRICITY_TOKEN, params],
		fetcher
	);
	let electricityData;
	let total;
	// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
	if (response) {
		electricityData = deserialize(
			ElectricityTokenModal,
			response?.data.data
		) as ElectricityTokenModal[];
		total = response?.data.total;
	}

	return {
		electricityData,
		total,
		error,
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		isLoading: !error && !response,
	};
};
const ElectricitytokenService = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const [total, setTotal] = useState<number | undefined>();

	// const { setalertText, setalertType, setshowAlert } = useAlertContext();
	const [ElectricityTokens, setElectricitytokens] = useState<
		ElectricityTokenModal[]
	>([]);

	const [ElectricityTokensPagination, setElectricitytokensPagination] =
		useState<Pagination | null>();

	const fetchElectricitytokens = async (params?: {
		search?: string;
		page?: number;
		month?: number;
		year?: number;
		sort_by?: string;
		sort_dir?: string;
	}) => {
		try {
			setLoading(true);

			const response = await axiosInstance.get(ApiRoutes.ELECTRICITY_TOKEN, {
				params,
			});

			const ElectricityToken = deserialize(
				ElectricityTokenModal,
				response?.data.data.data
			) as ElectricityTokenModal[];
			setTotal(response.data.data.total);
			const pagination = deserialize(Pagination, response?.data.meta);
			setElectricitytokensPagination(pagination);
			setElectricitytokens(ElectricityToken);
			setLoading(false);
		} catch (error) {
			setElectricitytokensPagination(null);
			setElectricitytokens([]);
		} finally {
			setLoading(false);
		}
	};

	const createElectricitytoken = async (data: any) => {
		try {
			setButtonLoading(true);
			// const payload = { ElectricityToken: serialize(ElectricityTokenModal, ElectricityToken) }
			
			const response = await axiosInstance.post(
				ApiRoutes.ELECTRICITY_TOKEN,
				data,
			);
			const responseData = await response.data;
			if (responseData.status === 'success') {
				setButtonLoading(false);
				setSuccess(true);
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				Notification({
					message: 'Success',
					description: 'Successfully created electricity token',
					type: NotificationTypes.SUCCESS,
				});
			}
		} catch (error: any) {
			const { response } = error;

			setButtonLoading(false);
			setSuccess(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
			// setElectricitytoken(null)
		} finally {
			setSuccess(false);
			setButtonLoading(false);
		}
	};

	// const updateElectricitytoken = async (ElectricityToken: Electricitytoken) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { ElectricityToken: serialize(Electricitytoken, ElectricityToken) }
	//         const API_URL = generatePath(ApiRoutes.ELECTRICITYTOKENS_SPECIFIC, { ElectricityTokenId: String(ElectricityToken.id) })
	//         const response = await axiosInstance.put(API_URL, payload)
	//         const data = deserialize(Electricitytoken, response.data["electricitytoken"]) as Electricitytoken;
	//         setElectricitytoken(data)
	//     } catch (error) {
	//         setElectricitytoken(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const deleteElectricitytoken = async (ElectricityTokenId: string) => {
	//     try {
	//         setButtonLoading(true)
	//         const API_URL = generatePath(ApiRoutes.ELECTRICITYTOKENS_SPECIFIC, { ElectricityTokenId })
	//         await axiosInstance.delete(API_URL)
	//         return true
	//     } catch (error) {
	//         return false
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const showElectricitytoken = async (ElectricityTokenId: string) => {
	//     try {
	//         setLoading(true)
	//         const API_URL = generatePath(ApiRoutes.ELECTRICITYTOKENS_SPECIFIC, { ElectricityTokenId })
	//         const response = await axiosInstance.get(API_URL)
	//         const data = deserialize(Electricitytoken, response.data["electricitytoken"]) as Electricitytoken;
	//         setElectricitytoken(data)
	//     } catch (error) {
	//         setElectricitytoken(null)
	//     } finally {
	//         setLoading(false)
	//     }
	// }
	return {
		total,
		loading,
		ElectricityTokens,
		buttonLoading,
		success,
		fetchElectricitytokens,
		createElectricitytoken,
		ElectricityTokensPagination,
	};
};

export default ElectricitytokenService;
