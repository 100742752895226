import { TitleProvider } from 'context/TitleComponentContext'
import React from 'react'
import Base from './Base'

export default function index() {
    return (
        <TitleProvider>
            <Base />
        </TitleProvider>
    )
}
