import React from 'react';
import Lottie from 'react-lottie';
import animationData from 'Animations/empty.json';
import { Row } from 'antd';
export default function index() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
		renderer: 'svg',
	};
	return (
		<Row justify='center' align='middle' style={{ width: '100%' }}>
			<Lottie options={defaultOptions} height={300} width={300} />
		</Row>
	);
}
