import React, { useState, memo, useEffect } from 'react';
import { Menu, Row, Col, Typography, Space } from 'antd';
import './navbar.scss';

import AvatarComponent from '../Avatar';
import {
	ChangePassword,
	ClockIconCustom,
	Logout,
	NoticeNotification,
	NotificationsMore,
	QuestionMarkIcon,
} from 'resources/Icons';

import { AuthContext } from 'context/AuthContext';
import { User } from 'models/user.model';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, NavigationRoutes } from 'routes/routeConstants/appRoutes';

import Notification from '../Notification';
import { NotificationTypes } from 'enums/notificationTypes';

import ChangePasswordModal from './ChangePasswordModal';
import NavbarService from 'services/NavbarService/navbar.service';
import moment from 'moment';
import ReferAFriendModal from '../ReferAFriend/ReferAFriendModal';
import { DateFormats } from 'enums/dateFormat';
import ProfileService from 'services/ProfileService/profile.service';

const { Text } = Typography;
const defaultUserData: User = {
	name: 'Bridget Kahana',
	email: 'bridget@ymail.com',
};

const Navbar = () => {
	const [showMenu, setShowMenu] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showReferFriendModal, setShowReferFriendModal] = useState(false);
	const navigate = useNavigate();
	const { markAllAsRead, markOneAsRead, fetchAppNotifications ,appNotifications} = NavbarService();

	const { user } = AuthContext();
	const { name = defaultUserData.name } = user ?? defaultUserData;
	const {generalInfo, getGeneralProfileInfo } = ProfileService();
	const [basicGeneralInfo, setBasicGeneralInfo] = useState<{profilePicture?: string}>()
	const changeModalState = () => setShowModal(!showModal);
	const changeReferFriendModalState = () =>
		setShowReferFriendModal(!showReferFriendModal);

	
	const changeNotificationsState = () => {
		setShowNotifications(prevShowNotifications => !prevShowNotifications);
		setShowMenu(false);
	};

	const handleLogout = (): void => {
		localStorage.clear();
		navigate(NavigationRoutes.LOGIN, { replace: true });

		void Notification({
			message: 'Logout',
			description: 'User Logged Out Successfully',
			type: NotificationTypes.SUCCESS,
		});
	};

	useEffect(() => {
		void fetchAppNotifications();
		void getGeneralProfileInfo();
	}, [])

	useEffect(() => {
		if (generalInfo){
			setBasicGeneralInfo({
				profilePicture: generalInfo?.profile_picture,
			})
		}
	},[generalInfo])
	enum typesOfNavigation {
		Dashboard = 'Dashboard',
		Documents = 'Documents',
		ReferAFriend = 'Refer friend',
	}

	const markAllNotificationsAsRead = async () => {
		const res = await markAllAsRead();
		if (res === 200) {
			setShowNotifications(false);
		}
	};
	const changeMenuState = () => {
		setShowMenu(!showMenu);
		setShowNotifications(false);
	};
	const markOneNotificationsAsRead = async (
		id: string,
		type: string,
		readAt: string | null
	) => {
		if (type === typesOfNavigation.Dashboard) {
			navigate('/app' + AppRoutes.HOME);
		} else if (type === typesOfNavigation.Documents) {
			navigate('/app' + AppRoutes.DOCUMENTS);
		} else if (type === typesOfNavigation.ReferAFriend) {
			changeReferFriendModalState();
		}
		if (readAt !== null) return;
		await markOneAsRead({ id });
	};
	return (
		<>
			<ChangePasswordModal
				showModal={showModal}
				changeModalState={changeModalState}
			/>
			<ReferAFriendModal
				isModalVisible={showReferFriendModal}
				changeModalState={changeReferFriendModalState}
			/>
			<div className="menu-top">
				<Row justify="end" align="middle" gutter={[20, 20]}>
					<Col>
						<div className="notice__icon" onClick={changeNotificationsState}>
							{!appNotifications?.length || !appNotifications?.every((i) => i?.readAt === null) ? (
								<NoticeNotification />
							) : (
								<NotificationsMore />
							)}
						</div>
					</Col>
					<Col>
						<AvatarComponent
							firstName={name ? name.split(' ')[0] : ''}
							lastName={name ? name.split(' ')[1] : ''}
							onAvatarClick={changeMenuState}
							imageUrl={basicGeneralInfo?.profilePicture}
						/>
					</Col>
				</Row>
				{showNotifications && (
					<Row
						className="navbar-menu"
						align="middle"
						justify="center"
						style={{ zIndex: '999', width: '30rem' }}
					>
						<Col span={24}>
							<Row className="navbar-menu__header">
								<Col span={18}>
									<Row gutter={[8, 32]} align="middle">
										<Col className="navbar-menu__notice-icon">
											<NoticeNotification color={'#65BCB0'} />
										</Col>
										<Col>
											<Text className="navbar-menu__notice-text">
												Notification
											</Text>
										</Col>
									</Row>
								</Col>
								<Col span={6} className="navbar-menu__mark-text">
									<Text
										className="greenText"
										onClick={() => {
											void markAllNotificationsAsRead();
										}}
										style={{ cursor: 'pointer' }}
									>
										Mark all as read
									</Text>
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							<Menu
								mode="vertical"
								style={{
									height: '19rem',
									overflow: 'scroll',
									zIndex: '999999',
								}}
								className="app-navbar"
							>
								{appNotifications?.map((i, j) => {
									return (
										<Menu.Item
											key={j}
											className="menu-item-notifications"
											onClick={() => {
												void markOneNotificationsAsRead(
													i.id ?? '',
													i.data?.type ?? '',
													i?.readAt ?? null
												);
											}}
										>
											<Row align="top">
												<Col span={3} className="circleIcon">
													<NoticeNotification color={'#65BCB0'} />
												</Col>
												<Col span={20}>
													<Row className="notification-item-row">
														<Col
															span={24}
															className="notification__item-text-col"
														>
															<Text
																className={
																	i.readAt ? 'text--black' : 'text--green'
																}
															>
																{i.data?.message}
															</Text>
														</Col>
														<Col span={24}>
															<Space direction="horizontal">
																<Col className="notification__item-row-clock-icon">
																	<ClockIconCustom />
																</Col>
																<Text
																	type="secondary"
																	className="notification__item-row-date"
																>
																	{moment(i.createdAt).format(DateFormats.SECONDARY)}
																</Text>
															</Space>
														</Col>
													</Row>
												</Col>
											</Row>
										</Menu.Item>
									);
								})}
							</Menu>
						</Col>
					</Row>
				)}
				{showMenu && (
					<Row
						className="navbar-menu navbar-user-menu"
						align="middle"
						justify="start"
						style={{ zIndex: '999' }}
					>
						<Menu mode="vertical">
							<Menu.Item className="menu-item" onClick={changeModalState}>
								<Space direction="horizontal" className="navbar__menutop">
									<ChangePassword color={'#000000'} />
									<Text>Change Password</Text>
								</Space>
							</Menu.Item>
							<Menu.Item className="menu-item">
								<Space direction="horizontal" className="navbar__menutop">
									<QuestionMarkIcon color={'##000000'} />
									<Text>FAQs</Text>
								</Space>
							</Menu.Item>
							<Menu.Item className="menu-item" onClick={handleLogout}>
								<Space direction="horizontal" className="navbar__menutop">
									<Logout color={'#000000'} />
									<Text>Logout</Text>
								</Space>
							</Menu.Item>
						</Menu>
					</Row>
				)}
			</div>
		</>
	);
};

export default memo(Navbar);
