import * as Yup from 'yup';
import { REGEX } from 'shared/utils/regex';

export const changePasswordValidation = Yup.object().shape({
    'Current Password': Yup.string()
        .min(8, 'Password has to be longer than 8 characters! ')
        .required('current Password is required!'),
    'changePassword': Yup.string()
        .min(8, 'Password has to be longer than 8 characters! ')
        .required('current Password is required!')
        .matches(REGEX.PASSWORD, "One Uppercase, One Lowercase, One Number and one special case Character"),
    'confirmPassword': Yup.string()
        .oneOf([Yup.ref('changePassword'), undefined], 'Passwords must match')

});