import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Invoices } from '../../models/Invoices/invoices.model';
import { Pagination } from './../../models/Pagination/pagination.model';

const InvoicesService = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const [invoices, setInvoices] = useState<Invoices[]>([]);
	const [DownloadInvoiceDisabled, setDownloadInvoiceDisabled] = useState(false);
	const [invoicesPagination, setInvoicesPagination] =
		useState<Pagination | null>();

	const fetchInvoices = async (params?: {
		search?: string;
		page?: number;
		sort_by?: string;
		sort_dir?: string;
		year?: number;
		month?: number;
	}) => {
		try {
			setLoading(true);
			// setButtonLoading(true);
			const response = await axiosInstance.get(ApiRoutes.INVOICES, { params });
			const invoice = deserialize(Invoices, response.data.data.data);
			setInvoices(invoice as Invoices[]);
			setLoading(false);
			// setButtonLoading(false);
		} catch (error) {
			setInvoicesPagination(null);
			setInvoices([]);
			setLoading(false);
			setButtonLoading(false);
		} finally {
			setLoading(false);
			setButtonLoading(false);
		}
	};

	const downloadStatement = async () => {
		try {
			setButtonLoading(true);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			axiosInstance
				.get(ApiRoutes.DOWNLOAD_STATEMENT, { responseType: 'blob' })
				.then((response) => {
					setButtonLoading(false);
					window.open(URL.createObjectURL(response.data));
				});
		} catch (error) {
			console.log('error.message');
		} finally {
			console.log('error');
		}
	};
	const downloadStatementPerInvoice = async (params: { id: number }) => {
		try {
			setDownloadInvoiceDisabled(true);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			axiosInstance
				.get(
					`${ApiRoutes.DOWNLOAD_STATEMENT_PER_INVOICE}?invoice_id=${params.id}`,
					{
						responseType: 'blob',
					}
				)
				.then((response) => {
					setDownloadInvoiceDisabled(false);
					window.open(URL.createObjectURL(response.data));
				});
		} catch (error) {
			setDownloadInvoiceDisabled(false);
			console.log('error.message');
		} finally {
			console.log('error');
		}
	};

	return {
		loading,
		invoices,
		buttonLoading,
		fetchInvoices,
		invoicesPagination,
		downloadStatement,
		downloadStatementPerInvoice,
		DownloadInvoiceDisabled,
	};
};

export default InvoicesService;
