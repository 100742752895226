import { Col, Image, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import './resetPassword.scss';
import useResponsive from 'shared/Hooks/useResponsive';
import QcareLogo from 'resources/images/newLogo.svg';
import { Form, Formik, FormikValues } from 'formik';

import InputField from 'shared/components/InputField';
import ButtonBase from 'shared/components/Button';
import { ButtonTypes } from 'enums/buttonTypes';
import { useNavigate } from 'react-router-dom';
import UserService from 'services/AuthService/auth.service';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import { resetPasswordValidationSchema } from '../AuthWrapper/validations';

const { Text } = Typography;

const ResetPasssword = () => {
	const navigate = useNavigate();
	const { isTablet, isMobile } = useResponsive();
	const { loading, resetPassword, forgotPasswordGenerateOtp} = UserService();
	const [email, setEmail] = useState(''); 
	const [countdown, setCountdown] = useState(0);
	const initialValues = {
		otp: '',
		newPassword: '',
		confirmPassword: '',
	};

	useEffect(() => {
		const storedEmail = localStorage.getItem('verify-email');
		if (storedEmail) {
			setEmail(storedEmail);
		}
	}, []);

	const resendOtpHandler = async () => {
		let timer = 15;
		await forgotPasswordGenerateOtp({ qcareEmail: email });
		const countdownInterval = setInterval(() => {
			if (timer >= 0) {
				setCountdown(timer--);
			} else {
				clearInterval(countdownInterval);
			}
		}, 1000);
	};

	const submitHandler = async (values?: FormikValues) => {
		const response = await resetPassword({
			otp: values?.otp,
			newPassword: values?.newPassword,
			confirmPassword: values?.confirmPassword,
			qcareEmail: email,
		});
		if (response) {
			localStorage.removeItem('verify-email');
			navigate(AppRoutes.LOGIN);
		}
	};
	
	return (
		<div>
			<Row
				className="reset-password"
				justify="center"
				align="middle"
				gutter={[16, 32]}
			>
				<Col
					span={isTablet ? 20 : isMobile ? 24 : 9}
					offset={isTablet ? 8 : isMobile ? 2 : 13}
				>
					<Row>
						<Col
							span={15}
							xs={20}
							md={18}
							xl={20}
							className="reset-password__container"
						>
							<Row align="middle" justify="center" gutter={[16, 22]}>
								<Col span={24} className="reset-password__container-logo">
									<Image
										src={QcareLogo}
										className="reset-password__container-image"
										preview={false}
									/>
								</Col>
								<Col className="reset-password-header">
									<Text
										className={
											isMobile
												? 'reset-password__heading-mobile'
												: 'reset-password__heading'
										}
									>
										Change your password
									</Text>
								</Col>
							</Row>
							<Formik
								initialValues={initialValues}
								onSubmit={submitHandler}
								validationSchema={resetPasswordValidationSchema}
							>
								<Form className="reset-password-form">
									<label className={'floating-label-otp '}>Enter The OTP</label>
									<InputField
										type="string"
										name="otp"
										placeholder="Enter OTP"
										showLabel={false}
									/>
									<label className={'floating-label-password '}>
										New Password
									</label>
									<InputField
										type="password"
										name="newPassword"
										showLabel={false}
									/>
									<label className={'floating-label-password '}>
										Confirm Password
									</label>
									<InputField
										type="password"
										name="confirmPassword"
										showLabel={false}
									/>
									<div
										className={
											isMobile
												? 'verify-email-form__resend-otp-mobile'
												: 'verify-email-form__resend-otp'
										}
									>
										<Text className={isMobile ? 'resend-otp__text' : ''}>
											Didn’t receive the OTP?
										</Text>
										<ButtonBase
											type={ButtonTypes.TERTIARY}
											ClassName={
												isMobile
													? 'verify-email-form__resend-btn-mobile'
													: 'verify-email-form__resend-btn'
											}
											text="Re-send"
											onclick={() => {
												void resendOtpHandler();
											}}
											disabled={countdown > 0}
										/>
										{countdown > 0 && <Text>({countdown})</Text>}
									</div>
									<ButtonBase
										type={ButtonTypes.PRIMARY}
										block={true}
										loading={loading}
										text="Change Password"
										htmlType="submit"
										ClassName="reset-password-form__btn"
									/>
								</Form>
							</Formik>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default ResetPasssword;
