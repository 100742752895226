import { Col, Image, Row, Typography } from 'antd';
import React from 'react';
import './forgotPassword.scss';
import useResponsive from 'shared/Hooks/useResponsive';
import QcareLogo from 'resources/images/newLogo.svg';
import { Form, Formik, FormikValues } from 'formik';
import { forgotPasswordValidationSchema } from '../AuthWrapper/validations';
import InputField from 'shared/components/InputField';
import ButtonBase from 'shared/components/Button';
import { ButtonTypes } from 'enums/buttonTypes';
import { useNavigate } from 'react-router-dom';
import UserService from 'services/AuthService/auth.service';
import { AppRoutes } from 'routes/routeConstants/appRoutes';

const { Text } = Typography;

const ForgotPassword = () => {
	const navigate = useNavigate();
	const { isTablet, isMobile } = useResponsive();
	const { showLoadingForOtp, forgotPasswordGenerateOtp } = UserService();
	const initialValue = {
		qcareEmail: '',
	};
	const submitHandler = async (values?: FormikValues) => {
		if (!values?.qcareEmail) {
			return;
		}
		const response = await forgotPasswordGenerateOtp({ qcareEmail: values.qcareEmail });
		if (response) {
			localStorage.setItem('verify-email', values.qcareEmail);
			navigate(AppRoutes.RESET_PASSWORD);
		}
	};
	const handleBackClick = () => {
		navigate(-1);
	};
	return (
		<div>
			<Row
				className="forgot-password"
				justify="center"
				align="middle"
				gutter={[16, 32]}
			>
				<Col
					span={isTablet ? 20 : isMobile ? 24 : 9}
					offset={isTablet ? 8 : isMobile ? 2 : 13}
				>
					<Row>
						<Col
							span={15}
							xs={20}
							md={18}
							xl={20}
							className="forgot-password__container"
						>
							<Row align="middle" justify="center" gutter={[16, 22]}>
								<Col span={24} className="forgot-password__container-logo">
									<Image
										src={QcareLogo}
										preview={false}
										className="forgot-password__container-image"
									/>
								</Col>
								<Col className="forgot-password-header">
									<Text
										className={
											isMobile
												? 'forgot-password__heading-mobile'
												: 'forgot-password__heading'
										}
									>
										{'Forgot password'}
									</Text>
									<Text
										className={
											isMobile
												? 'forgot-password__text-mobile'
												: 'forgot-password__text'
										}
									>
										Enter a valid email id to find your account
									</Text>
								</Col>
							</Row>
							<Formik
								initialValues={initialValue}
								onSubmit={submitHandler}
								validationSchema={forgotPasswordValidationSchema}
							>
								<Form className="forgot-password-form">
								<label className={'floating-label-password '}>Email</label>
									<InputField
										type="email"
										name="qcareEmail"
										placeholder="Enter email"
										showLabel={false}
									/>
									<div>
										<ButtonBase
											type={ButtonTypes.PRIMARY}
											block={true}
											loading={showLoadingForOtp}
											text="Generate OTP"
											htmlType="submit"
											ClassName="forgot-password-form__otp-btn"
										/>
										<ButtonBase
											type={ButtonTypes.TERTIARY}
											text="Back"
											ClassName="forgot-password-form__back-btn"
											block={true}
											onclick={handleBackClick}
										/>
									</div>
								</Form>
							</Formik>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default ForgotPassword;
