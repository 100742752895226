import React, { useEffect } from 'react';
import { Row, Col, Divider } from 'antd';
import { AgreementCard, TagText } from 'shared/components/Cards';

import AgreementService from 'services/AgreementService/agreement.service';
import BookingsSkeleton from 'views/Bookings/BookingsSkeleton';
import moment from 'moment';
import { usdollar } from 'views/Home';
import { DateFormats } from 'enums/dateFormat';

import DocumentsService from 'services/DocumentsService/documents.service';

 const Agreement = () => {
	const {
		loading,
		fetchAgreements,
		agreements,
		buttonLoading,
		generateDocusignLink,
		generationlinkloading,
		downloadAgreement
	} = AgreementService();
	const {agreementStatusId,fetchDocuments} = DocumentsService()

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		fetchAgreements();
		void fetchDocuments();
		
	}, []);
	

	const downloadDocumentAgreement = async ()=> {
		// setbuttonLoading(true);
		await downloadAgreement(agreementStatusId?.filePath);
		// setbuttonLoading(false);
	};


	

	return loading ? (
		<BookingsSkeleton />
	) : (
		<Row gutter={[16, 15]}>
			{agreements !== undefined ? (
				<Col xs={24} md={24} lg={24} xxl={24} key={agreements.id}>
					<AgreementCard
						title={agreements.building?.name}
						tagText={agreements.status?.name as TagText}
						bookingId={agreements.bookingId}
						roomNo={agreements.roomNumber}
						roomType={agreements.roomType}
						startDate={moment(agreements.checkin).format(DateFormats.SECONDARY)}
						endDate={moment(agreements.checkout).format(DateFormats.SECONDARY)}
						monthlyRent={usdollar.format(Number(agreements?.rent ?? 0))}
						showAgreement={true}
						buttonLoading={buttonLoading}

						agreementOnClick={downloadDocumentAgreement}
						imageUrl={agreements.building?.imageUrl}
						showDocusign={true}
						docusignLoading={generationlinkloading}
						agreementSignOnClick={generateDocusignLink}
						docusignDisabled={agreementStatusId?.filePath!==null}
					/>
				</Col>
			) : (
				<></>
			)}

			<Divider />
		</Row>
	);
};

export default React.memo(Agreement);
