import {
	Col,
	Row,
	TableProps,
	Typography,
	Form,
	DatePicker,
	Select,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState, useEffect, useMemo } from 'react';
import ButtonBase from 'shared/components/Button';
import {
	InputFieldForEmail,
	InputFieldWithIcon,
} from 'shared/components/InputField';
import TableUpdated from 'shared/components/Table';
import ModalComponent, { ModalSize } from 'shared/components/Modal';
// import { InputWithPrefix } from 'shared/components/InputField/InputWithPrefix';
import { ButtonTypes } from 'enums/buttonTypes';
// import ProfileService, { useCountriesList } from 'services/ProfileService/profile.service';
import moment from 'moment';

import VisitorlogsService from 'services/VisitorlogsService/VisitorLogs.service';
import { userDetails } from 'shared/Hooks/user';
import { userCheckInStatus } from 'enums/userStatusTypes';

import { countriesListType, salutationListType } from 'context/ProfileContext';
import { SolidArrow, SearchIcon } from 'resources/Icons';
import { useCountriesAndSalutations } from 'shared/Hooks/useCountriesAndSalutations';
import { debounce } from 'shared/Hooks/debounceFunction';
import { VisitorInvites } from 'models/VisitorInvites/VisitorInvites.model';
import { TableSkeleton } from 'shared/components/Table/TableSkeleton';
import useResponsive from 'shared/Hooks/useResponsive';
import { DateFormats } from 'enums/dateFormat';
import SelectInput from 'shared/components/Select/SelectInput';

const { Title } = Typography;
const { Option } = Select;
type DataType = {
	key: React.Key;
	name: string;
	email: string;
	phone: number;
	expectedAt: number;
};

const columns: ColumnsType<DataType> = [
	{
		title: 'Name',
		dataIndex: 'name',
		sorter: (a, b) => a.name.localeCompare(b.name),
	},
	{
		title: 'Guest Email',
		dataIndex: 'email',
		sorter: (a, b) => a.email.localeCompare(b.email),
		// sorter: {
		// 	compare: (a, b) => a.chinese - b.chinese,
		// 	//   multiple: 3,
		// },
	},
	{
		title: 'Guest Mobile Number',
		dataIndex: 'phone',
		// sorter: {
		// 	compare: (a, b) => a.math - b.math,
		// 	//   multiple: 2,
		// },
	},
	{
		title: 'Schedule',
		dataIndex: 'expectedAt',
		sorter: (a, b) => moment(a.expectedAt).unix() - moment(b.expectedAt).unix(),

		render: (_, { expectedAt }) => {
			return <p>{moment(expectedAt).format('DD-MMM-yy hh:mm a')}</p>;
		},
		// sorter: {
		// 	compare: (a, b) => a.english - b.english,
		// 	//   multiple: 1,
		// },
	},
];

// const data: DataType[] = [
// 	{
// 		key: '1',
// 		name: 'John Brown',
// 		chinese: 98,
// 		math: 60,
// 		english: 70,
// 	},
// 	{
// 		key: '2',
// 		name: 'Jim Green',
// 		chinese: 98,
// 		math: 66,
// 		english: 89,
// 	},
// 	{
// 		key: '3',
// 		name: 'Joe Black',
// 		chinese: 98,
// 		math: 90,
// 		english: 70,
// 	},
// 	{
// 		key: '4',
// 		name: 'Jim Red',
// 		chinese: 88,
// 		math: 99,
// 		english: 89,
// 	},
// ];

const onChange: TableProps<DataType>['onChange'] = (
	pagination,
	filters,
	sorter,
	extra
) => {
	console.log('params', pagination, filters, sorter, extra);
};

const changeValue = (e: string): any => {
	return console.log(e);
};

// const TitleComponent = (setShowModal: any) => (
//   <Row align="middle" gutter={12}>
//     <Col span={12}>
//       <InputFieldWithIcon
//         type="text"
//         name="search"
//         prefix={<SearchOutlined />}
//         placeholder="search by invoice details"
//         allowClear={true}
//       />
//     </Col>
//     <Col span={8}>
//       <ButtonBase
//         type={ButtonTypes.PRIMARY}
//         text="Invite Guest"
//         onclick={setShowModal}
//       />
//     </Col>
//   </Row>
// );

export const VisitorInviteComponent = (): JSX.Element => {
	const [showModal, setShowModal] = useState(false);
	const showHideModal = (): void => setShowModal(!showModal);
	const [search, setSearch] = useState();
	const { isMobile } = useResponsive();
	const [visitorInviteLogs, setvisitorInviteLogs] =
		useState<VisitorInvites[]>();
	const {
		countriesListProvider,
		salutationListProvider,
	}: {
		countriesListProvider: countriesListType;
		salutationListProvider: salutationListType;
	} = useCountriesAndSalutations();

	const {
		inviteGuest,
		buttonLoading,
		loading,
		fetchVisitorInvites,
		VisitorInvitesData,
	} = VisitorlogsService();
	const { user } = userDetails();
	const [hostDetails, setHostDetails] = useState({
		dialCode: '254',
		email: 'jack@ymail.com',
		expected_at: '2022-11-29 11:55',
		name: '',
		phone: '+2548989898989',
		hostName: '',
	});

	useEffect(() => {
		setvisitorInviteLogs(VisitorInvitesData);
	}, [VisitorInvitesData]);

	useEffect(() => {
		if (search !== undefined) {
			const searchTerm: any = search;
			let newlogs;
			if (/^[A-Za-z]/i.test(searchTerm)) {
				// try the search first with the name
				newlogs = VisitorInvitesData.filter((i) =>
					i.value?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
				);
				if (newlogs.length <= 0) {
					// if the name yields nothing , try with the status code
					newlogs = VisitorInvitesData.filter((i) =>
						i.value?.email?.toLowerCase().includes(searchTerm?.toLowerCase())
					);
				}
			} else {
				newlogs = VisitorInvitesData.filter((i) =>
					i.value?.phone?.includes(searchTerm)
				);
			}

			setvisitorInviteLogs(newlogs);
		} else {
			setvisitorInviteLogs(VisitorInvitesData);
		}
	}, [search]);

	const processChange = debounce((e) => onSearchChange(e), 300);

	const onSearchChange = (e: any) => {
		setSearch(e);
	};
	useEffect(() => {
		const user: string = JSON.parse(
			localStorage.getItem('user') as string
		).name;
		if (user) {
			setHostDetails({ ...hostDetails, hostName: user });
		}
		void fetchVisitorInvites();
	}, []);

	const { Text } = Typography;

	const inviteGuestFunction = async () => {
		const params = {
			dialCode: hostDetails.dialCode,
			email: hostDetails.email,
			phone: `+${hostDetails.dialCode}${hostDetails.phone}`,
			name: hostDetails.name,
			expected_at: hostDetails.expected_at,
		};
		const responseStatusCode = await inviteGuest(params);
		if (responseStatusCode === 200) {
			showHideModal();
		}
	};

	const MobileResponsiveCards = ({
		data,
		loading,
	}: {
		data: any | undefined;
		loading: boolean;
	}) => {
		return loading ? (
			<TableSkeleton />
		) : (
			<div style={{ paddingBottom: '1rem' }}>
				{data?.map((item: any, index: any) => (
					<Row key={index} align="middle" className="mobile-responsive-table-section">
						<Col span={14} style={{minHeight:"50px"}}>
							<Row gutter={[16, 5]} justify="start">
								<Col span={24}>
									<Text>{item?.name}</Text>
								</Col>
								<Col span={24}>
									<Text type="secondary">{item?.email}</Text>
								</Col>
							</Row>
						</Col>
						<Col span={10}  style={{minHeight:"50px"}}>
							<Row justify="end" align="middle" gutter={[16, 5]}>
								<Col>
									<Text>{moment(item.schedule).format(DateFormats.SECONDARY)}</Text>
								</Col>
								<Col>
									<Text type="secondary" style={{fontSize:"11px"}}>{item?.mobile}</Text>
								</Col>
							</Row>
						</Col>
					</Row>
				))}
			</div>
		);
	};

	const TitleComponent = (): JSX.Element => (
		<Row align="middle" gutter={12} style={
			isMobile
				? { position: 'absolute', top: '-6rem', width: '100vw' }
				: undefined
		}>
			<Col span={15}>
				<InputFieldWithIcon
					type="text"
					name="search"
					prefix={<SearchIcon />}
					placeholder="Search by guest details"
					allowClear={true}
					onChange={(e: any) => {						
						processChange(e.target.value)}
					}
				/>
			</Col>
			<Col span={7}>
				<ButtonBase
					type={ButtonTypes.PRIMARY}
					text="Invite Guest"
					disabled={user?.status?.name === userCheckInStatus.CheckedOut}
					onclick={() => showHideModal()}
				/>
			</Col>
		</Row>
	);

	const NewTitleComponent = useMemo(() => <TitleComponent />, [user]);
	return (
		<>
			<ModalComponent
				className={isMobile ? "visitor-details-modal-mobile": "visitor-details-modal"}
				shouldModalOpen={showHideModal}
				visible={showModal}
				showFooter={true}
				okButtonText={'Invite Guest'}
				cancelButtonText={'Cancel'}
				onButtonOkClick={() => {
					void inviteGuestFunction();
				}}
				title={<Title level={3}>Visitor Details</Title>}
				buttonLoading={buttonLoading}
				size={ModalSize.MEDIUM}
			>
				<Form>
					<Row gutter={[16, 0]}>
						<Col span={12} xs={24} md={12} xl={12} >
							<Form.Item>
								<Row gutter={[8, 0]}>
									<Col span={24}>
										<Text type="secondary" className="secondary__text">
											Guest Name
										</Text>
									</Col>
									<Col span={24}>
										<InputFieldForEmail
											type="text"
											placeholder="Guest Name"
											onChange={(e: any) => {
												setHostDetails({
													...hostDetails,
													name: e.target.value,
												});
											}}
											prefix={
												<SelectInput
													style={{ width: 70 }}
													listHeight={150}
													dropdownStyle={{ minWidth: 300, maxWidth: 500 }}
													suffixIcon={<SolidArrow />}
													className="profile-select-phone-number"												
													optionLabelProp="label"
													defaultValue={'Mr'}
												>
													{salutationListProvider?.map((item) => (
														<Option value={item.salutation} key={item.id}>
															{item.salutation}
														</Option>
													))}
													{/* <Option value="Mr">Mr</Option>
												<Option value="Mr">Mrs</Option>
												<Option value="Mr">Miss</Option> */}
												</SelectInput>
											}
											// prefixData={[...salutationListProvider.map(i => i.salutation)] ?? ['Mr', 'Mrs', 'Miss']}
		
										/>
									</Col>
								</Row>
							</Form.Item>

							<Form.Item>
								<Row gutter={[8, 0]}>
									<Col span={24}>
										<Text type="secondary" className="secondary__text">
											Phone Number
										</Text>
									</Col>
									<Col span={24}>
										<InputFieldForEmail
											placeholder="Enter Guest's Phone Number"
											id="phoneNumber"
											onChange={(e: any) =>
												setHostDetails({
													...hostDetails,
													phone: e.target.value,
												})
											}
											// value={hostDetails?.phone}
											prefix={
												<SelectInput
													style={{ width: 70 }}
													listHeight={150}
													showSearch
													dropdownStyle={{ minWidth: 300, maxWidth: 500 }}
													suffixIcon={<SolidArrow />}
													className="profile-select-phone-number"
													optionLabelProp="label"
													filterOption={(input, option) => {
														if (option?.children) {
															return !!option.value
																?.toString()
																?.toLowerCase()
																.startsWith(input?.toLowerCase());
														} else return false;
													}}
													defaultValue={
														countriesListProvider.filter((i) =>
															i.name.startsWith('Kenya')
														)[0]?.calling_code
													}
													onChange={(e: any) => {
													
														const id = countriesListProvider.filter(
															(i) => i.name === e
														)[0].calling_code;
														setHostDetails({
															...hostDetails,
															dialCode: id.toString(),
														});
													}}
												>
													<>
														{countriesListProvider?.map((i, j) => {
															return (
																<Option
																	value={i.name}
																	key={j}
																	label={i.calling_code}
																>
																	{`${i.name}(+${i.calling_code})`}
																</Option>
															);
														})}
													</>
												</SelectInput>
											}
										/>
									</Col>
								</Row>
							</Form.Item>

							<Form.Item>
								<Row gutter={[8, 0]}>
									<Col span={24}>
										<Text type="secondary" className="secondary__text">
											Visiting Date And Time
										</Text>
									</Col>
									<Col span={24}>
										<DatePicker
											format="YYYY-MM-DD HH:mm a"
											showTime={true}
											disabledDate={(current) =>
												current.isBefore(moment().subtract(1, 'day'))
											}
											onChange={(date: any, string: any) => {
												setHostDetails({
													...hostDetails,
													expected_at: moment(date).format(
														'YYYY-MM-DD HH:mm'
													),
												});
											}}
											style={{ width: '100%' }}
											placeholder='Select Date'
										/>
									</Col>
								</Row>
								<label></label>
							</Form.Item>
						</Col>
						<Col span={12} xs={24} md={12} xl={12}>
							<Form.Item>
								<Row gutter={[8, 0]}>
									<Col span={24}>
										<Text type="secondary" className="secondary__text">
											Host Name
										</Text>
									</Col>
									<Col span={24}>
										<InputFieldForEmail
											placeholder="host name"
											changeValue={changeValue}
											type="text"
											disabled={true}
											value={hostDetails.hostName}
										/>
									</Col>
								</Row>
							</Form.Item>

							<Form.Item>
								<Row gutter={[8, 0]}>
									<Col span={24}>
										<Text type="secondary" className="secondary__text">
											Email Address
										</Text>
									</Col>
									<Col span={24}>
										<InputFieldForEmail
											type="email"
											placeholder="Enter Guest's Email Address"
											onChange={(e: any) =>
												setHostDetails({
													...hostDetails,
													email: e.target.value,
												})
											}
										/>
									</Col>
								</Row>
							</Form.Item>
						</Col>
					</Row>
				</Form> 

				{/* </Form> */}
				{/* </Formik> */}
			</ModalComponent>
			{
				 isMobile ? (
					<>
						{NewTitleComponent}
						<MobileResponsiveCards loading={loading} data={visitorInviteLogs?.map((i) => i.value)} />
					</>
				):
						<TableUpdated
							columns={columns}
							data={visitorInviteLogs?.map((i) => i.value)}
							title={NewTitleComponent}
							onchange={onChange}
							isLoading={loading}
						/>
			}
		</>
	);
};
