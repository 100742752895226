import React, { FC } from 'react';
import { Row, Col, Image, Typography } from 'antd';
import { MonthlyRent } from 'resources/Icons';
import './bookingcard.scss';
import ButtonBase from '../Button';
import { TagTypes } from 'enums/tagTypes';
import { ButtonTypes } from 'enums/buttonTypes';
import Tags from '../Tag';
import useResponsive from 'shared/Hooks/useResponsive';

const { Text } = Typography;
export enum TagText {
	Active = 'Active',
	Inactive = 'Inactive',
	Occupied = 'Occupied',
	Paid = 'Paid',
	paid = 'paid',
	NotOccupied = 'Not Occupied',
	RequestInitiated = 'Request Initiated',
	PaymentCompleted = 'Payment Completed',
	Booked = 'Booked',
	CheckedIn = 'CheckedIn',
	CheckedOut = 'CheckedOut',
	Upload = 'Upload',
	Rejected = 'Rejected',
	Approved = 'Approved',
	RoomAllocatted = 'Room Allocatted',
	DocumentsNotUploaded = 'Documents Not Uploaded',
	DocumentsApproved = 'Documents Approved',
	DocumentsUploaded = 'Documents Uploaded',
	DocumentsRejected = 'Documents Rejected',
	BookingCancelled = 'Booking Cancelled',
	Closed = 'Closed',
	Reopened = 'Reopened',
	Escalated = 'Escalated',
	TokenGenerated = 'Token Generated',
	token_generated = 'token_generated',
	Pending = 'Pending',
	Completed = 'Completed',
	Cancelled = 'Cancelled',
	ReOpened = 'Re-opened',
	RefundCompleted = "Refund Completed",
	RefundRejected = "Refund Rejected",
	InProgress = "In Progress",
}

type bookingsCardType = {
	tagType?: TagTypes;
	tagText?: TagText;
	title: string | undefined;
	bookingId: string | number | undefined;
	roomNo: string | undefined;
	monthlyRent?: string;
	startDate?: string;
	endDate?: string;
	showAgreement?: boolean;
	agreementOnClick?: () => any;
	roomType?: string;
};

const Index: FC<bookingsCardType> = ({
	tagType,
	tagText,
	title,
	bookingId,
	roomType,
	roomNo,
	monthlyRent,
	startDate,
	endDate,
	showAgreement = false,
	agreementOnClick,
}) => {
	const { isMobile } = useResponsive();
	return (
		<Row className={isMobile ? "bookings-card-mobile w-100" : "bookingsCard w-100"} gutter={[16, 0]}>
			<Row className="w-100" gutter={16} justify="start" align="top">
				<Col md={4} xs={24} className={isMobile ? "booking-card-mobile__image" : ""}>
					<Image
						src="https://images.unsplash.com/photo-1486325212027-8081e485255e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb"
						className="bookingCardImage"
						preview={false}
					/>
				</Col>
				<Col md={20} xs={24} className={isMobile ? "booking-card-mobile__details" : ""}>
					{
						isMobile ?
							<>
								<Row className='booking-card-mobile__info'>
									<Row >
										<Text className="black__text__cards title__card__text">
											{title}
										</Text>
									</Row>
									<Row>
										<Text className={isMobile ? "card-booking-id-mobile" : "card-booking-id"}>({bookingId})</Text>
									</Row>
									<Row>
										<Text className={isMobile ? "booking-status-mobile" : "booking-status"}>
											<Tags tagText={tagText as TagText} />
										</Text>
									</Row>
								</Row>
							</> :
							<>
								<Row justify="space-between">
									<Row justify="start">
										<Text className="black__text__cards title__card__text">
											{title}
										</Text>
										<Text className={isMobile ? "card-booking-id-mobile" : "card-booking-id"}>({bookingId})</Text>
									</Row>
									<Row justify="end">
										<Text className={isMobile ? "booking-status-mobile" : "booking-status"}>
											<Tags tagText={tagText as TagText} />
										</Text>
									</Row>
								</Row>
							</>
					}
					{
						isMobile ?
							<>
								<Row justify="start" align="middle" className="card-right-rtwo-mobile">
									<Col xs={24}>
										<Text className="black__text__cards">{roomNo} |</Text>
										<Text className=""> {roomType} |</Text>
										<Text className="black__text__cards"> {startDate} - {endDate} </Text>
									</Col>
								</Row>
							</> :
							<>
								<Row justify="start" align="middle" className="card-right-rtwo">
									<Text className="black__text__cards">{roomNo} |</Text>
									<Text className="">{roomType} |</Text>
									<Text className="black__text__cards"> {startDate} - {endDate}</Text>
								</Row>
							</>
					}
					<Row justify="start" align="bottom" className="card-right-rthree">
						<div className="monthly-rent-logo">
							<MonthlyRent />
						</div>
						<Col>
							<Text className="monthly-rent-string"> Monthly Rent</Text>
						</Col>
						<Col>
							<Text className="monthly-rent-unit">
								{'KSh'} {monthlyRent}
							</Text>
						</Col>
					</Row>
				</Col>
			</Row>
		</Row>
	);
};

export const AgreementCard: FC<
	Partial<bookingsCardType> & {
		imageUrl?: string; buttonLoading?: boolean;
		showDocusign?: boolean; docusignDisabled?: boolean; agreementSignOnClick?: () => any; docusignLoading?: boolean;
	}
> = ({
	tagType,
	tagText,
	title,
	bookingId,
	roomType,
	roomNo,
	monthlyRent,
	startDate,
	endDate,
	showAgreement = false,
	agreementOnClick,
	imageUrl,
	buttonLoading = false,
	showDocusign = false,
	docusignDisabled = false,
	agreementSignOnClick,
	docusignLoading = false

}) => {
		const { isMobile } = useResponsive();
		return (
			<Row className={isMobile ? "argreement-card-mobile w100" : "bookingsCard w-100"} gutter={[16, 20]}>
				<Row className="w-100" gutter={16} justify="start" align="top">
					<Col sm={24} xs={24} md={4} className={isMobile ? "booking-card-mobile__image" : ""} >
						<Image
							src="https://images.unsplash.com/photo-1486325212027-8081e485255e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb"
							className="bookingCardImage"
							preview={false}
						/>
					</Col>
					<Col md={20} xs={24} className={isMobile ? "booking-card-mobile__details" : ""} >
						{
							isMobile ?
								<>
									<Row className='booking-card-mobile__info'>
										<Row>
											<Text className="black__text__cards title__card__text">
												{title}
											</Text>
										</Row>
										<Row>
											<Text className={isMobile ? "card-booking-id-mobile" : "card-booking-id"}>({bookingId})</Text>
										</Row>
										<Row>
											<Text className={isMobile ? "booking-status-mobile" : "booking-status"}>
												<Tags tagText={tagText as TagText} />
											</Text>
										</Row>
									</Row>
								</> :
								<>
									<Row justify="space-between">
										<Row justify="start">
											<Text className="black__text__cards title__card__text">
												{title}
											</Text>
											<Text className={isMobile ? "card-booking-id-mobile" : "card-booking-id"}>{bookingId}</Text>
										</Row>
										<Row justify="end">
											<Text className={isMobile ? "booking-status-mobile" : "booking-status"}>
												<Tags tagText={tagText as TagText} />
											</Text>
										</Row>
									</Row>
								</>
						}
						{
							isMobile ?
								<>
									<Row justify="start" align="middle" className="card-right-rtwo-mobile">
										<Text className="black__text__cards">{roomNo} |</Text>
										<Text className="">{roomType} |</Text>
										<Text className="black__text__cards">
											{startDate} - {endDate}
										</Text>
									</Row>
								</> :
								<>
									<Row justify="start" align="middle" className="card-right-rtwo">
										<Text className="black__text__cards">{roomNo} |</Text>
										<Text className="">{roomType} |</Text>
										<Text className="black__text__cards">
											{startDate} - {endDate}
										</Text>
									</Row>
								</>
						}
						{
							isMobile ?
								<>
									<Row
										justify="space-between"
										align="bottom"
										className="card-right-rthree-mobile"

									>
										<Row justify="start">
											<div className="monthly-rent-logo-mobile">
												<MonthlyRent />
											</div>
											<Col className="mt-six">
												<Text className="monthly-rent-string-mobile"> Monthly Rent</Text>
											</Col>
											<Col className="mt-six">
												<Text className="monthly-rent-unit-mobile">
													{'KSh'} {monthlyRent}
												</Text>
											</Col>
										</Row>
										<Row justify="end" className={isMobile ? "agreement-card-btn" : ""}>
											{showAgreement && (
												<Col span={24}>
													<Row justify={'space-between'}>
													{showDocusign && (
														<ButtonBase
															type={ButtonTypes.PRIMARY}
															text="Sign Agreement"
															onclick={agreementSignOnClick}
															loading={docusignLoading}
															disabled={docusignDisabled}
															ClassName='mr-10'
														/>
													)}
													<ButtonBase
														type={ButtonTypes.PRIMARY}
														text="View Agreement"
														onclick={agreementOnClick}
														loading={buttonLoading}
														disabled={!docusignDisabled}
													/>
													
													</Row>
												</Col>
											)}
										</Row>
									</Row>
								</> :
								<>
									<Row
										justify="space-between"
										align="bottom"
										className="card-right-rthree"
									>
										<Row justify="start">
											<div className="monthly-rent-logo">
												<MonthlyRent />
											</div>
											<Col className="mt-six">
												<Text className="monthly-rent-string"> Monthly Rent</Text>
											</Col>
											<Col className="mt-six">
												<Text className="monthly-rent-unit">
													{'KSh'} {monthlyRent}
												</Text>
											</Col>
										</Row>
										<Row  className={isMobile ? "agreement-card-btn" : ""}>
											{showAgreement && (
												<Col span={24}>
													<Row justify={'space-between'}> 
													{showDocusign && (
														<ButtonBase
															type={ButtonTypes.PRIMARY}
															text="Sign Agreement"
															onclick={agreementSignOnClick}
															loading={docusignLoading}
															disabled={docusignDisabled}
															ClassName='mr-10'
														/>
														
													)}
													<ButtonBase
														type={ButtonTypes.PRIMARY}
														text="View Agreement"
														onclick={agreementOnClick}
														loading={buttonLoading}
														disabled={!docusignDisabled}
													/>
													
													</Row>
													
												</Col>
											)}
										</Row>
									</Row>
								</>
						}
					</Col>
				</Row>
			</Row>
		);
	};

export default Index;
