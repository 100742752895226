import { serializable, alias, primitive } from 'serializr';

export class Notices {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;
}
