import { Button, Col, Drawer, Layout, Menu, Row, Typography, Space } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import useResponsive from 'shared/Hooks/useResponsive';
import React, { ReactNode, useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { ClockIconCustom, CloseIcon, HamburgerMenuMobile, NoticeNotification, NotificationsMore } from 'resources/Icons';
import isAuthenticated from '../HOC/requireAuth';
import ReferAFriendModal from '../ReferAFriend/ReferAFriendModal';
import SidebarLogo from '../SidebarLogo';
import Sidebar from 'shared/components/Layout/LayoutMain';
import logo from 'resources/images/QcareLogo.png';
import ChangePasswordModal from '../Navbar/ChangePasswordModal';
import VukaFooter from 'views/AppComponents/VukaFooter';
import NavbarService from 'services/NavbarService/navbar.service';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DateFormats } from 'enums/dateFormat';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import "./siderWrapper.scss"

const { Text } = Typography;
export default function SiderWrapperLayout({
	children,
}: {
	children: ReactNode;
}) {
	const { isTablet, isMobile, isDesktop } = useResponsive();
	const [showDrawer, setShowDrawer] = useState(false);
	const [showReferModal, setShowReferModal] = useState(false);
	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);
	const [showReferFriendModal, setShowReferFriendModal] = useState(false);
	const navigate = useNavigate();
	const changeReferFriendModalState = () =>
		setShowReferFriendModal(!showReferFriendModal);
	const { markAllAsRead, markOneAsRead, fetchAppNotifications ,appNotifications} = NavbarService();

	useEffect(() => {
		void fetchAppNotifications();
	}, [])

	const changeShowPasswordModal = () =>
		setShowPasswordModal(!showPasswordModal);

	const changeNotificationsState = () => {
		setShowNotifications(prevShowNotifications => !prevShowNotifications);
		// setShowMenu(false);
	};
	enum typesOfNavigation {
		Dashboard = 'Dashboard',
		Documents = 'Documents',
		ReferAFriend = 'Refer friend',
	}
	const markAllNotificationsAsRead = async () => {
		const res = await markAllAsRead();
		if (res === 200) {
			setShowNotifications(false);
		}
	};
	const markOneNotificationsAsRead = async (
		id: string,
		type: string,
		readAt: string | null
	) => {
		if (type === typesOfNavigation.Dashboard) {
			navigate('/app' + AppRoutes.HOME);
		} else if (type === typesOfNavigation.Documents) {
			navigate('/app' + AppRoutes.DOCUMENTS);
		} else if (type === typesOfNavigation.ReferAFriend) {
			changeReferFriendModalState();
		}
		if (readAt !== null) return;
		await markOneAsRead({ id });
	};
	
	const DrawerTitle = ({ onClose }: { onClose: () => void }) => {
		return (
			<Row>
				<Col span={20}>
					<SidebarLogo src={logo} />
				</Col>
				<Col span={4}>
					<Button
						icon={<Icon component={() => <CloseIcon />} />}
						onClick={onClose}
						className="closableActionButton"
					/>
				</Col>
			</Row>
		);
	};

	const DrawerFooter = () => {
		return (
			<Footer
				style={{
					position: 'sticky',
					bottom: 0,
					zIndex: 1,
					width: '100%',
					padding: isMobile ? '0.3125rem 0' : '1rem 1rem',
				}}
				className={isMobile ? "logo-bottom-mobile": "logo-bottom"}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
			>
				<VukaFooter hovered={hovered} isMobile={isMobile}/>
			</Footer>
		);
	};

	const SiderLayout = ({ children }: { children: ReactNode }): JSX.Element => {
		return isAuthenticated(
			<div className="full-height">
				<Layout hasSider={!isMobile}>
					{!isDesktop ? (
						<div className="top-navbar-fixed">
							<Button
								icon={<Icon component={() => (
									<HamburgerMenuMobile />
								)} />}
								onClick={() => setShowDrawer(!showDrawer)}
							/>
							<Row justify="end" align="middle" gutter={[20, 20]}>
								<Col>
									<div className="notice__icon-mobile" onClick={changeNotificationsState}>
										{!appNotifications?.length || !appNotifications?.every((i) => !i?.readAt) ? (
											<NoticeNotification />
										) : (
											<NotificationsMore />
										)}
									</div>
								</Col>
							</Row>
							{showNotifications && (
								<Row
									className="navbar-menu-mobile"
									align="middle"
									justify="center"
								>
									<Col span={24}>
										<Row className="navbar-menu__header">
											<Col span={16}>
												<Row gutter={[8, 32]} align="middle">
													<Col className="navbar-menu__notice-icon">
														<NoticeNotification color={'#65BCB0'} />
													</Col>
													<Col>
														<Text className="navbar-menu__notice-text">
															Notification
														</Text>
													</Col>
												</Row>
											</Col>
											<Col span={8} className="navbar-menu__mark-text">
												<Text
													className="greenText"
													onClick={() => {
														void markAllNotificationsAsRead();
													}}
													style={{ cursor: 'pointer' }}
												>
													Mark all as read
												</Text>
											</Col>
										</Row>
									</Col>
									<Col span={24}>
										<Menu
											mode="vertical"
											style={{
												height: '19rem',
												overflow: 'scroll',
												zIndex: '999999',
											}}
											className="app-navbar"
										>
											{appNotifications?.map((i, j) => {
												return (
													<Menu.Item
														key={j}
														className="menu-item-notifications"
														onClick={() => {
															void markOneNotificationsAsRead(
																i.id ?? '',
																i.data?.type ?? '',
																i?.readAt ?? null
															);
														}}
													>
														<Row align="top">
															<Col span={3} className="circleIcon">
																<NoticeNotification color={'#65BCB0'} />
															</Col>
															<Col span={20}>
																<Row className="notification-item-row">
																	<Col
																		span={24}
																		className="notification__item-text-col"
																	>
																		<Text
																			className={
																				i.readAt ? 'text--black' : 'text--green'
																			}
																		>
																			{i.data?.message}
																		</Text>
																	</Col>
																	<Col span={24}>
																		<Space direction="horizontal">
																			<Col className="notification__item-row-clock-icon">
																				<ClockIconCustom />
																			</Col>
																			<Text
																				type="secondary"
																				className="notification__item-row-date"
																			>
																				{moment(i.createdAt).format(DateFormats.SECONDARY)}
																			</Text>
																		</Space>
																	</Col>
																</Row>
															</Col>
														</Row>
													</Menu.Item>
												);
											})}
										</Menu>
									</Col>
								</Row>
							)}
						</div>
					) : (
						<div style={{ marginRight: isMobile ? '0' : '17.2rem' }}>
							<Sidebar showMenu={false} />
						</div>
					)}

					<Layout className="site-layout full-height">
						<Content>
							<ReferAFriendModal
								isModalVisible={showReferModal}
								changeModalState={() => setShowReferModal(!showReferModal)}
							/>
							<ChangePasswordModal
								showModal={showPasswordModal}
								changeModalState={changeShowPasswordModal}
							/>
							<Drawer
								title={
									<DrawerTitle onClose={() => setShowDrawer(!showDrawer)} />
								}
								footer={<DrawerFooter />}
								placement={'left'}
								width={isTablet ? '50%' : '100%'}
								closable={false}
								onClose={() => setShowDrawer(!showDrawer)}
								open={showDrawer}							
							>
								{showDrawer && (
									<Sidebar
										showMenu={showDrawer}
										closeDrawer={setShowDrawer}
										setShowReferFriendModal={setShowReferModal}
										showPasswordModal={changeShowPasswordModal}
									/>
								)}
							</Drawer>
							{children}
						</Content>
						{isMobile && (
							<Footer
								className='mobile__footer'
							>
								{/* // <div className='bottom-navbar-fixed'> */}
								<Sidebar />
								{/* // </div> */}
							</Footer>
						)}
					</Layout>
				</Layout>
			</div>
		);
	};
	return <SiderLayout>{children}</SiderLayout>;
}
