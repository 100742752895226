import { AppNotifications } from 'models/AppNotifications/AppNotifications.model';
import React, { createContext, ReactNode, useState } from 'react';
const NavbarNoticesContext = createContext<any>(null);


export function NavbarNotificationsProvider({ children }: { children: ReactNode }): any {

    const [notifications, setNotifications] = useState<AppNotifications[]>()



    return (
        <NavbarNoticesContext.Provider
            value={{
                notifications, setNotifications
            }}
        >
            {children}
        </NavbarNoticesContext.Provider>
    );
}

export const useNavbarNoticesContext = (): any => React.useContext(NavbarNoticesContext);
