import React, { useState, useEffect } from 'react';
import { TableProps,Row,Col,Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableUpdated from 'shared/components/Table';
import Moment from 'moment';
import { TagText } from 'shared/components/Cards';
import RefundsService from 'services/RefundsService/refunds.service';
import Tag from 'shared/components/Tag';
import { useTitleContext } from 'context/TitleComponentContext';
import { screenTypes } from 'enums/screenTypes';
import RefundsModal from './RefundsModal';
import { usdollar } from 'views/Home';
import { DateFormats } from 'enums/dateFormat';
import { TitleComponentPayments } from './TitleComponentPayments';
import { Refunds as RefundsModel } from 'models/Refunds/refunds.model';
import useResponsive from 'shared/Hooks/useResponsive';
import { RefundsMobileIcon } from 'resources/Icons';
import { TableSkeleton } from 'shared/components/Table/TableSkeleton';


const { Text } = Typography;
type DataType = {
	key: React.Key;
	// sNo: string;
	building_name: string;
	amount: number;
	created_at: string;
	isRefunded: 0 | 1 | 2;
};



const GetTag=({record}:{record:{isRefunded:number|undefined}}):JSX.Element=>{
	return <Tag
	tagText={
		record.isRefunded === 0
			? TagText.InProgress
			: record.isRefunded === 1
			? TagText.RefundCompleted
			: TagText.RefundRejected
	}
/>
}

const columns: ColumnsType<DataType> = [
	{
		title: 'ID',
		key: 'index',
		render: (text, record, index) => index + 1,
	},
	{
		title: 'Residence',
		dataIndex: 'buildingName',
		sorter: true,
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		sorter: true,
		render: (text) => <div className="text-right">{usdollar.format(text)}</div>,
	},
	{
		title: 'Requested Date',
		dataIndex: 'createdAt',
		sorter: true,
		render: (createdAt) => {
			const updatedDate = Moment(createdAt).format(DateFormats.SECONDARY)
			return <>{updatedDate}</>;
		  },
	},
	{
		title: 'Current Status',
		dataIndex: 'isRefunded',
		key: 'isRefunded',
		sorter: true,
		render: (_, record) => (
			<GetTag record={record}/>
		),
	},
];

enum Columns {
	buildingName = 'residence',
	amount = 'amount',
	createdAt = 'requested_date',
	isRefunded = 'requested_status',
}

enum SorterDirection {
	ascend = 'asc',
	descend = 'desc',
}



export const Refunds = () => {
	const [showModal, setShowModal] = useState(false);
	const showHideModal = () => setShowModal(!showModal);
	const { search, date, selectedTab } = useTitleContext();
	const { isMobile } = useResponsive();
	const [filters, setFilters] = useState<{
		page?: number;
		sort_by?: string;
		sort_dir?: string;
		search?: string;
	}>();
	const {
		refunds,
		loading,
		fetchRefunds,
		getWalletAmount,
		otpVerificationSuccess,
	} = RefundsService();




	const MobileResponsiveCards = ({ data,loading }: { data: RefundsModel[],loading:Boolean }) => {
		return loading ? (
			<TableSkeleton />
		) :<>{data?.map((item, index) => (
					<Row key={index} align="middle" style={{ paddingBottom: '0.5rem' }}>
						<Col span={18}>
							<Row gutter={[16, 5]} justify="start">
								<Col span={24}>
									<Text>{item?.buildingName}</Text>
								</Col>
								<Col span={24}>
									<Text type="secondary">{item?.createdAt}</Text>
								</Col>
							</Row>
						</Col>
						<Col span={6}>
							<Row justify="end" align="middle" gutter={[16, 5]}>
								<Col>
								{/* <Text type="secondary">{item?.isRefunded}</Text> */}
								<GetTag record={{isRefunded:item.isRefunded}}/>
								</Col>
								<Col>
									<Text type="secondary">{item?.amount}</Text>
								</Col>
							</Row>
						</Col>
					</Row>
				))}
			</>
	};
	const onChange: TableProps<DataType>['onChange'] = (
		pagination,
		filters,
		sorter: any,
		extra
	) => {
		const columns: any = Columns;
		const sortDirection: any = SorterDirection;
		// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
		// @ts-ignore
		setFilters({
			page: pagination.current,
			sort_by: sorter.order !== undefined ? columns[sorter.field] : null,
			sort_dir: sortDirection[sorter.order],
		});
		
	};

	useEffect(() => {
		if (filters) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			fetchRefunds(filters);
		}
	}, [filters]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		fetchRefunds();
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		getWalletAmount();
	}, []);

	useEffect(() => {
		if (selectedTab !== screenTypes.REFUNDS) return;
		let year, month;
		if (date) {
			const parsedDate = Moment(date);
			year = parsedDate.year();
		  	month = parsedDate.month() + 1;	
			void fetchRefunds({ year, month });
		} else {
			void fetchRefunds();
		}
		if (search !== undefined) {
			void fetchRefunds({ search });
		}
	}, [date, search]);

	

	useEffect(() => {
		if (otpVerificationSuccess) {
			showHideModal();
		}
	}, [otpVerificationSuccess]);

	return (
		<>
		<RefundsModal showHideModal={showHideModal} showModal={showModal} />
		{isMobile ? (
		<>
				<TitleComponentPayments
				buttonText={<RefundsMobileIcon color="white"/>}
					placeholder='Search by refunds'
					onclick={showHideModal}
				/>
			<MobileResponsiveCards data={refunds ?? ''} loading={loading}/>
			</>
		) : (
			<TableUpdated
				columns={columns}
				data={refunds}
				title={
					<TitleComponentPayments
						onclick={showHideModal}
						buttonText="Request Refund"
						placeholder='Search by refund details'
					/>
				}
				onchange={onChange}
				isLoading={loading}
			/>)
}			
		</>
	);
};
