import React from 'react';
import { Row, Col, Skeleton } from 'antd';
export default function NoticesSkeleton() {
	return (
		<Row className="w-100">

			<Row gutter={[16, 16]} className="w-100">
				<Col span={24}>
					<Skeleton paragraph={true} />
				</Col>
				<Col span={24}>
					<Skeleton paragraph={true} />
				</Col>
				<Col span={24}>
					<Skeleton paragraph={true} />
				</Col>
			</Row>
		</Row>
	);
}
