import { Building } from '../Building/building.model';
import { StatusId } from 'models/StatusId/statusId.model';

import { serializable, alias, object, primitive } from 'serializr';

export class Agreement {
	@serializable(alias('id', primitive()))
	id?: string;

	@serializable(alias('booking_id', primitive()))
	bookingId?: string;

	@serializable(alias('building_id', primitive()))
	buildingId?: string;

	@serializable(alias('room_id', primitive()))
	roomId?: string;

	@serializable(alias('booked_checkin', primitive()))
	bookedCheckin?: string;

	@serializable(alias('checkin', primitive()))
	checkin?: string;

	@serializable(alias('checkout', primitive()))
	checkout?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('remaining_days', primitive()))
	remainingDays?: string;

	@serializable(alias('lapsed_days', primitive()))
	lapsedDays?: string;

	@serializable(alias('deposit_paid', primitive()))
	depositPaid?: number;

	@serializable(alias('first_month_rent', primitive()))
	firstMonthRent?: number;

	@serializable(alias('rent', primitive()))
	rent?: number;

	@serializable(alias('amount_due', primitive()))
	amountDue?: number;

	@serializable(alias('building', object(Building)))
	building?: Building;

	@serializable(alias('status_id', object(StatusId)))
	status?: StatusId;

	@serializable(alias('room_type', primitive()))
	roomType?: string;

	@serializable(alias('room_type_id', primitive()))
	roomTypeId?: number;

	@serializable(alias('room_number', primitive()))
	roomNumber?: string;

	@serializable(alias('meter_number', primitive()))
	meterNumber?: string;

	@serializable(alias('agreement_status', primitive()))
	agreementStatus?: boolean;

}
