import { serializable, alias, primitive } from 'serializr';

export class Invoices {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('invoice_name', primitive()))
	invoiceName?: string;

	@serializable(alias('invoice_due_date', primitive()))
	invoiceDueDate?: string;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('invoice_status', primitive()))
	invoiceStatus?: string;
}
