import React from 'react';
import { Row, Col, Tabs } from 'antd';
import useResponsive from 'shared/Hooks/useResponsive';
import { Image } from 'shared/components/BackgroundImage/Image';
import Documents from './Documents';
import  Agreement  from './Agreement';
import { screenTypes } from 'enums/screenTypes';
import Navbar from 'shared/components/Navbar';
import background from "resources/images/containerBackground.png"
import { useParams } from 'react-router-dom';

const items = [
	{
		label: screenTypes.DOCUMENTS,
		key: screenTypes.DOCUMENTS_KEY,
		children: <Documents />,
	},
	{
		label: screenTypes.AGREEMENTS,
		key: screenTypes.AGREEMENTS_KEY,
		children: <Agreement />,
	},
];


const ImportantDocuments = () => {
	const { isMobile } = useResponsive();
	const { path } = useParams();
	return (
		<>{!isMobile && (
			<Image
				src={background}
			>
				<Navbar />
			</Image>)}
			<Row className="main-container">
				<Col span={24}>
					<Tabs defaultActiveKey={path}  moreIcon={false} items={items}/>

				</Col>
			</Row>
		</>
	);
};

export default React.memo(ImportantDocuments)
