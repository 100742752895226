import { useProfileContext } from 'context/ProfileContext';
import { useEffect } from 'react';
import ProfileService from 'services/ProfileService/profile.service';

export const useCountriesAndSalutations = () => {
	const { salutationListProvider, countriesListProvider } = useProfileContext();
	const { getCountriesList, getSalutations } = ProfileService();
	useEffect(() => {
		
		if (countriesListProvider.length <= 0) {
			void getCountriesList();
		}
		if (salutationListProvider.length <= 0) {
			void getSalutations();
		}

		return () => {};
	}, []);

	return {
		salutationListProvider,
		countriesListProvider,
	};
};
