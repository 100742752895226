
import React, { useState, FC, ReactNode } from 'react';
import { InputFieldForDropdown } from './index';
import { Select } from 'antd';
import { SolidArrow } from 'resources/Icons';
const { Option } = Select;

type inputWithPrefix = {
	prefixData: any[];
	placeholder: string;
	changeValue: (e: string) => any;
	type: string;
	defaultPrefixSelector?: ReactNode;
	value?: string;
	disabled?: boolean;
	id?: string
};

export const InputWithPrefix: FC<inputWithPrefix> = ({
	prefixData,
	placeholder,
	changeValue,
	type,
	defaultPrefixSelector,
	value,
	disabled = false,
	id
}) => {
	const [prefix, setPrefix] = useState<string>('');

	const prefixSelector =
		disabled ? <>{prefixData[0]}</> : <Select
			style={{ width: 70 }}
			onChange={(e) => setPrefix(e)}
			defaultValue={prefixData[0]}
			suffixIcon={<SolidArrow />}
			className="input-with-prefix-select"
			disabled={disabled}

		>
			{prefixData.map((i) => {
				return <Option key={i}>{i}</Option>;
			})}
		</Select>


	return (

		<InputFieldForDropdown
			disabled={disabled}
			placeholder={placeholder}
			prefix={disabled ? undefined : defaultPrefixSelector ?? prefixSelector}
			type={type}
			id={id}
			onChange={(e: { target: { value: string } }) => changeValue(prefix + e.target.value)}
			defaultValue={value}
			className={disabled ? "ant-disabled-input" : undefined}
		// value={value}
		/>

	);
};
