import { useState, useEffect } from 'react';

const useResponsive = () => {
	const [isMobile, setIsMobile] = useState<boolean>();
	const [isTablet, setIsTablet] = useState<boolean>();
	const [isDesktop, setIsDesktop] = useState<boolean>();

	useEffect(() => {
		if (window.innerWidth <= 600) {
			setIsMobile(true);
			setIsTablet(false);
			setIsDesktop(false);
		} else if (window.innerWidth > 600 && window.innerWidth <= 900) {
			setIsMobile(false);
			setIsTablet(true);
			setIsDesktop(false);
		} else {
			setIsMobile(false);
			setIsTablet(false);
			setIsDesktop(true);
		}
	}, [window.innerWidth]);

	return { isMobile, isTablet, isDesktop };
};

export default useResponsive;
