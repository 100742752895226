import { useState } from 'react';
import { deserialize } from 'serializr';

import axiosInstance from 'interceptor/axiosInstance';
import { ApiRoutes } from 'routes/routeConstants/apiRoutes';
import { Maintenance } from 'models/Maintenance/maintenance.model';
import { Pagination } from 'models/Pagination/pagination.model';
import { NotificationTypes } from 'enums/notificationTypes';
import Notification from '../../shared/components/Notification';
import { MaintenanceTicket } from 'models/Maintenance/maintenanceTicket.model';
// import { RcFile } from 'antd/lib/upload';
const MaintenanceService = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [buttonLoading, setButtonLoading] = useState<boolean>(false);
	const [maintenanceTicketLoader, setMaintenanceTicketLoader] = useState(false);
	const [Maintenances, setMaintenances] = useState<Maintenance[]>([]);
	const [ticketCategories, setTicketCategories] =
		useState<[{ id: number; name: string }]>();
	const [ticketLocations, setTicketLocations] =
		useState<[{ id: number; name: string }]>();
	const [pages, setPages] = useState();
	const [ticketData, setTicketData] = useState<MaintenanceTicket>();
	const [MaintenancesPagination, setMaintenancesPagination] =
		useState<Pagination | null>();

	const fetchMaintenances = async (params?: {
		search?: string;
		page?: number;
		sort_by?: string;
		sort_dir?: string;
		year?: number;
		month?: number;
	}) => {
		try {
			setLoading(true);
			// setButtonLoading(true);
			const response = await axiosInstance.get(ApiRoutes.MAINTENANCE, {
				params,
			});
			const maintenanceData = deserialize(Maintenance, response.data.data.data);

			setPages(response.data.data.total);

			setMaintenances(maintenanceData as Maintenance[]);
			setLoading(false);
			setButtonLoading(false);
		} catch (error) {
			setMaintenancesPagination(null);
			setMaintenances([]);
			setLoading(false);
			// setButtonLoading(false);
		} finally {
			setLoading(false);
			// setButtonLoading(false);
		}
	};

	const getTicketCategories = async () => {
		try {
			const response = await axiosInstance.get(ApiRoutes.TICKET_CATEGORIES);
			setTicketCategories(response.data.data);
		} catch (error) {
			console.log('');
		} finally {
			console.log('');
		}
	};

	const getTicketData = async (params: { ticketId: string }) => {
		try {
			setMaintenanceTicketLoader(true);
			const response = await axiosInstance.get(
				`${ApiRoutes.TICKETS}/${params?.ticketId}`
			);

			const ticketdata = deserialize(MaintenanceTicket, response.data.data);

			setTicketData(ticketdata);
			setMaintenanceTicketLoader(false);
		} catch (error) {
			setMaintenanceTicketLoader(false);
			console.log('error', error);
		} finally {
			setMaintenanceTicketLoader(false);
			console.log('done');
		}
	};
	const getTicketLocations = async () => {
		try {
			const response = await axiosInstance.get(ApiRoutes.TICKET_LOCATIONS);

			setTicketLocations(response.data.data);
		} catch (error) {
			console.log('');
		} finally {
			console.log('');
		}
	};

	const editMaintenanceTicketStatus = async (params: {
		ticket_id: string;
		status_id: string;
	}) => {
		try {
			const response = await axiosInstance.post(
				ApiRoutes.UPDATE_TICKET_STATUS,
				params
			);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: 'Success',
				description: response?.data.message,
				type:
					response.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});
		} catch (error: any) {
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const editMaintenanceTicketImage = async (params: {
		attachment?: any;
		ticketId?: string;
	}) => {
		try {
			const { attachment, ticketId } = params;
			const formData = new FormData();

			// const { originFileObj } = attachment;
			formData.append(`attachments[${0}]`, attachment);
			if (ticketId) {
				formData.append('ticket_id', ticketId);
			}

			const res = await axiosInstance.post(
				ApiRoutes.UPDATE_TICKET_ATTACHMENTS,
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' },
				}
			);
			return res.status;
		} catch (error) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: 'oh ho',
				description: 'Something went wrong',
				type: NotificationTypes.ERROR,
			});
		}
	};

	const raiseATicket = async (params?: {
		ticket_category?: number;
		description?: String;
		location_id?: number;
		attachments?: any[];
	}) => {
		setButtonLoading(true);
		try {

			const attachments = params?.attachments;

			const formData = new FormData();
			if (params?.location_id !== undefined) {
				formData.set('location_id', params?.location_id?.toString() ?? '');
			}
			if (params?.ticket_category !== undefined) {
				formData.set(
					'ticket_category',
					params?.ticket_category?.toString() ?? ''
				);
			}

			if (params?.description !== undefined) {
				formData.set('description', params?.description.toString());
			}

			let j = 0;
			if (attachments !== undefined) {
				for (const i of attachments) {
					const { originFileObj } = i;
					formData.append(`attachments[${j}]`, originFileObj);
					j++;
				}

				const res = await axiosInstance.post(ApiRoutes.TICKETS, formData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});
				if (res.status === 200) {
					void Notification({
						message: 'Success',
						description: 'Ticket Created Succefully',
						type: NotificationTypes.SUCCESS,
					});
					return res;
				}
			}
		} catch (error: any) {
			const { response } = error;
			void Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		} finally {
			setButtonLoading(false);
		}
	};



	return {
		loading,
		Maintenance,
		Maintenances,
		buttonLoading,
		fetchMaintenances,
		getTicketLocations,
		getTicketCategories,
		ticketLocations,
		ticketCategories,
		MaintenancesPagination,
		pages,
		raiseATicket,
		getTicketData,
		ticketData,
		maintenanceTicketLoader,
		editMaintenanceTicketImage,
		editMaintenanceTicketStatus,
	};
};

export default MaintenanceService;
