import React, { FC } from 'react';
import { Button } from 'antd';
import './button.scss';
import { ButtonTypes } from 'enums/buttonTypes';

type ButtonProps = {
	text: string;
	onclick?: () => void;
	disabled?: boolean;
	type: ButtonTypes;
	loading?: boolean;
	block?: boolean;
	htmlType?: 'button' | 'submit' | 'reset';
	ClassName?: string;
};
type ButtonType = Omit<ButtonProps, 'type' | 'loading'>;

const ButtonBase: FC<ButtonProps> = ({
	htmlType = 'button',
	block = false,
	type = ButtonTypes.PRIMARY,
	text = 'Button',
	onclick,
	loading = false,
	disabled = false,
	ClassName,
}) => {
	if (loading) {
		return ButtonLoading({ text, block, ClassName });
	} else {
		switch (type) {
			case ButtonTypes.PRIMARY:
				return ButtonPrimary({
					htmlType,
					text,
					onclick,
					disabled,
					block,
					ClassName,
				});
			case ButtonTypes.SECONDARY:
				return ButtonSecondary({ text, onclick, disabled, block });
			case ButtonTypes.TERTIARY:
				return ButtonTertiary({ text, onclick, disabled, block, ClassName });
			case ButtonTypes.LOADING:
				return ButtonLoading({ text, block });
		}
	}
	return <></>;
};

const ButtonPrimary: FC<ButtonType> = ({
	htmlType,
	text,
	onclick,
	disabled,
	block,
	ClassName,
}): JSX.Element => (
	<Button
		type="primary"
		onClick={onclick}
		block={block}
		disabled={disabled}
		htmlType={htmlType}
		className={ClassName}
	>
		{text}
	</Button>
);
const ButtonSecondary: FC<ButtonType> = ({
	text,
	onclick,
	disabled,
	block,
}): JSX.Element => (
	<Button type="default" onClick={onclick} block={block} disabled={disabled}>
		{text}
	</Button>
);
const ButtonTertiary: FC<ButtonType> = ({
	text,
	onclick,
	disabled,
	block,
	ClassName,
}): JSX.Element => (
	<Button
		type="text"
		onClick={onclick}
		block={block}
		disabled={disabled}
		className={ClassName}
	>
		{text}
	</Button>
);

const ButtonLoading: FC<Pick<ButtonProps, 'block' | 'text' | 'ClassName'>> = ({
	text,
	block,
	ClassName,
}) => (
	<Button
		type="primary"
		loading={true}
		block={block}
		size="large"
		className={ClassName}
	>
		{text}
	</Button>
);

export default ButtonBase;
