import { serializable, alias, primitive } from 'serializr';

export class PaymentsForHome {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('invoice_name', primitive()))
	invoiceName?: string;

	@serializable(alias('due_date', primitive()))
	dueDate?: string;

	@serializable(alias('amount', primitive()))
	amount?: string;

	@serializable(alias('amount_paid', primitive()))
	amountPaid?: string;
}
