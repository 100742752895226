import React from 'react';
import { Tag } from 'antd';
import { TagText } from '../Cards';
import './index.scss';
const Tags = ({ tagText,cssClass}: { tagText: TagText,cssClass?:string }): React.ReactElement => {
	switch (tagText) {
		case TagText.Active:
		case TagText.Approved:
		case TagText.Booked:
		case TagText.CheckedIn:
		case TagText.DocumentsApproved:
		case TagText.Paid:
		case TagText.paid:
		case TagText.Completed:
		case TagText.PaymentCompleted:
			return (
				<Tag color="#E1F7EC" className="booking-card-tag-success tag--green">
					{tagText === TagText.CheckedIn ? 'Checked In' : tagText}
				</Tag>
			);
		case TagText.CheckedOut:
		case TagText.DocumentsNotUploaded:
		case TagText.Inactive:
		case TagText.NotOccupied:
		case TagText.Pending:
		case TagText.RequestInitiated:
		case TagText.Upload:
			return (
				<Tag className="booking-card-tag-success tag--orange">
					{tagText}
				</Tag>
			);
		case TagText.BookingCancelled:
		case TagText.DocumentsRejected:
		case TagText.Closed:
		case TagText.Escalated:
		case TagText.Rejected:
		case TagText.Cancelled:
		case TagText.ReOpened:
			return (
				<Tag className="booking-card-tag-success tag--red">
					{tagText}
				</Tag>
			);
		case TagText.TokenGenerated:
		case TagText.token_generated:
			return (
				<Tag color="#CDE1FA" className="booking-card-tag-success tag--green">
					{TagText.TokenGenerated}
				</Tag>
			);
		case  TagText.RoomAllocatted:
			return (
				<Tag color="#E1F7EC" className="booking-card-tag-success tag--green">
					{tagText}
				</Tag>
			)
		case TagText.RefundCompleted:
			return (
				<Tag color="#E1F7EC" className="booking-card-tag-success tag--green">
					{tagText}
				</Tag>
			)
		case TagText.RefundRejected:
			return(
				<Tag color='#fbd4c4' className="booking-card-tag-success tag--red">
					{tagText}
				</Tag>
			)
		case TagText.InProgress:
			return(
				<Tag color='#ffebc9;' className="booking-card-tag-success tag--orange">
					{tagText}
				</Tag>
			)
		default:
			return <>{tagText}</>
	}

};


export const TagsHome = ({ tagText }: { tagText: TagText }): React.ReactElement => {
	switch (tagText) {
		case TagText.Active:
		case TagText.Approved:
		case TagText.Booked:
		case TagText.DocumentsApproved:
		case TagText.Paid:
		case TagText.paid:
		case TagText.Completed:
		case TagText.PaymentCompleted:
		case TagText.CheckedIn:
			return (
				<Tag color="#E1F7EC" className="booking-card-tag-success-home tag--green">
					{tagText === TagText.CheckedIn ? 'Checked In' : tagText}
				</Tag>
			);
		case TagText.CheckedOut:
		case TagText.DocumentsNotUploaded:
		case TagText.Inactive:
		case TagText.NotOccupied:
		case TagText.Pending:
		case TagText.RequestInitiated:
		case TagText.Upload:

			return (
				<Tag className="booking-card-tag-success-home tag--orange">
					{tagText === TagText.CheckedOut ? 'Checked In' : tagText}
				</Tag>
			);
		case TagText.BookingCancelled:
		case TagText.DocumentsRejected:
		case TagText.Closed:
		case TagText.Escalated:
		case TagText.Rejected:
		case TagText.Cancelled:
			return (
				<Tag className="booking-card-tag-success-home tag--red">
					{tagText}
				</Tag>
			);
		case TagText.TokenGenerated:
			return (
				<Tag color="#CDE1FA" className="booking-card-tag-success-home tag--green">
					{TagText.TokenGenerated}
				</Tag>
			);
		default:
			return <Tag>{tagText}</Tag>
	}

};
export default Tags;
