import { Row, Col, Tabs, } from 'antd';
import React, { memo, useState, useEffect } from 'react';
import Invoices from './Invoices';
import { Payments } from './Payments';
import { Refunds } from './Refunds';
import { Image } from 'shared/components/BackgroundImage/Image';
import { screenTypes } from 'enums/screenTypes';
import Navbar from 'shared/components/Navbar';
import useResponsive from 'shared/Hooks/useResponsive';
import './financials.scss';
import { useTitleContext } from 'context/TitleComponentContext';
import ReferAFriendModal from 'shared/components/ReferAFriend/ReferAFriendModal';
import background from "resources/images/containerBackground.png"
const items = [
	{
		label: screenTypes.INVOICES,
		key: screenTypes.INVOICES,
		children: <Invoices />,
	}, // remember to pass the key prop
	{
		label: screenTypes.PAYMENTS,
		key: screenTypes.PAYMENTS,
		children: <Payments />,
	},
	{
		label: screenTypes.REFUNDS,
		key: screenTypes.REFUNDS,
		children: <Refunds />,
	},
];

const FinancialsScreen = () => {
	const { isMobile } = useResponsive();
	const [showModal, setShowModal] = useState(false);
	// const changeModalState = () => setShowModal(!showModal);
	const { setSelectedTab } = useTitleContext();
	useEffect(() => {
		setCurrentTab(screenTypes.INVOICES);
		setSelectedTab(screenTypes.INVOICES);
	}, [])



	const [currentTab, setCurrentTab] = useState<screenTypes>(screenTypes.INVOICES);
	const changeCurrentTab = (item: screenTypes): void => {
		setCurrentTab(item);
		setSelectedTab(item);
		console.log(currentTab);
	};
	

	return (
		<>
			<ReferAFriendModal isModalVisible={showModal} changeModalState={() => setShowModal(!showModal)} />
			{!isMobile && <Image
				src={background}
			>
				<Navbar />
			</Image>}
			<Row className={!isMobile ? "main-container" : "mobile_container"}>
				
				<Col span={24} style={{ marginBottom: '5rem' }}>
					<Tabs onChange={(item) => changeCurrentTab(item as screenTypes)} defaultActiveKey="1" moreIcon={false} items={items} />

				</Col>
			</Row>
		</>
	);
};

export default memo(FinancialsScreen);
