import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { AppNotifications } from 'models/AppNotifications/AppNotifications.model';
// import useSWR from 'swr';
import Notification from 'shared/components/Notification';
import { NotificationTypes } from 'enums/notificationTypes';
import { useState } from 'react';

// const fetcher = async (url: string, params: {}) =>
// 	await axiosInstance.get(url, { params }).then((res) => res.data);
// export const useAppNotificationsData = () => {
// 	const { data: response, error } = useSWR(
// 		[ApiRoutes.APP_NOTIFICATIONS],
// 		fetcher
// 	);
// 	let appNotificationsData;
// 	// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
// 	if (response) {
// 		appNotificationsData = deserialize(
// 			AppNotifications,
// 			response?.data
// 		) as AppNotifications[];
// 	}

// 	return {
// 		appNotificationsData,
// 		error,
// 		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
// 		isLoading: !error && !response,
// 	};
// };

const NavbarService = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const [appNotifications, setAppNotifications] = useState<AppNotifications[]>(
		[]
	);

	const fetchAppNotifications = async (params?: {
		search?: string;
		page?: number;
	}) => {
		try {
			setLoading(true);
			setButtonLoading(true);
			const response = await axiosInstance.get(ApiRoutes.APP_NOTIFICATIONS);
			const navbar = deserialize(
				AppNotifications,
				response.data.data
			) as AppNotifications[];
			// const pagination = deserialize(Pagination, response.data["meta"])
			// setNavbarsPagination(pagination)
			setAppNotifications(navbar);
			setLoading(false);
		} catch (error) {
			// setNavbarsPagination(null)
			setAppNotifications([]);
		} finally {
			setLoading(false);
		}
	};

	const markAllAsRead = async () => {
		try {
			const response = await axiosInstance.put(ApiRoutes.MARK_ALL_AS_READ);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response?.data.message,
				type:
					response.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});

			return response.status;
		} catch (error: any) {
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};
	const markOneAsRead = async (params: { id: string }) => {
		try {
			const { id } = params;
			const response = await axiosInstance.put(`${ApiRoutes.MARK_READ}/${id}`);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response?.data.message,
				type:
					response.status === 200
						? NotificationTypes.SUCCESS
						: NotificationTypes.ERROR,
			});

			return response.status;
		} catch (error: any) {
			const { response } = error;
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	//     // const updateNavbar = async (navbar: Navbar) => {
	//     //     try {
	//     //         setButtonLoading(true)
	//     //         const payload = { navbar: serialize(Navbar, navbar) }
	//     //         const API_URL = generatePath(ApiRoutes.NAVBARS_SPECIFIC, { navbarId: String(navbar.id) })
	//     //         const response = await axiosInstance.put(API_URL, payload)
	//     //         const data = deserialize(Navbar, response.data["navbar"]) as Navbar;
	//     //         setNavbar(data)
	//     //     } catch (error) {
	//     //         setNavbar(null)
	//     //     } finally {
	//     //         setButtonLoading(false)
	//     //     }
	//     // }

	return {
		markAllAsRead,
		markOneAsRead,
		appNotifications,
		loading,
		buttonLoading,
		fetchAppNotifications,
	};
};

export default NavbarService;
