export enum screenTypes {
	BOOKINGS = 'Bookings',
	WAITING_LIST = 'Waiting List',
	INVOICES = 'Invoices',
	PAYMENTS = 'Payments',
	DOCUMENTS = 'Documents',
	AGREEMENTS = 'Agreement',
	AGREEMENTS_KEY = 'agreement',
	DOCUMENTS_KEY = 'documents',
	VISITOR_INVITES = 'Visitor Invites',
	VISITOR_LOGS = 'Visitor Logs',
	MAINTENANCE = 'Maintenance',
	REFUNDS = 'Refunds',
}
