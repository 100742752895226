import axiosInstance from '../../interceptor/axiosInstance';
import { deserialize } from 'serializr';
import { User } from '../../models/user.model';
import Notification from '../../shared/components/Notification';
import { NotificationTypes } from '../../enums/notificationTypes';
import { useState } from 'react';
import { ApiRoutes } from '../../routes/routeConstants/apiRoutes';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../routes/routeConstants/appRoutes';

const UserService = () => {
	const navigate = useNavigate();

	const [error, setError] = useState<Error>();

	const [loading, setLoading] = useState(false);

	const { setAuthenticated } = AuthContext();
	const [showLoadingForOtp, setShowLoadingForOtp] = useState(false);
	const logUserOut = async () => setAuthenticated({}, false);

	const loginUser = async (data: User): Promise<any> => {
		setLoading(true);
		return await axiosInstance
			.post(ApiRoutes.USER_LOGIN, data)
			.then((response: { data: { [x: string]: any } }) => {
				const authToken = response.data.data.token;
				localStorage.setItem('user_token', JSON.stringify(authToken));
				const user = deserialize(User, response.data.data.user_details);
				localStorage.setItem('user', JSON.stringify(user));
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				Notification({
					message: 'Login',
					description: 'Logged in successfully',
					type: NotificationTypes.SUCCESS,
				});
				setAuthenticated(user, true);
				navigate('/app/' + AppRoutes.HOME);
			})
			.catch((error: any) => {
				const { response } = error;
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				Notification({
					message: response.data.status,
					description: response.data.message,
					type: NotificationTypes.ERROR,
				});
				setError(response.data.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const forgotPasswordGenerateOtp = async (payload: { qcareEmail: string }) => {
		try {
			setShowLoadingForOtp(true);
			const response = await axiosInstance.post(
				ApiRoutes.FORGOT_PASSWORD_GENERATE_OTP,
				payload
			);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.SUCCESS,
			});

			setShowLoadingForOtp(false);
			return true;
		} catch (error: any) {
			const { response } = error;
			setShowLoadingForOtp(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const verifyEmail = async (payload: { qcareEmail: string; otp: string }) => {
		try {
			setShowLoadingForOtp(true);
			const response = await axiosInstance.post(
				ApiRoutes.FORGOT_PASSWORD_VERIFY_OTP,
				payload
			);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.SUCCESS,
			});

			setShowLoadingForOtp(false);
			return true;
		} catch (error: any) {
			const { response } = error;
			setShowLoadingForOtp(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	const resetPassword = async (payload: {
		otp: string,
		newPassword: string;
		confirmPassword: string;
		qcareEmail: string;
		
	}) => {
		try {
			const structuredPayload = {
				qcareEmail: payload.qcareEmail,
				otp: payload.otp,
				new_password: payload.newPassword,
				confirm_password: payload.confirmPassword,
			};
			setLoading(true)
			const response = await axiosInstance.post(ApiRoutes.RESET_PASSWORD, structuredPayload);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.SUCCESS,
			});
			

			setLoading(false);
			return true;
		} catch (error: any) {
			const { response } = error;
			setLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			Notification({
				message: response.data.status,
				description: response.data.message,
				type: NotificationTypes.ERROR,
			});
		}
	};

	return {
		error,
		loading,
		loginUser,
		logUserOut,
		forgotPasswordGenerateOtp,
		showLoadingForOtp,
		verifyEmail,
		resetPassword,
	};
};

export default UserService;
