import { useState } from 'react';
import { deserialize } from 'serializr';
// import { generatePath } from 'react-router-dom';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Bookings as BookingsModel } from 'models/Bookings/bookings.model';
import { Pagination } from './../../models/Pagination/pagination.model';

const BookingsService = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const [BookingsPagination, setBookingsPagination] =
		useState<Pagination | null>();

	const [Bookings, setBookings] = useState<BookingsModel[]>();

	const fetchBookings = async (params?: { search?: string; page?: number }) => {
		try {
			setLoading(true);
			setButtonLoading(true);

			const response = await axiosInstance.get(ApiRoutes.BOOKINGS, { params });
			const Bookings = deserialize(
				BookingsModel,
				response.data.data
			) as BookingsModel[];
			if (typeof Bookings !== 'undefined') {
				const pagination = deserialize(Pagination, response.data.meta);
				setBookingsPagination(pagination);
				setBookings(Bookings);
			}
		} catch (error) {
			setBookingsPagination(null);
			setBookings([]);
		} finally {
			setLoading(false);
			setButtonLoading(false);
		}
	};

	// const createBookings = async (Bookings: Bookings) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { Bookings: serialize(Bookings, Bookings) }
	//         const response = await axiosInstance.post(ApiRoutes.BOOKINGS, payload)
	//         const data = deserialize(Bookings, response.data["bookings"]) as Bookings;
	//         setBookings(data)
	//     } catch (error) {
	//         setBookings(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const updateBookings = async (Bookings: Bookings) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { Bookings: serialize(Bookings, Bookings) }
	//         const API_URL = generatePath(ApiRoutes.BOOKINGS_SPECIFIC, { BookingsId: String(Bookings.id) })
	//         const response = await axiosInstance.put(API_URL, payload)
	//         const data = deserialize(Bookings, response.data["bookings"]) as Bookings;
	//         setBookings(data)
	//     } catch (error) {
	//         setBookings(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const deleteBookings = async (BookingsId: string) => {
	//     try {
	//         setButtonLoading(true)
	//         const API_URL = generatePath(ApiRoutes.BOOKINGS_SPECIFIC, { BookingsId })
	//         await axiosInstance.delete(API_URL)
	//         return true
	//     } catch (error) {
	//         return false
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const showBookings = async (BookingsId: string) => {
	//     try {
	//         setLoading(true)
	//         const API_URL = generatePath(ApiRoutes.BOOKINGS_SPECIFIC, { BookingsId })
	//         const response = await axiosInstance.get(API_URL)
	//         const data = deserialize(Bookings, response.data["bookings"]) as Bookings;
	//         setBookings(data)
	//     } catch (error) {
	//         setBookings(null)
	//     } finally {
	//         setLoading(false)
	//     }
	// }

	return {
		loading,
		Bookings,
		buttonLoading,
		fetchBookings,
		BookingsPagination,
	};
};

export default BookingsService;
