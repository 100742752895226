import { Nationality } from '../Nationality/nationality.model';

import { serializable, alias, object, primitive } from 'serializr';

export class Profile {
	@serializable(alias('first_name', primitive()))
	firstName?: string;

	@serializable(alias('birth_date', primitive()))
	birthDate?: string;

	@serializable(alias('nationality', object(Nationality)))
	nationality?: Nationality;

	@serializable(alias('surname', primitive()))
	surname?: string;
}
