import React, { useEffect } from 'react';
import { Row, Col, Divider } from 'antd';
import NoDataAnimation from 'shared/components/Animations';
import BookingImageCard, { TagText } from 'shared/components/Cards';
import BookingsService from 'services/BookingsService/Bookings.service';
import BookingsSkeleton from './BookingsSkeleton';
import moment from 'moment';
import { usdollar } from 'views/Home';
import { DateFormats } from 'enums/dateFormat';

export default function BookingsComponent() {
	const { Bookings, fetchBookings, loading } = BookingsService();
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		fetchBookings();
	}, []);
	return loading ? (
		<BookingsSkeleton />
	) : (
		<>
			<Row gutter={[16, 15]}>
				{Bookings != null && Bookings.length >= 1 ? (
					Bookings.map((item, index) => {
						return (
							<>
								<Col
									xs={24}
									md={24}
									xl={24}
									xxl={24}
									key={index}
								>
									<BookingImageCard
										title={item?.buildingId?.name}
										roomType={item.roomType}
										tagText={item?.statusId?.name as TagText}
										bookingId={item.bookingId}
										roomNo={item.roomId?.roomNumber}
										startDate={moment(item.actualCheckinDate).format(
											DateFormats.SECONDARY
										)}
										endDate={moment(item.actualCheckoutDate).format(
											DateFormats.SECONDARY
										)}
										monthlyRent={usdollar.format(item?.rent ?? 0)}
									/>
								</Col>
								<Divider className='booking-card-divider' />
							</>
						);
					})
				) : (
					<>
						<NoDataAnimation />
					</>
				)}
			</Row>
		</>
	);
}
