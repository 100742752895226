import { TitleProvider } from 'context/TitleComponentContext'
import React from 'react'
import { MaintenanceScreen } from './MaintenanceScreen'

export default function index() {
    return (
        <TitleProvider>
            <MaintenanceScreen />
        </TitleProvider>
    )
}
