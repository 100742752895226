export const NavigationRoutes = {
	AUTH: '/auth',
	REGISTER: '/auth/register',
	LOGIN: '/auth/login',
	HOME: '/app/home',
};

export const AppRoutes = {
	APP: '/app/*',
	AUTH: '/auth/*',
	REGISTER: '/register',
	LOGIN: '/login',
	FORGOT_PASSWORD: '/forgot',
	FORGOT_PASSWORD_OTP: '/forgot-otp',
	RESET_PASSWORD: '/reset-password',
	APP_COMPONENTS: '/app-components',
	HOME: `home`,
	PROFILE: 'profile',
	DOCUMENTS: 'important-documents/documents',
	IMPORTANT_DOCUMENTS:'important-documents',
	IMPORTANT_DOCUMENTS_EXTENSIONS: 'important-documents/:path',
	FINANCIALS: 'financials',
	MAINTENANCE: 'maintenance',
	BOOKING: 'booking',
	NOTICE: 'notice',
	COMMUNITY: 'know-your-community',
	VISITOR: 'visitor-details',
	ELECTRICITY: 'electricity-token',
	FAQ: 'faq',
};
