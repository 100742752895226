import { Col, Form, Row, Space, Typography } from 'antd'
import React, { useState } from 'react'
import { Electricity } from 'resources/Icons'
import { InputFieldForEmail } from 'shared/components/InputField';
import ModalComponent, { ModalSize } from 'shared/components/Modal'
// import PhoneInput from 'react-phone-number-input';
import ElectricitytokenService from 'services/ElectricitytokenService/ElectricityToken.service';
import { useElectricityToken } from 'shared/Hooks/useElectricityToken';
const { Title, Text } = Typography;

type modalProps = {
    showModal: any,
    showHideModal: any,
    isMobile?: boolean,
}


export default function ElectricityTokenModel({ showModal, showHideModal, isMobile }: modalProps) {

    const [amount, setAmount] = useState<number>();
    const [phoneNumber, setPhoneNumber] = useState<any>();
    const { electricityTokenProvider } = useElectricityToken();
    const {
        buttonLoading,
        createElectricitytoken
    } = ElectricitytokenService();

    const createElectricityBill = async (): Promise<void> => {
        const data = {
            amount,
            mpesa_phone_num: phoneNumber,
        };
        await createElectricitytoken(data);
    };
    return (
			<ModalComponent
				shouldModalOpen={showHideModal}
				buttonLoading={buttonLoading}
				visible={showModal}
				showFooter={true}
				okButtonText={'Pay With MPESA'}
				cancelButtonText={'Cancel'}
				buttonLoadingBlock={false}
				title={
					<Space direction="horizontal">
						<Electricity color="#65BCB0" />
						<Title level={4}>Electricity Token</Title>
					</Space>
				}
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				onButtonOkClick={createElectricityBill}
				size={ModalSize.SMALL}
				className={
					isMobile ? 'electricity-token-modal' : 'electricityTokenModal'
				}
			>
				<Form>
					<Row gutter={[16, 0]}>
						{electricityTokenProvider !== undefined ? (
							<Col span={24}>
								<Row>
									{isMobile ? (
										<>								
											<Row
												className={
													isMobile
														? 'electricity-token-modal__building-info'
														: ''
												}
											>
												<Col span={15}>
													<Text type="secondary" className="secondary__text">
														Building
													</Text>
													<Title
														level={5}
														className="primary__text no__margin__top"
													>
														{electricityTokenProvider[0]?.buildingName}
													</Title>
												</Col>
												<Col span={9}>
													<Text type="secondary" className="secondary__text">
														Room Number
													</Text>
													<Title
														level={5}
														className="primary__text no__margin__top"
													>
														{electricityTokenProvider[0]?.roomNumber}
													</Title>
												</Col>
											</Row>
											<Row
												className={
													isMobile ? 'electricity-token-modal__meter-info' : ''
												}
											>
												<Col span={24}>
													<Text type="secondary" className="secondary__text">
														Meter Number
													</Text>
													<Title
														level={5}
														className="primary__text no__margin__top"
													>
														{electricityTokenProvider[0]?.meterNumber}
													</Title>
												</Col>
											</Row>
										</>
									) : (
										<>
											<Col span={10}>
												<Text type="secondary" className="secondary__text">
													Building
												</Text>
												<Title
													level={5}
													className="primary__text no__margin__top"
												>
													{electricityTokenProvider[0]?.buildingName}
												</Title>
											</Col>
											<Col span={6}>
												<Text type="secondary" className="secondary__text">
													Room Number
												</Text>
												<Title
													level={5}
													className="primary__text no__margin__top"
												>
													{electricityTokenProvider[0]?.roomNumber}
												</Title>
											</Col>
											<Col span={8}>
												<Text type="secondary" className="secondary__text">
													Meter Number
												</Text>
												<Title
													level={5}
													className="primary__text no__margin__top"
												>
													{electricityTokenProvider[0]?.meterNumber}
												</Title>
											</Col>
										</>
									)}
									<br />
								</Row>
							</Col>
						) : (
							<></>
						)}
						<Col span={22}>
							<Form.Item>
								<label>
									<Text type="secondary" className="secondary__text">
										Amount
									</Text>
								</label>
								<InputFieldForEmail
									type="number"
									placeholder="Enter Amount"
									onChange={(e: any) => setAmount(parseFloat(e.target.value))}
								/>
							</Form.Item>

							<Form.Item>
								<label>
									<Text type="secondary" className="secondary__text">
										Mpesa Account Number
									</Text>
								</label>
								{/* <InputWithPrefix placeholder='Enter account number' prefixData={prefixDataForNumbers} changeValue={changeValue} type="number" /> */}
								<InputFieldForEmail
									type="number"
									placeholder="Enter Account Number"
									onChange={(e: any) => setPhoneNumber(e.target.value)}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</ModalComponent>
		);
}
