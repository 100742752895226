import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Divider, Space, Typography,Row,Col } from 'antd'
import ModalComponent from 'shared/components/Modal'
import InputField from 'shared/components/InputField';
import ButtonBase from 'shared/components/Button';
import { ButtonTypes } from 'enums/buttonTypes';
import RefundsService from 'services/RefundsService/refunds.service';
import './refundsModal.scss';
import { Refunds } from 'resources/Icons';


const { Text } = Typography;
export default function RefundsModal({ showModal, showHideModal, isMobile }: any) {
    const { walletAmount, buttonLoading, otpRequestSuccess, getWalletAmount, otpVerificationSuccess, requestRefund, verifyOtp } = RefundsService();
    const [successfulRefund, setSuccessfulRefund] = useState(false);
    const initialValues = {
        Refund: 0,
        OTP: 0,
        RefundAmountKSh:0
    };
    useEffect(() => {
        if (otpVerificationSuccess) {
            showHideModal();
            setSuccessfulRefund(false);
        }
    }, [otpVerificationSuccess])

    useEffect(() => {
        if (otpRequestSuccess) {
            setSuccessfulRefund(true);
        }
    }, [otpRequestSuccess])

    useEffect(() => {
        void getWalletAmount();
    }, [])


    return (
        <ModalComponent
            shouldModalOpen={showHideModal}
            visible={showModal}
            showFooter={false}
            okButtonText={'Request'}
            className={isMobile ? "refund-modal": ""}
            title={<Space direction="horizontal">
                <Refunds />
                <Text>Request Refund</Text>
            </Space>}
        ><>

                {<Formik initialValues={initialValues} onSubmit={() => console.log('hi')}>

                    {({ values }) => (
                        !successfulRefund ? <>

                            <Text type="secondary" className='secondary__text'>
                                Note: Refund amount should be less than or equal to your wallet
                                amount. Your wallet amount is {walletAmount}
                            </Text>
                            <Divider className={"no-color"} />
                            <br/>
                          
                            <InputField type="number" name="Refund" />
                           
                            <div className="modal-footer">
                                <Row>
                                    <Col>
                                    <ButtonBase
                                    type={ButtonTypes.PRIMARY}
                                    text='Request'
                                    block={false}
                                    loading={buttonLoading}
                                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                    onclick={async () => {
                                        console.log(values)
                                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                        await requestRefund({ refund_amount: values.RefundAmountKSh })
                                    }}
                                    disabled={values.Refund > walletAmount}
                                />
                                    </Col>
                                    <Col>
                                    <ButtonBase
                                    type={ButtonTypes.TERTIARY}
                                    text={'Cancel'}
                                    onclick={showHideModal}
                                    ClassName="cancel__button"
                                />
                                    </Col>
                                </Row>
                               
                                
                            </div>
                        </> : <>
                            <>
                                <Text type="secondary" className='secondary__text'>
                                    Enter the OTP sent to your Registered Mobile Number
                                </Text>

                                {/* <Form className="login-form" > */}
                                <Divider className={"no-color"} />
                                <br/>
                                <InputField type="number" name="OTP" />
                                <div className="modal-footer">
                                    <Row>
                                        <Col>
                                        <ButtonBase
                                        loading={buttonLoading}
                                        type={ButtonTypes.PRIMARY}
                                        text='Verify OTP'
                                        block={false}
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onclick={async () => {
                                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                            await verifyOtp({ otp: values.OTP, refund_amount: values.Refund, })
                                        }}
                                        disabled={values.OTP.toString().length < 4}
                                    />
                                        </Col>
                                        <Col>
                                        <ButtonBase
                                        type={ButtonTypes.TERTIARY}
                                        text={'Cancel'}
                                        onclick={showHideModal}
                                        ClassName="cancel__button"
                                    />
                                        </Col>
                                    </Row>
                                   
                                   
                                </div>
                            </>
                        </>
                    )}
                </Formik>}

            </>
        </ModalComponent>
    )
}
