import { Col, Row, TableProps, Typography, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState, useEffect } from 'react';
import TableUpdated from 'shared/components/Table';
import ElectricitytokenService from 'services/ElectricitytokenService/ElectricityToken.service';

import Tags from 'shared/components/Tag';

import 'react-phone-number-input/style.css';
import { TagText } from 'shared/components/Cards';

import { TitleComponentPayments } from 'views/Financials/TitleComponentPayments';
import { useTitleContext } from 'context/TitleComponentContext';
import { ElectricityToken } from 'models/ElectricityToken/electricityToken.model';
import moment from 'moment';
import useResponsive from 'shared/Hooks/useResponsive';
import { userDetails } from 'shared/Hooks/user';
import { userCheckInStatus } from 'enums/userStatusTypes';
import { Electricity } from 'resources/Icons';
import ElectricityTokenModel from './ElectricityTokenModel';
import { usdollar } from 'views/Home';
import { DateFormats } from 'enums/dateFormat';

const { Title } = Typography;
type DataType = {
	key: React.Key;
	createdAt: string;
	tokenNum: string;
	mpesaRefNum: number;
	amount: number;
	electricityTokenStatus: 0 | 1 | 2;
};


const statusObj = {
	0: TagText.RequestInitiated,
	1: TagText.TokenGenerated,
	2: TagText.PaymentCompleted

}




const columns: ColumnsType<DataType> = [
	{
		title: 'Date',
		dataIndex: 'createdAt',
		sorter: true,
		render: (_, { createdAt }) => {
			const updatedDate = moment(createdAt).format(DateFormats.SECONDARY);
			return <>{updatedDate}</>;
		},

	},
	{
		title: 'Token Number',
		dataIndex: 'tokenNum',
		sorter: true,

	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		sorter: true,
		render:(text)=><>{usdollar.format(text)}</>
	},
	{
		title: 'Ref No',
		dataIndex: 'mpesaRefNum',
		sorter: true,


	},
	{
		title: 'Status',
		dataIndex: 'electricityTokenStatus',
		sorter: true,
		render: (_, { electricityTokenStatus }) => {
			return <Tags tagText={statusObj[electricityTokenStatus]} />
		}

	},
];

enum Columns {
	createdAt = 'created_at',
	amount = 'amount',
	tokenNum = 'token_number',
	mpesaRefNum = 'token_reference',
	electricityTokenStatus = 'token_status'
}

export enum SorterDirection {
	ascend = 'asc',
	descend = 'desc',
}
type ElectricityTokenExtended = Omit<ElectricityToken,'electricityTokenStatus'> & {electricityTokenStatus:0|1|2}


export const ElectricityTokenPurchase = () => {
	const [showModal, setShowModal] = useState(false);
	const showHideModal = () => setShowModal(!showModal);
	const { search, date } = useTitleContext();
	const { isMobile } = useResponsive();
	const { user } = userDetails();

	const {
		success,
		fetchElectricitytokens,
		ElectricityTokens,
		total,
		loading
	} = ElectricitytokenService();

	const [filters, setFilters] = useState<{ page?: number, sort_by?: Columns, sort_dir?: SorterDirection, year?: number, month?: number, search?: string }>();

	useEffect(() => {
		void fetchElectricitytokens()
	}, [])


	useEffect(() => {
		let year, month;
	
		if (date) {
			const parsedDate = moment(date);
			year = parsedDate.year();
			month = parsedDate.month() + 1;
		}
	
		const shallowCopy = {
			...filters,
			year: year ?? undefined,
        	month: month ?? undefined,
			search
		};
		
		setFilters(shallowCopy);
	
		if (!date) {
			void fetchElectricitytokens();
		}
	}, [date, search]);
	
	
	
	// change the filters
	const onChange: TableProps<DataType>['onChange'] = (
		pagination,
		filters,
		sorter,
		extra
	) => {
		// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
		// @ts-ignore
		setFilters({ page: pagination.current, sort_by: sorter.order !== undefined ? Columns[sorter.field] : null, sort_dir: SorterDirection[sorter.order] })
	};

	useEffect(() => {
		if (filters?.month !== undefined || filters?.year !== undefined || filters?.page !== undefined || filters?.search !== undefined || filters?.sort_by !== undefined || filters?.sort_dir !== undefined) {
			void fetchElectricitytokens(filters)
		}
	}, [filters])

	const { Text } = Typography;
	


	useEffect(() => {
		if (success) {
			setShowModal(false);
		}
	}, [success]);
	const MobileResponsiveCards = ({ data }: { data:ElectricityTokenExtended[]}) => {
		return <>{data?.map((item, index) => <Row key={index} align='middle' style={{ paddingBottom: '0.5rem' }}>
			<Col span={16} xs={15} xl={18}>
				<Row gutter={[16, 5]} justify="start">
				<Col span={24} className='electricity-token-mobile-name'>
						<Text>{item?.buildingName} | </Text>
						<Text className='electricity-token-mobile-amount'> {usdollar.format(item?.amount ?? 0)} KSh</Text>
						
					</Col>
					<Col span={24} className='electricity-token-mobile-number'>
						<Text type='secondary'>{item?.tokenNum}</Text>
					</Col>
				</Row>
			</Col>
			<Col span={8} xs={9} xl={6} >
				<Row align="middle" justify='end' gutter={[16, 5]}>
					<Col>
					{item.electricityTokenStatus !== undefined && <Tags tagText={statusObj[item?.electricityTokenStatus]} />}
		
					</Col>
					<Col>
						<Text type="secondary">{moment(item?.createdAt).format('DD-MM-yy')}</Text>
					</Col>

				</Row>

			</Col>
		</Row>
		)}

		</>
	}
	
	return (
		<>
			<Row className={isMobile ? "mobile_container_electricity" : "main-container"}>
				<Col span={24}>
					<ElectricityTokenModel showModal={showModal} showHideModal={showHideModal} />
					

					<Space direction="horizontal">
						<Electricity color="#65BCB0" />
						<Title level={4}>Electricity Token</Title>
					</Space>

					{isMobile ?<div style={{position: 'relative'}}>
						<TitleComponentPayments onclick={showHideModal} buttonText={	<Electricity color="white" />} placeholder='Search by tokens' />
						<MobileResponsiveCards data={ElectricityTokens as any} />
					</div> : <TableUpdated
						columns={columns}
						data={ElectricityTokens}
						title={<TitleComponentPayments onclick={showHideModal} buttonText={"Purchase Token"} buttonDisabled={user?.status?.name === userCheckInStatus.CheckedOut} placeholder='Search by token details' />}
						onchange={onChange}
						isLoading={loading}
						total={total}
					/>}

				</Col>
			</Row>
		</>
	);
};
