import React, { memo, useEffect, useState } from 'react';

import {
	Row,
	Col,
	Tag,
	Image,
	Typography,
	TableProps,
	Form,
	DatePicker,
	DatePickerProps,
	Space,
} from 'antd';
import {
	Calendar,
	RoomTypeCustom,
	DashboardTransation,
	CircleAddCustom,
	RightArrow,
} from 'resources/Icons';

import home from 'resources/images/home.png';
import cardOneBG from 'resources/images/cardOneBG.png';
import cardTwoBg from 'resources/images/cardTwoBg.png';
import cardThreeBg from 'resources/images/cardThreeBg.png';
import './home.scss';
import ButtonBase from 'shared/components/Button';
import { ColumnsType } from 'antd/lib/table';

import TableUpdated from 'shared/components/Table';
import { ButtonTypes } from 'enums/buttonTypes';

import Navbar from 'shared/components/Navbar';

import useResponsive from 'shared/Hooks/useResponsive';
import { TagTypes } from 'enums/tagTypes';
import ReferAFriend from 'shared/components/ReferAFriend';
import DashboardService from 'services/DashboardService/Dashboard.service';
import ModalComponent from 'shared/components/Modal';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { PaymentsForHome } from 'models/PaymentsLog/PaymentsForHome.model';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'routes/routeConstants/appRoutes';
import { TagText } from 'shared/components/Cards';
import Tags, { TagsHome } from 'shared/components/Tag';
import ReferAFriendModal from 'shared/components/ReferAFriend/ReferAFriendModal';
import RefundsModal from 'views/Financials/RefundsModal';
import RefundsService from 'services/RefundsService/refunds.service';

import { userDetails } from 'shared/Hooks/user';
import { userCheckInStatus } from 'enums/userStatusTypes';
import ElectricityTokenModel from 'views/ElectricityToken/ElectricityTokenModel';
import { electricityStatusObj } from 'enums/electricityTokenEnum';
import HomeLoader from './HomeLoader';
import PaymentsModal from 'views/Financials/PaymentsModal';
import MaintenanceModal from 'views/Maintenance/MaintenanceModal';
import { TicketsModelForDashboard } from 'models/Dashboard/ticketsmodelfordashboard.model';
import { capitalize } from 'shared/Helpers/helpers';
import { DateFormats } from 'enums/dateFormat';
import ProfileService from 'services/ProfileService/profile.service';


const { Text, Title } = Typography;
export const usdollar = new Intl.NumberFormat('en-US', {
	minimumFractionDigits: 2,
});

type DataType = {
	key: React.Key;
	invoiceName: string;
	dueDate: string;
	amount: string;
	amountPaid: string;
};

const columns: ColumnsType<DataType> = [
	{
		title: 'Transaction',
		dataIndex: 'invoiceName',
	},
	{
		title: 'Due Date',
		dataIndex: 'dueDate',
		render: ({ _, dueDate }) => {
			const updatedDate = moment(dueDate).format(DateFormats.SECONDARY);
			return <>{updatedDate}</>;
		},
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		className: 'text-right',
		render: (_, record) => {
			return <>{usdollar.format(Number(record.amount))}</>;
		},
	},
	{
		title: 'Amount Paid',
		dataIndex: 'amountPaid',
		className: 'text-right',
		render: (_, record) => {
			return <>{usdollar.format(Number(record.amountPaid))}</>;
		},
	},
];

const onChange: TableProps<DataType>['onChange'] = (
	pagination,
	filters,
	sorter,
	extra
) => {
	console.log('params', pagination, filters, sorter, extra);
};

const Home = () => {
	// const location = useLocation()
	const { isMobile, isTablet } = useResponsive();
	const [searchParams, setSearchParams] = useSearchParams();
	const [showElectricityToken, setShowElectricityToken] = useState(false);
	const showHideElectricityToken = () =>
		setShowElectricityToken(!showElectricityToken);
	const [checkoutModal, setCheckoutModal] = useState(false);
	const [modalDescription, setModalDescription] = useState<string>();
	const [date, setDate] = useState<string | undefined>();
	const [paymentsTableDate, setPaymentsTableDate] = useState<any>();
	const [makePayment, setMakePayment] = useState(false);
	const changeMakePayment = () => setMakePayment(!makePayment);
	const {
		fetchDashboards,
		loading,
		DashboardData,
		buttonLoading,
		tickets,
		changeCheckout,
		loadingPayments,
		fetchPayments,
		payments,
		getSkillShareAccess,
		checkIfUserPaymentSuccess
	} = DashboardService();
	const { walletAmount, getWalletAmount } = RefundsService();
	const { generalInfo, getGeneralProfileInfo } = ProfileService();
	const [paymentsModified, setPaymentsModified] = useState<{
		payments?: PaymentsForHome[];
		type: string;
	}>();
	const { user } = userDetails();
	const [showReferAFriendModal, setShowReferAFriendModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const showHideModal = () => setShowModal(!showModal);
	const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
	const [refundStatus, setRefundStatus] = useState<{ statusId?: number }>();
	const showHideMaintenanceModal = () =>
		setShowMaintenanceModal(!showMaintenanceModal);

	const changeReferAFriendModalState = () =>
		setShowReferAFriendModal(!showReferAFriendModal);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		void fetchDashboards();
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		// fetchPayments();
		void getWalletAmount();
		void getGeneralProfileInfo();
		const decision = searchParams.get('decision')
		if (decision) {
			void checkIfUserPaymentSuccess(decision);
			setSearchParams('')
		}

	}, []);

	useEffect(() => {
		const statusId = generalInfo?.user?.status_id;
		if (statusId) {
			setRefundStatus({ statusId });
		}
	}, [generalInfo]);


	const TicketStatus = {
		ACTIVE: 'Active',
		REOPENED: 'Re-opened',
		CLOSED: 'Closed',
	};

	const getTicketsOpen = () => {
		if (!tickets) {
			return 0;
		}

		const openTickets = tickets.filter(
			(ticket: TicketsModelForDashboard) => ticket.ticketStatus === TicketStatus.ACTIVE
		);

		const reopenedTotal = tickets
			.filter(
				(ticket: TicketsModelForDashboard) =>
					ticket.ticketStatus === TicketStatus.REOPENED
			)
			.reduce((acc, ticket) => acc + (ticket.total ?? 0), 0);

		const totalOpen =
			openTickets.reduce((acc, ticket) => acc + (ticket.total ?? 0), 0) +
			reopenedTotal;

		return totalOpen;
	};

	const getClosedTickets = () => {
		if (!tickets) {
			return 0;
		}

		const closedTicket = tickets.find(
			(ticket: TicketsModelForDashboard) => ticket.ticketStatus === TicketStatus.CLOSED
		);

		return closedTicket?.total ?? 0;
	};

	useEffect(() => {
		if (payments) {
			setPaymentsModified({
				payments: payments?.slice(0, 4),
				type: 'viewMore',
			});
			if (paymentsTableDate === undefined) {
				// setPaymentsTableDate({
				// 	dateString: moment(payments[0]?.dueDate).format('MMMM-yyyy')
				// });
			}
		}
	}, [payments]);

	// const showMore = () => {
	// 	setPaymentsModified({
	// 		payments,
	// 		type: 'viewLess',
	// 	});
	// };

	// const showLess = () => {
	// 	setPaymentsModified({
	// 		payments: payments?.slice(0, 5),
	// 		type: 'viewMore',
	// 	});
	// };

	const changeCheckoutCallback = () => {
		const params = {
			checkout_date: date,
			reason: modalDescription,
		};
		changeCheckout(params)
			.then((res) => {
				if (res === 200) {
					changeCheckoutModal();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		const splittedDate = paymentsTableDate?.dateString?.split('-');
		if (splittedDate) {
			const params = {
				month: moment().month(splittedDate[0]).format('M'),
				year: splittedDate[1],
			};
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			fetchPayments(params);
		} else {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			fetchPayments();
		}
	}, [paymentsTableDate]);

	const changeCheckoutModal = () => setCheckoutModal(!checkoutModal);
	const onChangeDate: DatePickerProps['onChange'] = (_, dateString) => {

		if (!dateString) {
			setDate(undefined);
			return;
		}
		setDate(dateString);
	};

	const onChangeDateForPaymensTable: DatePickerProps['onChange'] = (
		dateMoment,
		dateString
	) => {
		if (!dateString) {
			setPaymentsTableDate(undefined);
			return;
		}
		setPaymentsTableDate({
			moment: dateMoment,
			dateString,
		});
	};

	// Title component of the table
	const TitleComponent = () => (
		<Row align="middle" gutter={16} className="fullWidth">
			<Col span={9}>
				<Title level={4}>Invoice Summary</Title>
			</Col>
			<Col span={6} md={5}>
				{paymentsTableDate && (
					<Row gutter={[16, 16]} justify="start" align="middle">
						<Col>
							<DashboardTransation />
						</Col>
						<Col>
							<Text>{paymentsTableDate?.dateString}</Text>
						</Col>
						<Col>
							<Calendar color="#5A5B5D" />
						</Col>
					</Row>
				)}
			</Col>
			<Col span={8} md={6}>
				<DatePicker
					picker="month"
					style={{ width: '100%', background: '#F8F8F8' }}
					placeholder="Select month"
					value={paymentsTableDate?.moment}
					onChange={onChangeDateForPaymensTable}
					format={'MMMM-YYYY'}
				/>
			</Col>
			{/* This was previously there when we were limiting the data */}
			{/* {paymentsModified?.payments !== undefined &
			paymentsModified?.payments.length > 5 ? ( */}
			<Col span={3} className="view-all-container">
				<ButtonBase
					type={ButtonTypes.TERTIARY}
					text={
						paymentsModified?.type === 'viewMore' ? 'View All' : 'view less'
					}
					onclick={handleInvoiceViewAll}
				/>
			</Col>
			{/* ) : (
				<></>
			)} */}
		</Row>
	);

	const navigate = useNavigate();
	const handleMaintenanceClick = () => {
		navigate(`/app/${AppRoutes.MAINTENANCE}`);
	};

	const handleClickElectricty = () => {
		navigate(`/app/${AppRoutes.ELECTRICITY}`);
	};

	const handleInvoiceViewAll = () => {
		navigate(`/app/${AppRoutes.FINANCIALS}`);
	};

	const formatWithLeadingZero = (num: number): string => {
		return num < 10 ? `0${num}` : num.toString();
	};

	return (
		<>
			<RefundsModal showModal={showModal} showHideModal={showHideModal} isMobile={isMobile} />
			<ModalComponent
				shouldModalOpen={changeCheckoutModal}
				visible={checkoutModal}
				title="Change Checkout Date"
				okButtonText="Update"
				cancelButtonText="Cancel"
				onButtonOkClick={changeCheckoutCallback}
				buttonLoading={buttonLoading}
				className={isMobile ? "current-booking-modal" : ""}
			>
				<Form>
					<Form.Item>
						<Row>
							<Col span={24}>
								<label>
									<Text type="secondary">Checkout or Extended Date</Text>
								</label>
							</Col>
							<Col span={24}>
								<DatePicker
									picker="date"
									placeholder="month"
									onChange={onChangeDate}
									style={{ width: '100%' }}
								/>
							</Col>
						</Row>
					</Form.Item>

					<Form.Item>
						<Text type="secondary">Reason (Optional)</Text>
						<TextArea
							placeholder="Description"
							autoSize={{ minRows: 2, maxRows: 6 }}
							value={modalDescription}
							onChange={(e) => setModalDescription(e.target.value)}
						/>
					</Form.Item>
				</Form>
			</ModalComponent>
			<PaymentsModal
				makePayment={makePayment}
				changeMakePayment={changeMakePayment}
				isMobile={isMobile}
				dueAmount={DashboardData?.amountDue ?? 0}
				merchant={DashboardData?.building?.merchantId}
				callback={`${window.location.href}`}
			/>
			{!loading && DashboardData !== undefined ? (
				<Row style={{ background: '#fff' }}>
					{/* left part */}
					<Col
						span={isMobile ? 24 : 17}
						md={17}
						lg={19}
						xl={17}
						className={isMobile ? "fixed-column-mobile" : "fixedColumn h-100"}
					>
						{isMobile && (
							<Row justify="center">
								<Col span={22} className="mobile-top-card">
									<Row gutter={[32, 16]}>
										<Col span={24}>
											<Row align="middle">
												<Col md={5} xs={7}>
													<Image
														src={
															DashboardData.building?.imageUrl ??
															'https://images.unsplash.com/photo-1486325212027-8081e485255e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb'
														}
														className="homeCardMobileImage"
														preview={false}

													/>
												</Col>
												<Col md={13} xs={11}>
													<Text>{DashboardData.building?.name}</Text>
												</Col>
												<Col span={6}>
													<Tag color={TagTypes.SUCCESS}>
														{DashboardData.statusId?.name}
													</Tag>
												</Col>
											</Row>
										</Col>

										<Col span={24}>
											<Row className="mobile-top-card-booking">
												<Col span={7}>
													<Text>Booking ID</Text>
												</Col>
												<Col span={17}>
													<Text type="secondary">
														{DashboardData.bookingId}
													</Text>
												</Col>
											</Row>
											{/* <br /> */}
											<Row>
												<Col span={7}>
													<Text>{DashboardData.roomNumber}</Text>
												</Col>
												<Col span={17} md={8}>
													<Text type="secondary">{DashboardData.roomType}</Text>
												</Col>
											</Row>
											{/* <br /> */}
										</Col>
									</Row>
								</Col>
							</Row>
						)}
						<div
							className={isMobile ? 'welcome__container-mobile' : 'welcome__container'}
						>
							{!isMobile && (
								<Space direction="vertical">
									<Text className="primary__text" style={{ fontSize: '1rem' }}>
										Welcome
									</Text>
									<Row gutter={[20, 0]}>
										<Col>
											<Title level={3} className="primary__text">
												{user?.name ? capitalize(user?.name) : ""}
											</Title>
										</Col>
										<Col>
											<Tag className="tag__home__welcome">
												Customer ID: {user?.id}
											</Tag>
										</Col>
									</Row>
								</Space>
							)}
							<Row
								gutter={[{ xs: 20, md: 30, lg: 20, xl: 10, xxl: 20 }, 25]}
								className={isMobile ? "card__row-mobile" : "card__row"}
							>
								{/* Card ONE  */}
								<Col xs={24} md={10} xl={8} className="cardContainer">
									<Row
										justify="start"
										align="middle"
										className="cardWithGradient h-100"
										style={{
											backgroundImage: `url(${cardOneBG})`,
										}}
									>
										<Col span={24} md={24} xs={24}>
											<Row
												gutter={[0, 8]}
												className="text_first"
												justify="start"
												align='middle'
											>
												<Col md={24} xs={14} sm={14}>
													<Row>

														<Col span={24} className="mb-6">
															<Row
																gutter={[10, { sm: 8, md: 0, lg: 0, xl: 16 }]}
																justify="start"
																align="middle"
															>
																<Col>
																	<Title level={5} className="whiteTitle">
																		Current Booking
																	</Title>
																</Col>
															</Row>
														</Col>
														<Col span={24}>
															<Row justify="start">
																<Title
																	level={5}
																	className="whiteTitleCheckedIn"
																	style={{ color: '#FFF' }}
																>
																	{moment(DashboardData.checkin).format(
																		DateFormats.SECONDARY
																	)}
																	{' - '}
																	{moment(DashboardData.checkout).format(
																		DateFormats.SECONDARY
																	)}
																</Title>
															</Row>
														</Col>
													</Row>
												</Col>
												<Col md={24} xs={10} sm={10} className={isMobile ? "card__btn" : ""}>

													<ButtonBase
														type={ButtonTypes.SECONDARY}
														text="Extend Now"
														onclick={changeCheckoutModal}
														disabled={
															user?.status?.name ===
															userCheckInStatus.CheckedOut
														}
													/>

												</Col>
											</Row>
										</Col>
									</Row>
								</Col>

								{/* Card Two New */}
								<Col xs={24} sm={24} md={10} xl={8} className="cardContainer">
									<Row
										justify="start"
										align="middle"
										className="cardWithGradient h-100"
										style={{
											backgroundImage: `url(${cardTwoBg})`,
										}}
									>
										<Col span={16} md={24} xs={24}>
											<Row
												gutter={[0, 8]}
												className="text_first"
												justify="start"
												align={isMobile ? "middle" : 'top'}
											>
												<Col md={24} sm={14} xs={14}>
													<Row>
														<Col md={24}>
															<Row
																gutter={[10, { sm: 8, md: 0, lg: 0, xl: 16 }]}
																justify="start"
																align="middle"
															>
																<Col>
																	<Title level={5} className="whiteTitle">
																		Amount Due
																	</Title>
																</Col>
															</Row>
															<Row
																align="top"
																className="depositGap mb-7"
																style={{ marginTop: '-0.9375rem' }}
															>
																<Text
																	className="secondaryText"
																	style={{ fontSize: '.6875rem' }}
																>
																	Deposit:
																</Text>

																<Text
																	type="secondary"
																	className="secondaryText font-weight-regulr"
																	style={{ fontSize: '.6875rem' }}
																>
																	{usdollar.format(DashboardData.depositPaid ?? 0)}
																	{' KSh'}
																</Text>
															</Row>
														</Col>
														<Col span={24}>
															<Row gutter={[5, 5]} justify="start" align="bottom">
																<Col>
																	<Title
																		level={5}
																		className="whiteTitleCheckedIn"
																		style={{ color: '#FFF' }}
																	>
																		{usdollar.format(DashboardData.amountDue ?? 0)}{' '}
																		{'KSh'}
																	</Title>
																</Col>
															</Row>
														</Col>
													</Row>
												</Col>

												<Col span={24} sm={10} xs={10} className={isMobile ? "card__btn" : ""}>
													<ButtonBase
														type={ButtonTypes.SECONDARY}
														text="Pay Now"
														onclick={changeMakePayment}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>

								{/* Card three New */}
								<Col xs={24} sm={24} md={10} xl={8} className="cardContainer">
									<Row
										justify="start"
										align="middle"
										className="cardWithGradient"
										style={{
											backgroundImage: `url(${cardThreeBg})`,
											height: '100%',
										}}
									>
										<Col span={16} md={24} xs={24}>
											<Row
												gutter={[0, 8]}
												className="text_first"
												justify="start"
												align="middle"
											>

												<Col md={24} xs={14} sm={14}>
													<Row>

														<Col span={24} className="mb-6">
															<Row
																gutter={[10, { sm: 8, md: 0, lg: 0, xl: 16 }]}
																justify="start"
																align="middle"
															>
																<Col>
																	<Title level={5} className="whiteTitle">
																		Wallet
																	</Title>
																</Col>
															</Row>
														</Col>
														<Col span={24}>
															<Row gutter={[5, 5]} justify="start" align="bottom">
																<Col>
																	<Title
																		level={5}
																		className="whiteTitleCheckedIn"
																		style={{ color: '#FFF' }}
																	>
																		{usdollar.format(walletAmount ?? 0)} {'KSh'}
																	</Title>
																</Col>
															</Row>
														</Col>
													</Row>
												</Col>
												<Col md={24} xs={10} sm={10} className={isMobile ? "card__btn" : ""}>
													<ButtonBase
														type={ButtonTypes.SECONDARY}
														text="Request Refund"
														disabled={refundStatus?.statusId !== 7}
														onclick={() => showHideModal()}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
								{/* Card four New */}
								{user?.status?.name === userCheckInStatus.CheckedIn && (
									<Col xs={24} sm={24} md={10} xl={8} className="cardContainer">
										<Row
											justify="start"
											align="middle"
											className="cardWithGradient h-100"
											style={{
												background: '#DBF0FD',
											}}
										>
											<Col span={16} md={24} xs={24}>
												<Row
													gutter={[0, 8]}
													className="text_first"
													justify="start"
												>
													<Col span={24}>
														<Row
															gutter={[0, { sm: 8, md: 0, lg: 0, xl: 16 }]}
															justify="start"
															align="middle"
															style={{ display: 'flex' }}
														>
															{/*  */}
															<Col span={22}>
																<Row>

																	<Title
																		level={5}
																		className={isMobile ? "plus-btn" : "blackTitle maintenance-card-name font-weight-bold"}
																	>
																		Maintenance Ticket
																	</Title>

																	<div className={!isMobile ? "maintenance-card-add-btn" : ""} onClick={showHideMaintenanceModal}
																	>
																		<CircleAddCustom />
																	</div>
																</Row>

															</Col>

															<Col
																span={2}
																className="maintenance-view-btn"
																onClick={handleMaintenanceClick}
															>
																<RightArrow />
															</Col>
														</Row>
													</Col>
													<Col span={24}>
														{tickets !== undefined && (
															<Row gutter={[5, 5]} justify="start">
																<Col>
																	<Title level={1} className="ticket-closed">
																		{formatWithLeadingZero(
																			getClosedTickets() ?? 0
																		)}
																	</Title>
																</Col>
																<Col style={{ marginTop: '1.375rem' }}>
																	<Text className="ticket-closed-string">
																		Closed
																	</Text>
																</Col>
															</Row>
														)}
													</Col>
													<Col span={24}>
														{tickets !== undefined && (
															<Row gutter={[5, 5]} justify="start">
																<Col>
																	<Title level={1} className="ticket-open">
																		{formatWithLeadingZero(
																			getTicketsOpen() ?? 0
																		)}
																	</Title>
																</Col>
																<Col style={{ marginTop: '1.125rem' }}>
																	<Text className="ticket-closed-string">
																		Open
																	</Text>
																</Col>
															</Row>
														)}
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								)}

								<ElectricityTokenModel
									showModal={showElectricityToken}
									showHideModal={showHideElectricityToken}
									isMobile={isMobile}
								/>
								<MaintenanceModal
									showHideModal={showHideMaintenanceModal}
									showModal={showMaintenanceModal}
									isMobile={isMobile}
								/>
								{/* Card five new */}
								{user?.status?.name === userCheckInStatus.CheckedIn && (
									<Col xs={24} sm={24} md={14} xl={16} className="cardContainer">
										<Row
											className="cardWithGradient h-100"
											style={{
												background: '#DEF3F0',
											}}
											gutter={[0, 8]}
										>
											<Col span={24} className={isMobile ? "electricity-token-card" : "text"}>
												<Row gutter={[32, 0]}>
													<Col span={24} >
														<Row align="middle" className='electricity-token-first-row'>
															<Col span={14}>
																<Row>
																	<Col className={isMobile ? "electricity-token-card__name" : "electricity-token-name"}>
																		<Title level={5} className="blackTitle">
																			Electricity Token
																		</Title>
																	</Col>
																	<Col
																		className={!isMobile ? "electricity-token-add-btn" : ""}
																		onClick={showHideElectricityToken}
																	>
																		<CircleAddCustom />
																	</Col>
																</Row>
															</Col>


															<Col
																span={1}
																onClick={handleClickElectricty}
																className="electricity-redirection"
															>
																<RightArrow />
															</Col>
														</Row>
													</Col>
													{DashboardData.electricity ? (
														<Col span={24}>
															<Row align="middle">
																<Col
																	xxl={24}
																	span={24}
																	className="current-token-wrapper"
																>
																	<Row gutter={[8, 8]} justify="start">
																		<Col>
																			<Text className="current-token-string">
																				Current Token
																			</Text>
																		</Col>
																		<Col style={{ paddingRight: '.625rem' }}>
																			<Text className="current-token-value">
																				{DashboardData?.electricity?.id ??
																					' N/A '}
																			</Text>
																		</Col>
																		<Col className="current-token-status">
																			<TagsHome
																				tagText={
																					electricityStatusObj[
																					DashboardData.electricity?.status ??
																					0
																					]
																				}
																			/>
																		</Col>
																	</Row>
																</Col>
															</Row>
														</Col>
													) : (
														<>
															{' '}
															<Col span={24} className="no-token-string-col">
																<Row>
																	<Text>No tokens purchased yet</Text>
																</Row>
															</Col>
														</>
													)}
													{DashboardData.electricity && (
														<Col span={24}>
															<Row gutter={[5, 5]} justify="space-between">
																<Row
																	style={{ marginRight: '.3125rem' }}
																	justify="start"
																>
																	<Title level={2}>
																		{DashboardData?.electricity?.amount ?? 0}
																	</Title>
																	<Text className="electricity-amount-unit">
																		KSh
																	</Text>
																</Row>

																<Row className="mpesa-col " justify="end">
																	<Col>
																		<span className="mpesa-string">
																			Mpesa Ref No
																		</span>
																		<span className="mpesa-val">
																			{DashboardData.electricity?.mpesaRefNum ??
																				'Nil'}
																		</span>
																	</Col>
																	<Col></Col>
																</Row>
															</Row>
														</Col>
													)}
												</Row>
											</Col>
										</Row>
									</Col>
								)}
							</Row>
							<br />
							<br />
							{!isMobile && (
								<>
									<br />
									<Col span={22} md={24}>
										<TableUpdated
											columns={columns}
											data={paymentsModified?.payments}
											isLoading={loadingPayments}
											onchange={onChange}
											title={<TitleComponent />}
											positionForJumpers={['bottomRight']}
											className="tableTitleFullWidth table-home"
											showPagination={false}
										/>
									</Col>
								</>
							)}
						</div>
					</Col>

					{/* right part */}
					{!isMobile && (
						<Col span={7} md={7} lg={4} xl={7} className="right-part-home">
							{!isTablet ? (
								<>
									<Navbar />
								</>
							) : (
								<div style={{ padding: '4.8px' }}></div>
							)}


							<div className="">
								<Row style={{ width: '100%' }}
									gutter={[16, { xs: 5, md: 10, lg: 10, xl: 30, xxl: 40 }]}
									className='booking-status-container'
								>
									<Row className='p-2-sides w-100' justify="space-between">
										<Col span={12}>
											<Tags
												tagText={DashboardData.statusId?.name as TagText}
											/>
										</Col>
										<Col span={12}>
											<ButtonBase
												type={ButtonTypes.PRIMARY}
												text="Get skillshare access"
												onclick={() => {
													void getSkillShareAccess();
												}}
												loading={buttonLoading}
												disabled={
													DashboardData.skillShareAccess === 1 ||
													user?.status?.name === userCheckInStatus.CheckedOut
												}
												ClassName="skill-share-btn"
											/>
										</Col>
									</Row>
									<Row className="residence-refer-container p-2-sides" >
										<Col
											span={24}
											md={24}
											xl={9}
											xxl={9}
											className="residence-image-container"
										>
											<Image
												src={
													DashboardData.building?.imageUrl ??
													'https://images.unsplash.com/photo-1486325212027-8081e485255e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb'
												}
												className="homeCardImage"
												preview={false}
											/>
										</Col>
										<Col span={24} md={24} xl={14} xxl={14}>
											<Row justify="start" align="top">
												<Text className="booking__id__text">
													{DashboardData.bookingId}
												</Text>
											</Row>
											<Row
												justify="start"
												align="middle"
												style={{ height: '50%' }}
											>
												<Title level={5} className="home__name">
													{DashboardData.building?.name}
												</Title>
											</Row>
											<Row
												justify="start"
												align="top"
												className="residentpane-room-details"
											>
												<div
													style={{
														height: '19.2px',
														width: '19.2px',
														background: '#fff',
														borderRadius: '50%',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													<RoomTypeCustom />
												</div>

												<Text className="primary__text__home">
													{DashboardData.roomNumber}
												</Text>

												<Text
													type="secondary"
													className="secondary__text__home"
												>
													{DashboardData.roomType}
												</Text>
											</Row>
										</Col>
									</Row>
									<Row className="refer-friend-card mt-6 p-2-sides">
										<ReferAFriend onClick={changeReferAFriendModalState} />
									</Row>
								</Row>
								<ReferAFriendModal
									isModalVisible={showReferAFriendModal}
									changeModalState={changeReferAFriendModalState}
								/>
							</div>
							<Row className="sider-bottom-image">
								<Col span={24}>
									<Image src={home} preview={false} className="sider-image" />
								</Col>
							</Row>

						</Col>
					)}
				</Row>
			) : (
				<>
					<HomeLoader />
				</>
			)}
		</>
	);
};

export default memo(Home);
