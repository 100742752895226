import axios from 'axios';
// import { NotificationTypes } from 'enums/notificationTypes';
import { ApiRoutes } from '../routes/routeConstants/apiRoutes';
// import Notification from 'shared/components/Notification';
import LogoutUser from 'shared/components/Navbar/Helpers';

export const getHeaders = (): any => {
	const user = JSON.parse(localStorage.getItem('user_token') as string);

	// eslint-disable-next-line prefer-const
	const headers = {
		'Content-Type': 'application/json',
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		Authorization: `Bearer ${user}`,
	};
	return headers;
};


const axiosInstance = axios.create({
	baseURL: ApiRoutes.BASE_URL,
	timeout: 20000
});

axiosInstance.interceptors.request.use(function (config) {
	config.headers = getHeaders();
	return config;
});

axiosInstance.interceptors.response.use(
	(response): any => {
		return {
			data: response.data,
			message: response.statusText,
			status: response.status,
		};
	},
	async (error) => {
		const { response } = error;
		const user = JSON.parse(localStorage.getItem('user_token') as string);
		if (response.status === 401 && user) {
			window.location.href = '/';
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			// Notification({
			// 	message: '',
			// 	description: 'Access Denied',
			// 	type: NotificationTypes.ERROR,
			// });
			// do something here
			// remove the token as well as user
			localStorage.clear();
			LogoutUser();
			// void Logout();
		}
		return await Promise.reject(error);
	}
);

export default axiosInstance;

// export { AxiosInterceptor };
