import { RoomId } from '../RoomId/roomId.model';

import { StatusId } from '../StatusId/statusId.model';

import { BuildingId } from '../BuildingId/buildingId.model';

import { serializable, alias, object, primitive } from 'serializr';

export class Bookings {
	@serializable(alias('booking_id', primitive()))
	bookingId?: number;

	@serializable(alias('room_type', primitive()))
	roomType?: string;

	@serializable(alias('booked_checkin_date', primitive()))
	bookedCheckinDate?: string;

	@serializable(alias('booked_checkout_date', primitive()))
	bookedCheckoutDate?: string;

	@serializable(alias('actual_checkin_date', primitive()))
	actualCheckinDate?: string;

	@serializable(alias('actual_checkout_date', primitive()))
	actualCheckoutDate?: string;

	@serializable(alias('building_id', object(BuildingId)))
	buildingId?: BuildingId;

	@serializable(alias('status_id', object(StatusId)))
	statusId?: StatusId;

	@serializable(alias('room_id', object(RoomId)))
	roomId?: RoomId;

	@serializable(alias('rent', primitive()))
	rent?: number;
}
