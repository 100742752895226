import { serializable, alias, primitive } from 'serializr';

export class WaitingList {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('residence', primitive()))
	residence?: string;

	@serializable(alias('room_type', primitive()))
	roomType?: string;

	@serializable(alias('room_number', primitive()))
	roomNumber?: string;

	@serializable(alias('actual_checkin_date', primitive()))
	actualCheckinDate?: string;

	@serializable(alias('actual_checkout_date', primitive()))
	actualCheckoutDate?: string;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('booking_id', primitive()))
	bookingId?: string;

	@serializable(alias('status', primitive()))
	status?: number;
}
