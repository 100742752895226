import React from 'react';
import { Row, Col, Skeleton } from 'antd';
export default function HomeBookings() {
    return (
        <Row className="skeletonRow" gutter={[60, 60]}>
            <Col span={8}>
                <Row gutter={[16, 16]}>
                  

                    <Skeleton paragraph={true} />
                </Row>


            </Col>

            <Col span={12}>
                <Row gutter={[16, 16]}>
                  

                    <Skeleton paragraph={true} />
                </Row>
            </Col>
            <Col span={8}>
                <Row gutter={[16, 16]}>
                  

                    <Skeleton paragraph={true} />
                </Row>
            </Col>
            <Col span={12}>
                <Row gutter={[16, 16]}>
                  

                    <Skeleton paragraph={true} />
                </Row>
            </Col>
        </Row>
    );
}
