import { ElectricityToken } from 'models/ElectricityToken/electricityToken.model';
import React, { createContext, ReactNode, useState } from 'react';
const ElectricityTokenContext = createContext<any>(null);


export function ElectricityTokenProvider({ children }: { children: ReactNode }): any {

    const [electricityTokenProvider, setElectricityTokenProvider] = useState<ElectricityToken[]>([])



    return (
        <ElectricityTokenContext.Provider
            value={{
                electricityTokenProvider,
                setElectricityTokenProvider
            }}
        >
            {children}
        </ElectricityTokenContext.Provider>
    );
}

export const useElectricityTokenContext = (): any => React.useContext(ElectricityTokenContext);
