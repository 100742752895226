import { Col, Row } from 'antd';
import { ButtonTypes } from 'enums/buttonTypes'
import React from 'react';
import ButtonBase from '../Button'
import './referafriend.scss';

type modalOpenProps = {
	onClick?: any;
};
export default function ReferAFriend({ onClick }: modalOpenProps) {
	return (
		<>
			<Row
				className="notice-row cursor-pointer"
				align="top"
				gutter={[0, 24]}
			>
				<Col span={24} xl={13} xxl={11} className='refer-friend-content'>
					<Row justify="start" align="top">
						<h4 className="white_100 mobile__small">
							Get some exciting offers by referring your friend
						</h4>
						<ButtonBase
							type={ButtonTypes.SECONDARY}
							text="Refer A Friend"
							onclick={onClick}
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
}
