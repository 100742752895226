import { Data } from '../Data/appnotifications.model';

import { serializable, alias, object, primitive } from 'serializr';

export class AppNotifications {
	@serializable(alias('id', primitive()))
	id?: string;

	@serializable(alias('type', primitive()))
	type?: string;

	@serializable(alias('data', object(Data)))
	data?: Data;

	@serializable(alias('read_at', primitive()))
	readAt?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;
}
