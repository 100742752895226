import { Form, Input, Select, Row, Col, Typography } from "antd";
import React, { useEffect, useState } from "react";
import DashboardService from "services/DashboardService/Dashboard.service";
import ModalComponent from "shared/components/Modal";
import { usdollar } from "views/Home";
import { InputFieldForEmail } from "shared/components/InputField";
import { SolidArrow } from "resources/Icons";
import { useCountriesAndSalutations } from "shared/Hooks/useCountriesAndSalutations";
import { countriesListType } from "context/ProfileContext";
import { PaymentMethodAndOptions } from "enums/paymentTypes";
import SelectInput from "shared/components/Select/SelectInput";

const { Option } = Select;

const paymentOptions = [
    { label: 'M-Pesa', value: PaymentMethodAndOptions.Mpesa },
    { label: 'Debit/Credit Card', value: PaymentMethodAndOptions.CreditCard }
]
const PaymentFor = [
    { label: 'Total Due Amount', value: PaymentMethodAndOptions.TotalDueAmount },
    { label: 'Other Amount', value: PaymentMethodAndOptions.CustomAmount }
]

 const PaymentsModal = ({ merchant,callback,makePayment, changeMakePayment, isMobile, dueAmount }: { merchant?:string,callback:string,makePayment: boolean, changeMakePayment: () => void, isMobile?: boolean, dueAmount?:number })=> {
    const [paymentFor, setPaymentFor] = useState<PaymentMethodAndOptions>()
    const { Text } = Typography;
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodAndOptions>(PaymentMethodAndOptions.Mpesa);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [dueAmountMain, setDueAmountMain] = useState(dueAmount);
    const [basicGeneralInfo, setBasicGeneralInfo] = useState<{
        phoneNumber?: string;
		callingCode?: string;
        countryCode?: number;
	}>({callingCode: "254"});
    const {
		countriesListProvider,
	}: {
		countriesListProvider: countriesListType;
	} = useCountriesAndSalutations();
    const { makePayments, makeCreditCardPayment,buttonLoading, DashboardData, fetchDashboards } = DashboardService();
    // const changeMakePayment = () => setMakePayment(!makePayment);

    useEffect(() => {
        console.log(callback)
        if (!DashboardData) {
            void fetchDashboards()
        }
    }, [])

    const putPayment = ({ bookingId,paymentOption=PaymentMethodAndOptions.Mpesa }: any) => {
        const params = {
            booking_id: bookingId,
            payment_for: paymentFor === PaymentMethodAndOptions.CustomAmount ? 1 : 2,
            payment_method: paymentOption,
            phone_number: `${basicGeneralInfo?.phoneNumber}`,
            to_be_paid: paymentAmount,
        }
        if(paymentOption===PaymentMethodAndOptions.CreditCard){
           const  creditCardParams={...params,merchant,callback}
           makeCreditCardPayment(creditCardParams).then(res=>{
            if (res === 200) {
                changeMakePayment();
            }
        }).catch(err => {
            console.log(err);
        })
        }else{
            makePayments(params).then(res => {
                if (res === 200) {
                    changeMakePayment();
                }
            }).catch(err => {
                console.log(err);
            })
        }
}

    useEffect(() => {
        const updatedDueAmount = paymentFor === PaymentMethodAndOptions.TotalDueAmount && dueAmount ? dueAmount : 0;
        setDueAmountMain(dueAmount);
        setPaymentAmount(updatedDueAmount);
    }, [paymentFor, dueAmount]);

    return <ModalComponent
        shouldModalOpen={changeMakePayment}
        visible={makePayment}
        title="Payment"
        okButtonText="Make Payment"
        cancelButtonText="Cancel"
        buttonLoading={buttonLoading}
        buttonDisabled={paymentAmount<=0}
        onButtonOkClick={() => putPayment({ bookingId: DashboardData?.id,paymentOption:paymentMethod })}
        className={isMobile ? "amount-due-modal": ""}
    >

        <Form>
            <Form.Item>
                <Text className="secondary__text">Payment For</Text>
                <SelectInput 
                className='homeSelect' placeholder="Select Payment Method" options={PaymentFor} onSelect={(e: PaymentMethodAndOptions) => setPaymentFor(e)} />
            </Form.Item>
            {paymentFor === PaymentMethodAndOptions.CustomAmount && (
                <Form.Item>
                <Text className="secondary__text">Amount</Text>
                <Input addonBefore="KES" placeholder='Enter Custom Amount' className='antWithAddonBefore' onChange={(e: any) => {
                    const amount = e.target.value;
                    setPaymentAmount(amount);
                }} />
            </Form.Item>
            )}
            <Form.Item>
                <Text className="secondary__text">Payment Using</Text>
                <SelectInput 
                className='homeSelect' placeholder="Select Payment Method" options={paymentOptions} onSelect={(e: PaymentMethodAndOptions) => setPaymentMethod(e)} value={paymentMethod} />
            </Form.Item>
            {paymentMethod!==PaymentMethodAndOptions.CreditCard&&(
            <Form.Item >
                <InputFieldForEmail 
                    placeholder="Enter phone number" 
                    onChange={(e: any) =>
                        setBasicGeneralInfo({
                        ...basicGeneralInfo,
                        phoneNumber: e.target.value,    
                        })
                    } 
                prefix={
                    <SelectInput
                        style={{ width: 70 }}
						listHeight={150}
						className="profile-select-phone-number short"
					    showSearch
                        filterOption={(input, option) => {
                            if (option?.children) {
                                return !!option.value
                                    ?.toString()
                                    ?.toLowerCase()
                                    .startsWith(input?.toLowerCase());
                            } else return false;
                        }}															
                        suffixIcon={<SolidArrow />}
                        dropdownStyle={{
                            minWidth: 300,
                            maxWidth: 500,
                        }}
                        defaultValue={basicGeneralInfo?.callingCode}
                        optionLabelProp="label"
                        onChange={(e: any) => {
                            const countryCodeSelected =
                                countriesListProvider.filter(
                                    (i) => i.name === e
                                )[0].id;
                            setBasicGeneralInfo({
                                ...basicGeneralInfo,
                                countryCode: countryCodeSelected,
                            });
                        }}
                        >
                            <>
                                {countriesListProvider
                                    ?.sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    ?.map((i, j) => {
                                        return (
                                            <Option
                                                value={i.name}
                                                key={j}
                                                label={i.calling_code}
                                            >
                                                {`${i.name}(+${i.calling_code})`}
                                            </Option>
                                        );
                                    })}
                            </>
                    </SelectInput>
                }
                />
            </Form.Item>
            )}

            <Row gutter={[16, 16]}>
                <Col span={10}>
                    <p>Total Due</p>
                    <h2>Payment</h2>
                </Col>
                <Col span={10}>
                    <p>{usdollar.format(Number(dueAmountMain))} KSh</p>
                    <h2>{usdollar.format(Number(paymentAmount))} KSh</h2>
                </Col>
            </Row>
        </Form>

    </ModalComponent>
}

export default PaymentsModal;