import { serializable, alias, primitive } from 'serializr';

export class Payments {
	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('transaction_date', primitive()))
	transactionDate?: string;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('payment_method', primitive()))
	paymentMethod?: string;

	@serializable(alias('id', primitive()))
	id?: string;
}
