import {
	Collapse,
	Row,
	Col,
	Typography,
	DatePicker,
	DatePickerProps,
} from 'antd';
import { Image } from 'shared/components/BackgroundImage/Image';
import React, { useState } from 'react';
import { NoticeCustom, ClockIcon, Building } from 'resources/Icons';
import NoDataAnimation from 'shared/components/Animations';
import Icon from '@ant-design/icons';

import { stripHtml } from 'string-strip-html';
// import { InputFieldWithIcon } from 'shared/components/InputField';
import { useData } from 'services/NoticesService/Notices.service';
// import { htmlToText } from 'html-to-text';
import ReactHtmlParser from 'react-html-parser';

// styles
import './notices.scss';
import NoticesSkeleton from './NoticesSkeleton';
import Navbar from 'shared/components/Navbar';
import useResponsive from 'shared/Hooks/useResponsive';
import moment from 'moment';
import { DateFormats } from 'enums/dateFormat';
import background from "resources/images/containerBackground.png"

const { Panel } = Collapse;
const { Text, Title } = Typography;

const getSubString = (text: string | any) => {
	if (text.length > 300) {
		return text.substring(0, 270);
	} else {
		return text;
	}
};

const convertText=(value:any)=>{
    return value.replace(
        /&lt;/gi,
        "<"
      ).replace(/&gt;/gi,
      ">").replace(
        /<\/font>/gi,'');

}

const Header: React.FC<{
	bodyText: string;
	value: string;
	activeKey: string;
	date: string;
	currentKey: string;
	isMobile?:boolean;
}> = ({ bodyText, value, activeKey, date, currentKey, isMobile }) => {
	const noticeDate = moment(date).format('YYYY-MM-DD');
	const text = stripHtml(bodyText).result;

	return (
		<Row gutter={32} style={{ width: '100%' }}>
			<Col span={15} xs={15} md={15} xl={15} >
				<Row gutter={isMobile ? 6 : 16} align="middle">
					<Col>
						<Icon
							component={() => <Building color="#65BCB0" />}
							className={'notices'}
						/>
					</Col>
					<Col xs={11} xl={8}>
						<Title level={5}  className={ isMobile ? "primary__text notice-header" : "primary__text"}>
							{value}
						</Title>
					</Col>
					<Col>
					<Title level={4} className={isMobile ? "notice-header-collapse": "primary__text_color"}>
							Collapse
						</Title>
						</Col>
				</Row>
			</Col>
			<Col span={9} xs={9} md={9} xl={9}>
				<Row gutter={isMobile ? 3 : 12} justify="end" align='middle'>
					<Col>
						<ClockIcon />
					</Col>
					<Col>
						<Text type="secondary">
							{moment(noticeDate).format(DateFormats.SECONDARY)}
						</Text>
					</Col>
				</Row>
			</Col>

			{currentKey !== activeKey && (
				<Col>
					<p className="lineClamp">
						{getSubString(text)}
						{text.length > 300 && (
							<span className="viewMore">...View more</span>
						)}
					</p>
				</Col>
			)}
		</Row>
	);
};

export const Notices: React.FC = () => {
	const [activeKey, setActiveKey] = useState<string>('-1');
	const [month, setMonth] = useState<number | undefined>();
	const [year, setYear] = useState<number | undefined>();
	const { isMobile } = useResponsive();
	const { noticesData, isLoading } = useData({ month, year });

	

	const getActiveKey = (event: string | string[]) => {
		setActiveKey(event?.toString() ?? '-1');
		// if (event !== null) {
		// 	setActiveKey(event.toString());
		// } else {
		// 	setActiveKey('-1');
		// }
	};

	const onChangeDate: DatePickerProps['onChange'] = (_, dateString) => {
		if (!dateString) {
			setMonth(undefined);
			setYear(undefined);
		} else {
			const parsedDate = moment(dateString);
			const year = parsedDate.year();
			const month = parsedDate.month() + 1; 
			setYear(year)
			setMonth(month);
		}
		// setDate(dateString);
	};

	return (
		<>
			<>
				{!isMobile && (
					<Image
						src={background}
					>
						<Navbar />
					</Image>
				)}
			</>

			<Row className={isMobile ? "main-container-notices-mobile" : "main-container-notices"}>
				<Col span={24}>
					<Row gutter={15} justify="space-between" style={{ width: '100%' }}>
						<Col span={18} xs={12} xl={18} xxl={20}>
							{/* <Row gutter={12}>
								<Col> */}
							{/* <Space direction="horizontal"> */}
							<Row>
								<Col>
									<Icon component={() => <NoticeCustom color="#65BCB0" />} />
								</Col>
								<Col className="mt-col">
									<Title level={4} className="primary__text">
										Notice
									</Title>
								</Col>
							</Row>

							{/* </Space> */}

							{/* </Col>
								<Col> */}

							{/* </Col>
							</Row> */}
						</Col>
						<Col span={6} xs={12} xl={6} xxl={4}>
							<Row justify="end">
								<DatePicker
									picker="month"
									className="notices"
									placeholder="Select month"
									onChange={onChangeDate}
									disabledDate={(current) =>
										current.isAfter(moment().subtract(1, 'day'))
									}
									format={(value) => moment(value).format('MMM YYYY')}
								/>
							</Row>
						</Col>
					</Row>
				</Col>

				{!isLoading ? (
					<>
						<Col>
							{noticesData !== null ? (
								<Collapse
									defaultActiveKey={activeKey}
									onChange={getActiveKey}
									accordion
									ghost
									className="notices"
								>
									{noticesData?.map((val, index) => {
										return (
											<Panel
												header={
													<>
														<Header
															currentKey={`${index}`}
															value={val.name ?? ''}
															activeKey={activeKey ?? '1'}
															date={val.createdAt ?? ''}
															bodyText={val.description ?? ''}
															isMobile={isMobile}
														/>
													</>
												}
												key={index}
												showArrow={false}
											>
												<div
													style={{
														boxSizing: 'border-box',
														padding: '2rem',
														wordBreak: 'break-word',
													}}
													className={isMobile ? "notice-container-mobile": "notice-container"}
												>
													{ReactHtmlParser(convertText(val?.description ?? ''))}
													{/* // val.description.replace( /(<([^>]+)>)/ig, '')}
														} */}
												</div>
											</Panel>
										);
									})}
								</Collapse>
							) : (
								<>
									<NoDataAnimation />
								</>
							)}
						</Col>
					</>
				) : (
					<NoticesSkeleton />
				)}
			</Row>
		</>
	);
};
