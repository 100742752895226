import { VisitorValue } from './VisitorValue.model';
import { serializable, alias, object, primitive } from 'serializr';

export class VisitorInvites {
	@serializable(alias('id', primitive()))
	id?: string;

	@serializable(alias('value', object(VisitorValue)))
	value?: VisitorValue;
}
