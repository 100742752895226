import { Col, Row, TableProps, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { InputFieldWithIcon } from 'shared/components/InputField';
import TableUpdated from 'shared/components/Table';
import { SearchOutlined } from '@ant-design/icons';
import VisitorLogsService from 'services/VisitorlogsService/VisitorLogs.service';
import Tags from 'shared/components/Tag';
import { TagText } from 'shared/components/Cards';
// import ModalSkeleton from 'shared/components/ModalSkeleton';
// import Animations from 'shared/components/Animations';
import moment from 'moment';
import { VisitorDetails } from 'models/VisitorDetails/visitorDetails.model';
import { TableSkeleton } from 'shared/components/Table/TableSkeleton';
import useResponsive from 'shared/Hooks/useResponsive';
import { userDetails } from 'shared/Hooks/user';

const { Text } = Typography;
type DataType = {
	key: React.Key;
	name: string;
	checkinDate: number;
	checkoutDate: number;
	status: TagText;
};

const columns: ColumnsType<DataType> = [
	{
		title: 'Guest Name',
		dataIndex: 'name',
		sorter: (a, b) => a.name.localeCompare(b.name),
	},
	{
		title: 'Checkin Time',
		dataIndex: 'checkinDate',
		sorter: (a, b) =>
			moment(a.checkinDate).unix() - moment(b.checkinDate).unix(),

		render: (_, { checkinDate }) => {
			return <p>{moment(checkinDate).format('DD-MMM-yy hh:mm a')}</p>;
		},
	},
	{
		title: 'Checkout Time',
		dataIndex: 'checkoutDate',
		sorter: (a, b) =>
			moment(a.checkoutDate).unix() - moment(b.checkoutDate).unix(),
		render: (_, { checkoutDate }) =>
			checkoutDate !== undefined ? (
				<p>{moment(checkoutDate).format('DD-MM-yy hh:mm a')}</p>
			) : (
				<></>
			),
	},
	{
		title: 'Status',
		dataIndex: 'status',
		sorter: (a, b) => a.status.localeCompare(b.status),
		render: (_, { status }) => {
			return <Tags tagText={status} />;
		},
	},
];

const onChange: TableProps<DataType>['onChange'] = (
	pagination,
	filters,
	sorter,
	extra
) => {
	console.log('params', pagination, filters, sorter, extra);
};

export const VisitorLogs = (): JSX.Element => {
	const [searchTerm, setSearchTerm] = useState();
	const { VisitorLogs, fetchVisitorlogs, loading } = VisitorLogsService();
	const [logs, setLogs] = useState<VisitorDetails[]>();
	const { isMobile } = useResponsive();
	const { user } = userDetails();
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	function debounce<F extends (...params: any[]) => void>(
		fn: F,
		delay: number
	) {
		let timeoutID: number = 0;
		return function (this: any, ...args: any[]) {
			clearTimeout(timeoutID);
			timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
		} as F;
	}

	const processChange = debounce((e) => onSearchChange(e), 300);

	const onSearchChange = (e: any) => {
		setSearchTerm(e);
	};

	const MobileResponsiveCards = ({
		data,
		loading,
	}: {
		data: any | undefined;
		loading: boolean;
	}) => {
		return loading ? (
			<TableSkeleton />
		) : (
			<div style={{ paddingBottom: '1rem' }}>
				{data?.map((item: any, index: any) => (
					<Row key={index} align="middle" style={{ paddingBottom: '0.5rem'}} className={"mobile-responsive-section-log"}>
						<Col span={15}>
							<Row gutter={[16, 5]} justify="start" style={{marginTop:"10px"}}>
								<Col span={24}>
									<Text>{item?.name}</Text>
								</Col>
								<Col span={24}>
									<Text type="secondary">
										In: {moment(item.checkinDate).format('DD-MMM-yy')}
									</Text>
								</Col>
							</Row>
						</Col>
						<Col span={9}>
							<Row justify="end" align="middle" gutter={[16, 5]}>
								<Col>
									<Tags tagText={item.status as TagText} />
								</Col>
								<Col>
									<Text type='secondary'>Out: {moment(item.checkoutDate).format('DD-MMM-yy')}</Text>
								</Col>
								
							</Row>
						</Col>
					</Row>
				))}
			</div>
		);
	};
	useEffect(() => {
		if (searchTerm !== undefined) {
			const search: any = searchTerm;
			let newlogs;
			if (/^[A-Za-z]/i.test(searchTerm)) {
				// try the search first with the name
				newlogs = VisitorLogs.filter((i) =>
					i.name.toLowerCase().includes(search?.toLowerCase())
				);
				if (newlogs.length <= 0) {
					// if the name yields nothing , try with the status code
					newlogs = VisitorLogs.filter((i) =>
						i.status.toLowerCase().includes(search?.toLowerCase())
					);
				}
			} else {
				newlogs = VisitorLogs.filter((i) => i.checkinDate.includes(search));
				if (newlogs.length < 0) {
					newlogs = VisitorLogs.filter((i) => i.checkoutDate.includes(search));
				}
			}

			setLogs(newlogs);
		} else {
			setLogs(VisitorLogs);
		}
	}, [searchTerm]);
	useEffect(() => {
		setLogs(VisitorLogs);
		// console.log(VisitorLogs);
	}, [VisitorLogs]);

	// Title component of the table
	const TitleComponent = ({
		changeEvent,
	}: {
		changeEvent: any;
	}): JSX.Element => {
		return (
			<Row
				align="middle"
				gutter={12}
				key={'hi'}
				style={
					isMobile
						? { position: 'absolute', top: '-6rem', width: '100vw' }
						: undefined
				}
			>
				<Col span={16}>
					<InputFieldWithIcon
						type="text"
						name="search"
						prefix={<SearchOutlined />}
						placeholder="Search by guest details"
						allowClear={true}
						onChange={(e: any) => changeEvent(e.target.value)}
					/>
				</Col>
			</Row>
		);
	};

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		fetchVisitorlogs();
	}, []);

	const NewTitleComponent = useMemo(() => <TitleComponent changeEvent={processChange}/>, [user]);

	return isMobile ? (
		<>
			{NewTitleComponent}
			<MobileResponsiveCards loading={loading} data={logs} />
		</>
	) : loading ? (
		<TableSkeleton />
	) : (
		<TableUpdated
			columns={columns}
			data={logs}
			title={NewTitleComponent}
			onchange={onChange}
			isLoading={loading}
		/>
	);
};
