import { useState } from 'react';
import { deserialize } from 'serializr';
// import { generatePath } from 'react-router-dom';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Payments as PaymentsModel } from 'models/PaymentsLog/paymentslog.model';
import { Pagination } from './../../models/Pagination/pagination.model';

const PaymentsService = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const [BookingsPagination, setBookingsPagination] =
		useState<Pagination | null>();

	const [Payments, setPayments] = useState<PaymentsModel[]>();
	const [pages, setPages] = useState<number>();

	const fetchPayments = async (params?: {
		search?: string;
		sort_by?: string;
		sort_dir?: string;
		page?: number;
		month?: number;
		year?: number;
	}) => {
		try {
			setLoading(true);
			setButtonLoading(true);
			const response = await axiosInstance.get(ApiRoutes.PAYMENTS, { params });
			const payments = deserialize(PaymentsModel, response.data.data.data);
			setPayments(payments as PaymentsModel[]);

			setPages(response.data.data.total);
			if (typeof Payments !== 'undefined') {
				const pagination = deserialize(Pagination, response.data.meta);
				setBookingsPagination(pagination);
			}
		} catch (error) {
			setBookingsPagination(null);
			setPayments([]);
		} finally {
			setLoading(false);
			setButtonLoading(false);
		}
	};

	const downloadPaymentReceipt = async (params: { id: any }) => {
		try {
			// setDownloadInvoiceDisabled(true);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			axiosInstance
				.get(`${ApiRoutes.DOWNLOAD_PAYMENT_RECEIPT}${params.id}`, {
					responseType: 'blob',
				})
				.then((response) => {
					// setDownloadInvoiceDisabled(false);
					window.open(URL.createObjectURL(response.data));
				});
		} catch (error) {
			// setDownloadInvoiceDisabled(false);
		} finally {
			console.log('error');
		}
	};

	// const createBookings = async (Bookings: Bookings) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { Bookings: serialize(Bookings, Bookings) }
	//         const response = await axiosInstance.post(ApiRoutes.BOOKINGS, payload)
	//         const data = deserialize(Bookings, response.data["bookings"]) as Bookings;
	//         setBookings(data)
	//     } catch (error) {
	//         setBookings(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const updateBookings = async (Bookings: Bookings) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { Bookings: serialize(Bookings, Bookings) }
	//         const API_URL = generatePath(ApiRoutes.BOOKINGS_SPECIFIC, { BookingsId: String(Bookings.id) })
	//         const response = await axiosInstance.put(API_URL, payload)
	//         const data = deserialize(Bookings, response.data["bookings"]) as Bookings;
	//         setBookings(data)
	//     } catch (error) {
	//         setBookings(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const deleteBookings = async (BookingsId: string) => {
	//     try {
	//         setButtonLoading(true)
	//         const API_URL = generatePath(ApiRoutes.BOOKINGS_SPECIFIC, { BookingsId })
	//         await axiosInstance.delete(API_URL)
	//         return true
	//     } catch (error) {
	//         return false
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const showBookings = async (BookingsId: string) => {
	//     try {
	//         setLoading(true)
	//         const API_URL = generatePath(ApiRoutes.BOOKINGS_SPECIFIC, { BookingsId })
	//         const response = await axiosInstance.get(API_URL)
	//         const data = deserialize(Bookings, response.data["bookings"]) as Bookings;
	//         setBookings(data)
	//     } catch (error) {
	//         setBookings(null)
	//     } finally {
	//         setLoading(false)
	//     }
	// }

	return {
		pages,
		loading,
		Payments,
		buttonLoading,
		fetchPayments,
		BookingsPagination,
		downloadPaymentReceipt,
	};
};

export default PaymentsService;
