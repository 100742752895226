import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
	Row,
	Col,
	Typography,
	Divider,
	Select,
	Button,
	Form,
	Input,
	DatePicker,
	Tag,
	Space,
} from 'antd';
import ButtonBase from 'shared/components/Button';

import {
	KnowYourCommunityIcon,
	RefundIcon,
	GuarantorIcon,
	SolidArrow,
	GuarantorWarning,
} from 'resources/Icons';
import AvatarComponent from 'shared/components/Avatar';
import { InputFieldForEmail } from 'shared/components/InputField';
import { InputWithPrefix } from 'shared/components/InputField/InputWithPrefix';

import { ButtonTypes } from 'enums/buttonTypes';
import ProfileService from 'services/ProfileService/profile.service';

import 'react-phone-number-input/style.css';
import './profile.scss';

import useResponsive from 'shared/Hooks/useResponsive';
import Navbar from 'shared/components/Navbar';
import { Image } from 'shared/components/BackgroundImage/Image';
import ModalComponent from 'shared/components/Modal';
import moment from 'moment';
import KnowyourcommunityService from 'services/KnowyourcommunityService/knowYourCommunity.service';
import { countriesListType, salutationListType } from 'context/ProfileContext';
import { User } from 'models/user.model';
import EnableDisableSwitch from '../EnableDisableSwitch';
import { useCountriesAndSalutations } from 'shared/Hooks/useCountriesAndSalutations';
import { capitalize } from 'shared/Helpers/helpers';
import background from "resources/images/containerBackground.png"
import SelectInput from 'shared/components/Select/SelectInput';

// import { Formik, Form, ErrorMessage } from 'formik';
// import { guarantorValidationSchema } from './GuarantorValidation';

const { Option } = Select;

type TitleAndTextProps = {
	title: string;
	text: string | number | any[] | undefined;
};
const { Title, Text } = Typography;
const Profile = () => {
	const dataFetchedRef = useRef(false);
	const [showGeneralInformation, setShowGeneralInformation] = useState(true);
	const [showGuarantorInformation, setShowGuarantorInformation] =
		useState(true);

	const [showRefundDetails, setShowRefundDetails] = useState(true);
	const [prefix, setPrefix] = useState<string>();
	const [verifyOTPModal, setVerifyOTPModal] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const [user, setUser] = useState<User>();

	const [interestsListUpdated, setInterestsListUpdated] = useState<any[]>([]);
	const {
		salutationListProvider,
		countriesListProvider,
	}: {
		salutationListProvider: salutationListType;
		countriesListProvider: countriesListType;
	} = useCountriesAndSalutations();
	const {
		fetchGuardianDetails,
		guardianDetails,
		getGeneralProfileInfo,
		buttonLoading,
		updateGeneralInfo,
		yearOfStudyList,
		verifyOTP,
		uploadProfilePicture,
		sendOTP,
		showLoadingForOtp,
		currienciesList,
		updateRefundDetails,
		generalInfo,
		getProfileRelatedData,
		institutionList,
		updateGuarantor,
	} = ProfileService();
	const {
		userInterests,
		getUserInterests,
		fetchAllInterests,
		interestListFlattened,
		updateInterests,
		buttonLoading: kycButtonLoading,
	} = KnowyourcommunityService();
	const { isMobile } = useResponsive();

	const [otp, setOtp] = useState<string>();
	const [basicGeneralInfo, setBasicGeneralInfo] = useState<{
		fullname?: string;
		dateOfBirth?: any;
		phoneNumber?: any;
		yearOfStudy?: any;
		mailId?: string;
		institution?: any;
		nationality?: any;
		phoneNumberChanged?: boolean;
		countryCode?: number;
		callingCode?: string;
		profilePicture?: string;
		isGeneralInfoEdited?: boolean;
		isInterestsUpdated?: boolean;
	}>();
	useEffect(() => {
		if (userInterests) {
			setInterestsListUpdated(userInterests);
		}
	}, [userInterests]);

	const [guarantorInfo, setGuarantorInfo] = useState<{
		name?: string;
		mobileNumber?: number;
		userId?: string;
		id?: number;
		email?: string;
		isGuarantorEdited?: boolean;
		countryCode?: number;
		callingCode?: string;
	}>();

	enum BankAccountType {
		InternationalBankAccount = 'InternationalBankAccount',
		Mpesa = 'Mpesa',
		KenyanBankAccount = 'KenyanBankAccount',
	}

	const showHideVerifyOTPModal = () => setVerifyOTPModal(!verifyOTPModal);

	const [refundDetails, setRefundDetails] = useState<{
		accountHolderName?: string;
		accountType?: BankAccountType;
		accountName?: string;
		accountNumber?: string;
		bankCode?: string;
		bankName?: string;
		branchCode?: string;
		country?: any;
		creditCurrency?: string;
		swiftCode?: string;
		isRefundEdited?: boolean;
	}>();

	const prefixDataForName = salutationListProvider ?? ['Mr', 'Ms'];
	const getInitialData = useMemo(
		() => async () => {
			await fetchGuardianDetails();

			await getGeneralProfileInfo();

			await getProfileRelatedData();
			await getUserInterests();
			await fetchAllInterests();
		},
		[]
	);

	const updateUserInterests = async () => {
		const data = interestsListUpdated?.map((i: any) => {
			return { name: i.name, type: i.type_id };
		});

		const params = {
			interests: data,
		};
		await updateInterests(params);
	};
	const tagRender = (props: any) => {
		// eslint-disable-next-line react/prop-types
		const { label, value, closable, onClose } = props;
		const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				color={value}
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				className="tagWithGreyTextForProfile"
			>
				{label}
			</Tag>
		);
	};
	useEffect(() => {
		if (dataFetchedRef.current) return;
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		getInitialData();
		dataFetchedRef.current = true;
		const userid = JSON.parse(localStorage.getItem('user') as string);

		setUser(userid ?? '');
	}, []);
	useEffect(() => {
		if (generalInfo) {
			// const date = moment(generalInfo.birth_date, 'YYYY-MM-DD').toDate();
			setBasicGeneralInfo({
				fullname: generalInfo?.first_name,
				dateOfBirth: generalInfo?.birth_date,
				phoneNumber: `${generalInfo?.mobile_number}`,
				yearOfStudy: generalInfo?.year_of_study,
				mailId: generalInfo?.email,
				institution: generalInfo?.college,
				nationality: generalInfo?.nationality,
				phoneNumberChanged: false,
				profilePicture: generalInfo?.profile_picture,
				countryCode: generalInfo?.user?.resident_account?.country_id,
				isGeneralInfoEdited: false,
				isInterestsUpdated: false,
				callingCode: generalInfo?.dial_code.toString(),
			});
		}
	}, [generalInfo]);

	useEffect(() => {
		if (generalInfo?.user.resident_account !== undefined) {
			setRefundDetails({
				accountHolderName:
					generalInfo.user.resident_account?.account_holder_name,
				accountType: generalInfo.user.resident_account
					?.account_type as BankAccountType,
				accountName: generalInfo.user.resident_account?.account_holder_name,
				branchCode: generalInfo.user.resident_account?.branch_code,
				country: generalInfo.user.resident_account?.country,
				creditCurrency: generalInfo.user.resident_account?.credit_currency,
				swiftCode: generalInfo.user.resident_account?.swift_code,
				bankCode: generalInfo.user.resident_account?.bank_code,
				accountNumber: generalInfo.user.resident_account?.account_number,
				isRefundEdited: false,
				bankName: generalInfo.user.resident_account?.bank_name,
			});
			// setBasicGeneralInfo({ ...generalInfo, countryCode: generalInfo.user.resident_account.country_id, })
		}
	}, [generalInfo?.user.resident_account]);

	useEffect(() => {
		if (guardianDetails !== undefined) {
			setGuarantorInfo({
				name: guardianDetails?.name,
				email: guardianDetails?.email,
				userId: guardianDetails.userId ?? '',
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				mobileNumber: guardianDetails?.mobileNumber,
				id: guardianDetails.id,
				isGuarantorEdited: false,
				countryCode: guardianDetails?.countryId,
				callingCode: guardianDetails?.callingCode,
			});
		}
	}, [guardianDetails]);

	const handleSelectChangeForUpdate = (i: any) => {
		setBasicGeneralInfo({ ...basicGeneralInfo, isInterestsUpdated: true });

		const newItems = [];
		for (const j of i) {
			const item = interestListFlattened?.find((k) => k.name === j);
			if (!item) {
				newItems.push({ name: j });
			} else {
				newItems.push(item);
			}
			// newItems.push(item);
		}
		setInterestsListUpdated(newItems);
	};

	const updateForRefund = async () => {
		const params = {
			account_holder_name: refundDetails?.accountHolderName,
			account_number: refundDetails?.accountNumber,
			account_type: refundDetails?.accountType,
			bank_code: refundDetails?.bankCode,
			bank_name: refundDetails?.bankName,
			branch_code: refundDetails?.branchCode,
			country_id: Number(refundDetails?.country?.id),
			credit_currency: refundDetails?.creditCurrency,
			swift_code: refundDetails?.swiftCode,
		};
		const response = await updateRefundDetails(params);
		if (response === 200) {
			setShowRefundDetails(!showRefundDetails);
			setRefundDetails({ ...refundDetails, isRefundEdited: false });
		}
	};

	const DefaultPrefixSelector = () => {
		return salutationListProvider !== undefined ? (
			<SelectInput
				style={{ width: 70 }}
				onChange={(e) => setPrefix(e)}
				defaultValue={prefix ?? salutationListProvider[0].salutation}
				suffixIcon={<SolidArrow />}
			>
				{salutationListProvider.map((i) => {
					return <Option key={i.id}>{i.salutation}</Option>;
				})}
			</SelectInput>
		) : (
			<></>
		);
	};
	const updateProfilePicture = async (event: any) => {
		if (event !== undefined) {
			const params = {
				file: event,
			};
			const response = await uploadProfilePicture(params);
			if (response?.status === 200) {
				console.log(response.data.data);
				setBasicGeneralInfo({
					...basicGeneralInfo,
					profilePicture: response.data.data,
				});
			}
		} else {
			console.log(event);
		}
	};

	const TitleAndText = ({ title, text }: TitleAndTextProps) => {
		return (
			<>
				<Row gutter={[8, 8]}>
					<Col span={24}>
						<Text type="secondary" className="profile__secondary">
							{title}
						</Text>
					</Col>
					<Col span={24}>
						<Text className="profile__primary">{text}</Text>
					</Col>
				</Row>
			</>
		);
	};

	const updateGuarantoInfo = async () => {
		if (guarantorInfo) {
			const params = {
				email: guarantorInfo.email,
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				mobile_number: `${guarantorInfo.mobileNumber}`,
				name: guarantorInfo.name,
				userId: guarantorInfo.userId ?? '',
				countryCode: guarantorInfo.countryCode ?? undefined,
			};

			const res = await updateGuarantor(params);
			if (res === 200) {
				setShowGuarantorInformation(!showGuarantorInformation);
				setGuarantorInfo({ ...guarantorInfo, isGuarantorEdited: false });
			}
		}
	};

	const sendOtpToNewNumber = async () => {
		// const phoneNumberParsed = parsePhoneNumber(basicGeneralInfo?.phoneNumber)
		const params = {
			country_code: basicGeneralInfo?.countryCode?.toString() ?? '',
			mobile_number: basicGeneralInfo?.phoneNumber ?? '',
		};
		const response = await sendOTP(params);
		if (response === 200) {
			showHideVerifyOTPModal();
		}
	};

	const updateGeneralInformation = async () => {
		const params = {
			birth_date: basicGeneralInfo?.dateOfBirth ?? '',
			college: basicGeneralInfo?.institution.id ?? '',
			title: '1',
			nationality: basicGeneralInfo?.nationality.id ?? '',
			first_name: basicGeneralInfo?.fullname ?? '',
			year_of_study: basicGeneralInfo?.yearOfStudy.id ?? '',
		};
		if (
			basicGeneralInfo?.isInterestsUpdated &&
			basicGeneralInfo.isGeneralInfoEdited
		) {
			const response = await updateGeneralInfo(params);
			await updateUserInterests();
			if (response === 200) {
				setShowGeneralInformation(!showGeneralInformation);
				setBasicGeneralInfo({
					...basicGeneralInfo,
					isGeneralInfoEdited: false,
					isInterestsUpdated: false,
				});
			}
		} else if (
			basicGeneralInfo?.isInterestsUpdated &&
			!basicGeneralInfo?.isGeneralInfoEdited
		) {
			await updateUserInterests();
			setBasicGeneralInfo({ ...basicGeneralInfo, isInterestsUpdated: false });
		} else if (
			!basicGeneralInfo?.isInterestsUpdated &&
			basicGeneralInfo?.isGeneralInfoEdited
		) {
			const response = await updateGeneralInfo(params);
			// await updateUserInterests();
			if (response === 200) {
				setShowGeneralInformation(!showGeneralInformation);
				setBasicGeneralInfo({
					...basicGeneralInfo,
					isGeneralInfoEdited: false,
				});
			}
		}
	};


	const GeneralInfoHeader=({Icon,Button,text}:{Icon:()=>JSX.Element,Button:()=>JSX.Element,text:string}):JSX.Element=>{
		return <Row justify="center" align="middle">
		<Col xs={3} sm={3} md={2}>
			<Icon />
		</Col>
		<Col xs={17} sm={17} md={19}>
			<Title level={5} className="font-regular">
				{text}
			</Title>
		</Col>
		<Col span={3}>
			<Row justify="end" align='middle'>
				<Button/>
				</Row>
				</Col>
				</Row>
	}
	return (
		<>
			{!isMobile && (
				<Image
					src={background}
				>
					<Navbar />
				</Image>
			)}
			{isMobile && (
				<>
					<br />
					<br />
				</>
			)}
			<Row className="general_info_row_profile_components">
				{/* General information section */}
				<Col md={12} span={24} className="general_info_col_profile_components">
					{showGeneralInformation ? (
						<>
							<Row justify="center" align="middle">
								<Col xs={3} sm={3} md={2}>
									<KnowYourCommunityIcon />
								</Col>
								<Col xs={17} sm={17} md={19}>
									<Title level={5} className="font-regular">
										General Information
									</Title>
								</Col>
								<Col span={3}>
									<Row justify="end" align="middle">
										<ButtonBase
											type={ButtonTypes.TERTIARY}
											text="Edit"
											onclick={() =>
												setShowGeneralInformation(!showGeneralInformation)
											}
											ClassName="font-bold"
										/>
									</Row>
								</Col>
							</Row>
							<br />
							<Row justify={isMobile?"center":"start"} align="middle" style={{display:'flex'}}>
								<Col xs={12} sm={12} md={6} xxl={4}>
									<AvatarComponent
										firstName="johny"
										lastName="english"
										imageUrl={basicGeneralInfo?.profilePicture}
										isProfile={true}
									/>
								</Col>

								<Col xs={12} sm={12} md={16} xl={18}>
									<Row>

								<Col xs={20} sm={20} md={12} xl={10}>
									<TitleAndText
										title="Name"
										text={basicGeneralInfo?.fullname ? capitalize(basicGeneralInfo?.fullname) : ""}
									/>
								</Col>
								<Col xs={20}  sm={20} md={12} xl={10}>
									<TitleAndText title="User ID" text={user?.id} />
								</Col>
									</Row>
								</Col>
							</Row>
							<br />
							<Row gutter={[8, 8]}>
								<Col span={24}>
									<Row>
										<Col span={20}>
											<Text>Share My Contacts</Text>
										</Col>
										<Col span={4}>
											<Row justify="end">
												<EnableDisableSwitch disabled={true} />
											</Row>
										</Col>
									</Row>
								</Col>
								<Col span={24}>
									<Text type="secondary">
										By enabling, only your Name, Property Details and Interests
										will be shared publicly
									</Text>
								</Col>
							</Row>
							<Divider className="no-color-top" />
							<Row>
								<Col span={10}>
									<TitleAndText
										title="Date Of Birth"
										text={moment(basicGeneralInfo?.dateOfBirth).format(
											'DD MMM YYYY'
										)}
									/>
								</Col>
								<Col span={10} offset={2}>
									<TitleAndText
										title="Phone Number"
										text={`+${basicGeneralInfo?.callingCode}  ${basicGeneralInfo?.phoneNumber}`}
									/>
								</Col>
							</Row>
							<Divider className="no-color-top" />
							<Row>
								<Col span={10}>
									<TitleAndText
										title="Year Of Study"
										text={basicGeneralInfo?.yearOfStudy?.name}
									/>
								</Col>
								<Col span={10} offset={2}>
									<TitleAndText
										title="Email Address"
										text={basicGeneralInfo?.mailId}
									/>
								</Col>
							</Row>
							<Divider className="no-color-top" />
							<Row>
								<Col span={10}>
									<TitleAndText
										title="Institution"
										text={basicGeneralInfo?.institution?.name}
									/>
								</Col>
								<Col span={10} offset={2}>
									<TitleAndText
										title="Nationality"
										text={basicGeneralInfo?.nationality?.citizenship}
									/>
								</Col>
							</Row>

							<Divider className="no-color-top" />
							<Row>
								<Col span={24}>
									<TitleAndText
										title="Interests And Hobbies"
										text={
											interestsListUpdated.map((i) => i.name).join(', ') ?? [
												'cricket,hockey,reading,movie buff',
											]
										}
									/>
								</Col>
							</Row>
						</>
					) : (
						<>
							<GeneralInfoHeader text={"General Information"} Icon={()=><KnowYourCommunityIcon/>} Button={()=><ButtonBase
											type={ButtonTypes.PRIMARY}
											text={
												basicGeneralInfo?.isGeneralInfoEdited === true ||
												basicGeneralInfo?.isInterestsUpdated === true
													? 'Update'
													: 'Close'
											}
											loading={buttonLoading || kycButtonLoading}
											onclick={() => {
												if (
													basicGeneralInfo?.isGeneralInfoEdited === true ||
													basicGeneralInfo?.isInterestsUpdated === true
												) {
													void updateGeneralInformation();
												} else {
													setShowGeneralInformation(!showGeneralInformation);
												}
											}}
										/>}/>
							
							<br />
							<Row justify="start" align="middle">
								<Col
									span={10}
									xl={7}
									xxl={4}
									style={{
										alignItems: 'left',
										textAlign: 'left',
										justifyContent: 'left',
										display: 'flex',
									}}
								>
									<input
										id="fileInput"
										type="file"
										style={{ display: 'none' }}
										ref={inputRef}
										accept={'.jpeg,.png,.jpg,.heic'}
										onChange={(event) => {
											if (event.target.files) {
												// eslint-disable-next-line @typescript-eslint/no-floating-promises
												updateProfilePicture(event.target.files[0]);
											}
										}}
									/>
									<div
										style={{ position: 'relative' }}
										onClick={() => {
											// const fileInput = document.getElementById('fileInput');
											// ts-ignore
											inputRef?.current?.click();
						
										}}
									>
										{/* <UploadImage /> */}
										<AvatarComponent
											firstName="johny"
											lastName="english"
											imageUrl={basicGeneralInfo?.profilePicture}
											isEditing={true}
											isProfile={true}
										/>
									</div>
								</Col>
								<Col span={14}>
									<Row gutter={[8, 8]}>
										<Col span={24}>
											<Text type="secondary">Name</Text>
										</Col>
										<Col span={24}>
											{
												<InputWithPrefix
													disabled={true}
													placeholder="Enter your name"
													prefixData={prefixDataForName}
													value={basicGeneralInfo?.fullname}
													changeValue={(e) => {
														setBasicGeneralInfo({
															...basicGeneralInfo,
															fullname: e,
															isGeneralInfoEdited: true,
														});
													}}
													type="text"
													defaultPrefixSelector={<DefaultPrefixSelector />}
												/>
											}
										</Col>
									</Row>
								</Col>
							</Row>
							{/* <Divider plain={true} /> */}
							<br />
							<Row gutter={[8, 8]}>
								<Col span={24}>
									<Row>
										<Col span={20}>
											<Text>Share My Contacts</Text>
										</Col>
										<Col span={4}>
											<Row justify="end">
												<EnableDisableSwitch />
											</Row>
										</Col>
									</Row>
								</Col>
								<Col span={24}>
									<Text type="secondary">
										By enabling, only your Name, Property Details and Interests
										will be shared publicly
									</Text>
								</Col>
							</Row>
							<br />
							<Row gutter={[8, 32]}>
								<Col lg={22} xl={12}>
									<Row gutter={[8, 8]}>
										<Col span={24}>
											<Text type="secondary">Date Of Birth</Text>
										</Col>
										<Col span={24}>
											<DatePicker
												picker="date"
												style={{ width: '100%' }}
												disabledDate={(current) =>
													current.isAfter(moment().subtract(1, 'day'))
												}
												placeholder="Select month"
												defaultValue={moment(generalInfo?.birth_date)}
												format={(value) => moment(value).format('DD MMM YYYY')}
												onChange={(date: any, string: any) => {
													setBasicGeneralInfo({
														...basicGeneralInfo,
														dateOfBirth: moment(date).format('DD MMM YYYY'),
														isGeneralInfoEdited: true,
													});
												}}
											/>
										</Col>
									</Row>
								</Col>
								<Col lg={24} xl={12}>
									<Row gutter={[8, 8]}>
										<Col span={24}>
											<Text type="secondary">Phone Number</Text>
										</Col>
										<Col span={24}>
											<Input.Group>
												<Row>
													<Col span={24}>
														<InputFieldForEmail
															placeholder="Enter phone number"
															onChange={(e: any) =>
																setBasicGeneralInfo({
																	...basicGeneralInfo,
																	phoneNumber: e.target.value,
																	phoneNumberChanged: true,
																})
															}
															value={basicGeneralInfo?.phoneNumber}
															prefix={
																<SelectInput
																	style={{ width: 70 }}
																	listHeight={150}
																	placement={isMobile ? "topLeft" : "bottomRight"}
																	className="profile-select-phone-number short"
																	showSearch
																	filterOption={(input, option) => {
																		if (option?.children) {
																			return !!option.value
																				?.toString()
																				?.toLowerCase()
																				.startsWith(input?.toLowerCase());
																		} else return false;
																	}}															
																	suffixIcon={<SolidArrow />}
																	dropdownStyle={{
																		minWidth: 300,
																		maxWidth: 400,
																	}}
																	defaultValue={basicGeneralInfo?.callingCode}
																	optionLabelProp="label"
																	onChange={(e: any) => {
																		const countryCodeSelected =
																			countriesListProvider.filter(
																				(i) => i.name === e
																			)[0].id;
																		setBasicGeneralInfo({
																			...basicGeneralInfo,
																			countryCode: countryCodeSelected,
																			phoneNumberChanged: true,
																		});
																	}}
																>
																	<>
																		{/* <option selected>{generalInfo?.dial_code}</option> */}
																		{countriesListProvider
																			?.sort((a, b) =>
																				a.name.localeCompare(b.name)
																			)
																			?.map((i, j) => {
																				return (
																					<Option
																						value={i.name}
																						key={j}
																						label={i.calling_code}
																					>
																						{`${i.name}(+${i.calling_code})`}
																					</Option>
																				);
																			})}
																	</>
																</SelectInput>
															}
															suffix={
																basicGeneralInfo?.phoneNumberChanged ? (
																	<>
																		<Button
																			type="text"
																			onClick={() => {
																				void sendOtpToNewNumber();
																			}}
																			loading={showLoadingForOtp}
																			className="profile-verify"
																		>
																			Verify
																		</Button>
																	</>
																) : (
																	<></>
																)
															}
														/>
													</Col>
												</Row>
											</Input.Group>
										</Col>
									</Row>
								</Col>
								<Col lg={22} xl={12}>
									<Row gutter={[8, 8]}>
										<Col span={24}>
											<Text type="secondary">Year Of Study</Text>
										</Col>
										<Col span={24}>
											<SelectInput
												
												listHeight={500}
												suffixIcon={<SolidArrow />}
												className="profile-select"
												defaultValue={basicGeneralInfo?.yearOfStudy.name}
												onChange={(e) => {
													// console.log(e);
													const item =
														yearOfStudyList?.filter((i) => i.id === e)[0] ?? '';
													setBasicGeneralInfo({
														...basicGeneralInfo,
														yearOfStudy: item,
														isGeneralInfoEdited: true,
													});
													// triggerChangeInGeneralInfo();
												}}
											>
												{yearOfStudyList?.map((i, j) => {
													return (
														<Option value={i.id} key={i.id}>
															{i.name}
														</Option>
													);
												})}
											</SelectInput>
										</Col>
									</Row>

									{/* <InputFieldForEmail type="text" value={basicGeneralInfo?.yearOfStudy} /> */}
								</Col>
								<Col lg={22} xl={12}>
									<Row gutter={[8, 8]}>
										<Col span={24}>
											<Text type="secondary">Email Address</Text>
										</Col>
										<Col span={24}>
											<InputFieldForEmail
												type="email"
												value={basicGeneralInfo?.mailId}
												onChange={(e: any) => {
													setBasicGeneralInfo({
														...basicGeneralInfo,
														mailId: e.target.value,
														isGeneralInfoEdited: true,
													});
													// triggerChangeInGeneralInfo();
												}}
												disabled={true}
											/>
										</Col>
									</Row>
								</Col>
								<Col lg={22} xl={12}>
									<Row gutter={[8, 8]}>
										<Col span={24}>
											<Text type="secondary">Institution</Text>
										</Col>
										<Col span={24}>
											<SelectInput
												
												listHeight={500}
												dropdownStyle={{ width: 'auto' }}
												className="profile-select"
												suffixIcon={<SolidArrow />}
												defaultValue={basicGeneralInfo?.institution.name}
												onChange={(e) => {
													const item =
														institutionList?.filter((i) => i.id === e)[0] ?? '';

													setBasicGeneralInfo({
														...basicGeneralInfo,
														institution: item,
														isGeneralInfoEdited: true,
													});
													// triggerChangeInGeneralInfo();
												}}
											>
												{institutionList?.map((i, j) => {
													return (
														<Option value={i.id} key={i.id}>
															<Text>{i.name}</Text>
														</Option>
													);
												})}
											</SelectInput>
										</Col>
									</Row>

									{/* <InputFieldForEmail type="text" value={basicGeneralInfo?.institution} /> */}
								</Col>
								<Col lg={22} xl={12}>
									<Row gutter={[8, 8]}>
										<Col span={24}>
											<Text type="secondary">Nationality</Text>
										</Col>
										<Col span={24}>
											<SelectInput
												
												listHeight={500}
												className="profile-select"
												suffixIcon={<SolidArrow />}
												disabled
												defaultValue={basicGeneralInfo?.nationality.citizenship}
												onChange={(e) => {
													const item =
														countriesListProvider?.filter(
															(i) => i.id === e
														)[0] ?? '';
													setBasicGeneralInfo({
														...basicGeneralInfo,
														nationality: item,
														isGeneralInfoEdited: true,
													});
													// triggerChangeInGeneralInfo();
												}}
											>
												{countriesListProvider?.map((i, j) => {
													return (
														<Option value={i.id} key={i.id}>
															{i.name}
														</Option>
													);
												})}
											</SelectInput>
										</Col>
									</Row>

									{/* <br /> */}

									{/* <InputFieldForEmail type="text" value={basicGeneralInfo?.nationality} /> */}
								</Col>
								<Col span={24}>
									<Row gutter={[8, 8]}>
										<Col span={24}>
											<Text type="secondary">Interests And Hobbies</Text>
										</Col>
										<Col span={24}>
											<SelectInput
												mode="tags"
												tagRender={tagRender}
												listHeight={500}
												
												onChange={handleSelectChangeForUpdate}
												placeholder="Search for your interst / hobby"
												className="profile-select interests"
												value={interestsListUpdated?.map((i: any) => i.name)}
											>
												{interestListFlattened?.map((i) => (
													<Option value={i.name} key={i.name}>
														{i.name}
													</Option>
												))}
											</SelectInput>
										</Col>
									</Row>
									{/* <hr /> */}
								</Col>
							</Row>
						</>
					)}
				</Col>
				{isMobile && <Divider className="no-color" />}
				<Col span={24} md={11} offset={isMobile ? 0 : 1}>
					<Row gutter={[0, 32]}>
						<Col
							span={24}
							style={{
								borderRadius: '1rem',
								backgroundColor: '#fff',
								boxShadow: '8px 10px 35px #0000000A',
							}}
						>
							{showGuarantorInformation ? (
								<div style={{ padding: '2rem' }}>
									<GeneralInfoHeader Icon={()=><GuarantorIcon />} text={"Next Of Kin"} Button={()=><ButtonBase
													type={ButtonTypes.TERTIARY}
													text="Edit"
													onclick={() =>
														setShowGuarantorInformation(
															!showGuarantorInformation
														)
													}
													ClassName="font-bold"
												/>}/>
									{/* <Row>
										<Col span={2} style={{ paddingBlock: '7px' }}>
											<GuarantorIcon />
										</Col>
										<Col span={19}>
											<Title level={5} className="font-regular">
												Next Of Kin
											</Title>
										</Col>
										<Col span={3}>
											<Row justify="end" align="middle">
												<ButtonBase
													type={ButtonTypes.TERTIARY}
													text="Edit"
													onclick={() =>
														setShowGuarantorInformation(
															!showGuarantorInformation
														)
													}
													ClassName="font-bold"
												/>
											</Row>
										</Col>
									</Row> */}
									<Divider className="no-color-top" />
									<Row>
										<Col span={12}>
											<TitleAndText title="Name" text={guarantorInfo?.name} />
										</Col>
										<Col span={12}>
											<Row justify="end">
												<Col span={24}>
													<TitleAndText
														title="Phone Number"
														text={`+${guarantorInfo?.callingCode} ${guarantorInfo?.mobileNumber}`}
													/>
												</Col>
											</Row>
										</Col>
										<Divider className="no-color-top" />
										<Col span={24}>
											<TitleAndText
												title="Email Address"
												text={guarantorInfo?.email}
											/>
										</Col>
									</Row>
								</div>
							) : (
								<>
					
									<div style={{ padding: '2rem' }}>
										<GeneralInfoHeader text={"Next Of Kin"} Icon={()=><GuarantorIcon />} Button={()=><ButtonBase
														type={ButtonTypes.PRIMARY}
														htmlType="submit"
														text={
															guarantorInfo?.isGuarantorEdited
																? 'Update'
																: 'Close'
														}
														loading={buttonLoading}
														onclick={() => {
															if (guarantorInfo?.isGuarantorEdited) {
																void updateGuarantoInfo();
															} else {
																setShowGuarantorInformation(
																	!showGuarantorInformation
																);
															}
														}}
													/>}/>
										{/* <Row>
											<Col span={2} style={{ paddingBlock: '7px' }}>
												<GuarantorIcon />
											</Col>
											<Col span={19}>
												<Title level={5} className="font-regular">
													Next Of Kin
												</Title>
											</Col>
											<Col span={3}>
												<Row justify="end" align="middle">
													<ButtonBase
														type={ButtonTypes.PRIMARY}
														htmlType="submit"
														text={
															guarantorInfo?.isGuarantorEdited
																? 'Update'
																: 'Close'
														}
														loading={buttonLoading}
														onclick={() => {
															if (guarantorInfo?.isGuarantorEdited) {
																void updateGuarantoInfo();
															} else {
																setShowGuarantorInformation(
																	!showGuarantorInformation
																);
															}
														}}
													/>
												</Row>
											</Col>
										</Row> */}
										<br />
										<Row gutter={[16, 20]}>
											<Col xs={22} sm={22} md={12}>
												<Row gutter={[8, 8]}>
													<Col span={24}>
														<Text
															type="secondary"
															className="profile__secondary"
														>
															Name
														</Text>
													</Col>
													<Col span={24}>
														<InputFieldForEmail
															required
															value={guarantorInfo?.name}
															name="Name"
															placeholder="Name"
															onChange={(e: any) =>
																setGuarantorInfo({
																	...guarantorInfo,
																	name: e.target.value,
																	isGuarantorEdited: true,
																})
															}
														
														/>
													</Col>
												</Row>
											</Col>
											<Col  xs={22} sm={22} md={12}>
												<Row gutter={[8, 8]}>
													<Col span={24}>
														<Text
															type="secondary"
															className="profile__secondary"
														>
															Phone Number
														</Text>
													</Col>
													<Col span={24}>
														
														<InputFieldForEmail
															placeholder="Enter phone number"
															onChange={(e: any) =>
																setGuarantorInfo({
																	...guarantorInfo,
																	mobileNumber: e.target.value,
																	isGuarantorEdited: true,
																})
															}
															value={guarantorInfo?.mobileNumber}
															prefix={
																
																<SelectInput
																	style={{ width: 70 }}
																	listHeight={isMobile?150:250}
																	className="profile-select-phone-number"
																	 placement={isMobile ? "topLeft" : "bottomRight"}
																	suffixIcon={<SolidArrow />}
																	 showSearch
																	filterOption={(input, option) => {
																		if (option?.children) {
																			return !!option.value
																				?.toString()
																				?.toLowerCase()
																				.startsWith(input?.toLowerCase());
																		} else return false;
																	}}
																	dropdownStyle={{
																		minWidth: 200,
																		maxWidth: 200,
																	}}
																	defaultValue={guardianDetails?.callingCode}
																	optionLabelProp="label"
																	onChange={(e: any) => {
																		const countryCodeSelectedForKin =
																			countriesListProvider.filter(
																				(i) => i.name === e
																			)[0].id;
																		setGuarantorInfo({
																			...guarantorInfo,
																			isGuarantorEdited: true,
																			countryCode: countryCodeSelectedForKin,
																		});
																	}}
																>
																	<>
																		{/* <option selected>{generalInfo?.dial_code}</option> */}
																		{countriesListProvider
																			.sort((a, b) =>
																				a.name.localeCompare(b.name)
																			)
																			?.map((i, j) => {
																				return (
																					<Option
																						value={i.name}
																						key={j}
																						label={i.calling_code}
																					>
																						{`${i.name}(+${i.calling_code})`}
																					</Option>
																				);
																			})}
																	</>
																</SelectInput>
															}
														/>
														
													</Col>
												</Row>
											</Col>
											<Col  xs={22} sm={22} md={12}>
												<Row gutter={[8, 8]}>
													<Col span={24}>
														<Text
															type="secondary"
															className="profile__secondary"
														>
															Email Address
														</Text>
													</Col>
													<Col span={24}>
														<InputFieldForEmail
															type="email"
															name="email"
															required
															value={guarantorInfo?.email}
															onChange={(e: any) => {
																setGuarantorInfo({
																	...guarantorInfo,
																	email: e.target.value,
																	isGuarantorEdited: true,
																});
															}}
														/>
													</Col>
												</Row>
											</Col>
											<Col span={24}>
												<Row align="middle" justify="center" gutter={[8, 8]}>
													{/* <Col span={1}> */}
													<Space direction="horizontal">
														<GuarantorWarning />
														<Text
															type="secondary"
															className="profile__secondary"
														>{`You can't use your personal details on the guarantor's information`}</Text>
													</Space>

													{/* </Col> */}
													{/* <Col> */}

													{/* </Col> */}
												</Row>
											</Col>
										</Row>
									</div>
								</>
							)}
						</Col>
						<Col
							span={24}
							style={{
								borderRadius: '1rem',
								backgroundColor: '#fff',
								boxShadow: '8px 10px 35px #0000000A',
							}}
						>
							{showRefundDetails ? (
								<div style={{ padding: '2rem' }}>
									<GeneralInfoHeader text={"Refund Details"} Icon={()=>	<RefundIcon />} Button={()=>	<ButtonBase
													type={ButtonTypes.TERTIARY}
													text="Edit"
													onclick={() =>
														setShowRefundDetails(!showRefundDetails)
													}
													ClassName="font-bold"
												/>}/>
									
									<Divider className="no-color-top" />
									<Row gutter={[16, 22]}>
										<Col xs={12}>
											<TitleAndText
												title="Account Type"
												text={refundDetails?.accountType}
											/>
										</Col>

										<Col span={12}>
											<TitleAndText
												title="Account Name"
												text={refundDetails?.accountHolderName}
											/>
										</Col>
										{refundDetails?.accountType !== BankAccountType.Mpesa && (
											<>
												<Col span={12}>
													<TitleAndText
														title="Bank Name"
														text={refundDetails?.bankName}
													/>
												</Col>

												<Col span={12}>
													<TitleAndText
														title="Swift Code"
														text={refundDetails?.swiftCode}
													/>
												</Col>
											</>
										)}
										<Col span={12}>
											<TitleAndText
												title="Account Number"
												text={refundDetails?.accountNumber}
											/>
										</Col>
										{refundDetails?.accountType ===
											BankAccountType.InternationalBankAccount && (
											<>
												<Col span={12}>
													<TitleAndText
														title="currency"
														text={refundDetails?.creditCurrency}
													/>
												</Col>
												<Col span={12}>
													<TitleAndText
														title="Country Name"
														text={refundDetails?.country.name}
													/>
												</Col>
											</>
										)}

										{refundDetails?.accountType ===
											BankAccountType.KenyanBankAccount && (
											<>
												<Col span={12}>
													<TitleAndText
														title="Bank Code"
														text={refundDetails?.bankCode}
													/>
												</Col>
												<Col span={12}>
													<TitleAndText
														title="Branch Code"
														text={refundDetails?.branchCode}
													/>
												</Col>
											</>
										)}
									</Row>
								</div>
							) : (
								<div style={{ padding: '2rem' }}>
									<GeneralInfoHeader text={"Refund Details"} Icon={()=><RefundIcon />} Button={()=><ButtonBase
													type={ButtonTypes.PRIMARY}
													text={
														refundDetails?.isRefundEdited ? 'Update' : 'Close'
													}
													loading={buttonLoading}
													// eslint-disable-next-line @typescript-eslint/no-misused-promises
													onclick={() => {
														if (refundDetails?.isRefundEdited) {
															void updateForRefund();
														} else {
															setShowRefundDetails(!showRefundDetails);
														}
													}}
												/>}/>
									
									<br />
									<Row gutter={[16, 16]}>
										<Col xs={22} sm={22} md={12}>
											<Row gutter={[8, 8]}>
												<Col span={24}>
													<Text type="secondary">Account Type</Text>
												</Col>
												<Col span={24}>
													<SelectInput
														
														suffixIcon={<SolidArrow />}
														defaultValue={refundDetails?.accountType}
														onChange={(e) => {
															setRefundDetails({
																...refundDetails,
																accountType: e,
																isRefundEdited: true,
															});
															// triggerChangeInRefund();
														}}
														className="profile-select"
													>
														
														<Option
															value={BankAccountType.InternationalBankAccount}
														>
															{BankAccountType.InternationalBankAccount}
														</Option>
														<Option value={BankAccountType.Mpesa}>
															{BankAccountType.Mpesa}
														</Option>
														<Option value={BankAccountType.KenyanBankAccount}>
															{BankAccountType.KenyanBankAccount}
														</Option>
													</SelectInput>
												</Col>
											</Row>

											{/* <InputFieldForEmail /> */}
										</Col>
										<Col xs={22} sm={22} md={12}>
											<Row gutter={[8, 8]}>
												<Col span={24}>
													<Text type="secondary">Account Holder Name</Text>
												</Col>
												<Col span={24}>
													<InputFieldForEmail
														value={refundDetails?.accountHolderName}
														onChange={(e: any) => {
															setRefundDetails({
																...refundDetails,
																accountHolderName: e.target.value,
																isRefundEdited: true,
															});
														}}
													/>
												</Col>
											</Row>
										</Col>
										<Col xs={22} sm={22} md={12}>
											<Row gutter={[8, 8]}>
												<Col span={24}>
													<Text type="secondary">Account Number</Text>
												</Col>
												<Col span={24}>
													<InputFieldForEmail
														value={refundDetails?.accountNumber}
														onChange={(e: any) => {
															setRefundDetails({
																...refundDetails,
																accountNumber: e.target.value,
																isRefundEdited: true,
															});
														}}
													/>
												</Col>
											</Row>
										</Col>
										<Col xs={22} sm={22} md={12}>
											{refundDetails?.accountType !== BankAccountType.Mpesa && (
												<Row gutter={[8, 8]}>
													<Col span={24}>
														<Text type="secondary">Bank Name</Text>
													</Col>
													<Col span={24}>
														<InputFieldForEmail
															value={refundDetails?.bankName}
															onChange={(e: any) => {
																setRefundDetails({
																	...refundDetails,
																	bankName: e.target.value,
																	isRefundEdited: true,
																});
															}}
														/>
													</Col>
												</Row>
											)}
										</Col>

										{refundDetails?.accountType !== BankAccountType.Mpesa && (
											<Col xs={22} sm={22} md={12}>
												<Row gutter={[8, 8]}>
													<Col span={24}>
														<Text type="secondary">Swift Code</Text>
													</Col>
													<Col span={24}>
														<InputFieldForEmail
															value={refundDetails?.swiftCode}
															onChange={(e: any) => {
																setRefundDetails({
																	...refundDetails,
																	swiftCode: e.target.value,
																	isRefundEdited: true,
																});
															}}
														/>
													</Col>
												</Row>
											</Col>
										)}
										{refundDetails?.accountType ===
										BankAccountType.KenyanBankAccount ? (
											<>
												<Col xs={22} sm={22} md={12}>
													<Row gutter={[8, 8]}>
														<Col span={24}>
															<Text type="secondary">Bank Code</Text>
														</Col>
														<Col span={24}>
															<InputFieldForEmail
																value={refundDetails?.bankCode}
																onChange={(e: any) => {
																	setRefundDetails({
																		...refundDetails,
																		bankCode: e.target.value,
																		isRefundEdited: true,
																	});
																}}
															/>
														</Col>
													</Row>
												</Col>
												<Col xs={22} sm={22} md={12}>
													<Row gutter={[8, 8]}>
														<Col span={24}>
															<Text type="secondary">Branch code</Text>
														</Col>
														<Col span={24}>
															<InputFieldForEmail
																value={refundDetails?.branchCode}
																onChange={(e: any) => {
																	setRefundDetails({
																		...refundDetails,
																		branchCode: e.target.value,
																		isRefundEdited: true,
																	});
																}}
															/>
														</Col>
													</Row>
												</Col>
											</>
										) : refundDetails?.accountType ===
										  BankAccountType.InternationalBankAccount ? (
											<>
													<Col xs={22} sm={22} md={12}>
													<Row gutter={[8, 8]}>
														<Col span={24}>
															<Text type="secondary">Credit Currency</Text>
														</Col>
														<Col span={24}>
															<SelectInput
																suffixIcon={<SolidArrow />}
																defaultValue={refundDetails?.creditCurrency}
																onChange={(e: any) => {
																	setRefundDetails({
																		...refundDetails,
																		creditCurrency: e,
																		isRefundEdited: true,
																	});
																}}
																
																className="profile-select"
															>
																{currienciesList?.map((i, j) => {
																	return (
																		<Option value={i} key={j}>
																			{i}
																		</Option>
																	);
																})}
															</SelectInput>
														</Col>
													</Row>
													{/* <InputFieldForEmail value={refundDetails?.creditCurrency} /> */}
												</Col>
												<Col xs={22} sm={22} md={12}>
													<Row gutter={[8, 8]}>
														<Col span={24}>
															<Text type="secondary">Country</Text>
														</Col>
														<Col span={24}>
															<SelectInput
														
																suffixIcon={<SolidArrow />}
																defaultValue={refundDetails?.country?.name}
																onChange={(e: any) => {
																	const item = countriesListProvider?.filter(
																		(i) => i.id === e
																	)[0];
																	setRefundDetails({
																		...refundDetails,
																		country: item,
																		isRefundEdited: true,
																	});
																}}
																className="profile-select"
															>
																{countriesListProvider?.map((i, j) => {
																	return (
																		<Option value={i.id} key={j}>
																			{i?.name}
																		</Option>
																	);
																})}
															</SelectInput>
														</Col>
													</Row>
												</Col>
											</>
										) : (
											<></>
										)}
									</Row>
								</div>
							)}
						</Col>
					</Row>
				</Col>
				{isMobile && <Divider className="no-color" />}

				{/* verify otp modal */}

				<ModalComponent
					okButtonText="Verify"
					cancelButtonText="cancel"
					buttonLoading={showLoadingForOtp}
					onButtonOkClick={async () => {
						const params = {
							otp: otp ?? '',
						};
						const response = await verifyOTP(params);
						if (response === 200) {
							showHideVerifyOTPModal();
							setOtp(undefined);
							setBasicGeneralInfo({
								...basicGeneralInfo,
								phoneNumberChanged: false,
							});
						}
					}}
					shouldModalOpen={showHideVerifyOTPModal}
					visible={verifyOTPModal}
				>
					<Form>
						<Row gutter={[16, 16]}>
							<label>Verify OTP sent to your phone number</label>
							<InputFieldForEmail
								placeholder="Enter OTP"
								onChange={(e: any) => setOtp(e.target.value)}
							/>
						</Row>
						<br />
					</Form>
				</ModalComponent>
			</Row>
		</>
	);
};

export default Profile;
