import { useState } from 'react';
import { deserialize } from 'serializr';
// import { generatePath } from 'react-router-dom';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Notices } from 'models/Notices/notices.model';
import { Pagination } from './../../models/Pagination/pagination.model';
import useSWR from 'swr';
import Moment from 'moment';
// import Notification from 'shared/components/Notification';
const fetcher = async (url: string, params: {}) =>
	await axiosInstance.get(url, { params }).then((res) => res.data);
export const useData = (params?: {
	search?: string;
	month?: number;
	year?: number;
}) => {
	const { data: response, error } = useSWR(
		[ApiRoutes.NOTICES, params],
		fetcher
	);
	let noticesData;
	// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
	if (response) {
		noticesData = deserialize(Notices, response.data) as Notices[];
	}

	return {
		noticesData,
		error,
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		isLoading: !error && !response,
	};
};
const NoticesService = () => {
	const [loading, setLoading] = useState<boolean>(false);

	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const [NoticesData, setNoticesData] = useState<Notices[]>([]);
	const [filteredNoticesData, setFilteredNoticesData] = useState<Notices[]>([]);
	const [NoticesPagination, setNoticesPagination] =
		useState<Pagination | null>();

	const fetchNotices = async (params?: {
		search?: string;
		month?: number;
		year?: number;
	}) => {
		try {
			setLoading(true);
			setButtonLoading(true);
			const response = await axiosInstance.get(ApiRoutes.NOTICES, { params });
			const notices = deserialize(Notices, response.data.data) as Notices[];
			// const pagination = deserialize(Pagination, response.data["meta"])
			// setNoticesPagination(pagination)
			setNoticesData(notices);
			setFilteredNoticesData(notices);
			console.log(notices);
		} catch (error) {
			setNoticesPagination(null);
			setNoticesData([]);
		} finally {
			setLoading(false);
		}
	};

	// create a function which will check if the data is in the context state , if there is the data then dont
	// do a api call but rather send the data back from the context
	// if there doesnt exist anything in the context
	// then call the api
	// get the data
	// save it in the context
	// and send back

	const filterNotices = (date: string) => {
		// If the date is cleared set the data back
		if (date !== '') {
			setNoticesData(filteredNoticesData);
			return;
		}
		// If the date as well as data is present ,
		// filter the data based on the date then
		if (NoticesData !== null) {
			const filter = filteredNoticesData.filter((item) => {
				const itemDate = Moment(item.createdAt).format('YYYY-MM-DD');
				if (itemDate === date) return item;
				return null;
			});
			if (filter.length > 0) {
				setNoticesData(filter);
			} else {
				setNoticesData([]);
			}
		} else {
			return 'Data not found';
		}
	};

	// const createNotices = async (Notices: Notices) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { Notices: serialize(Notices, Notices) }
	//         const response = await axiosInstance.post(ApiRoutes.NOTICES, payload)
	//         const data = deserialize(Notices, response.data["notices"]) as Notices;
	//         setNotices(data)
	//     } catch (error) {
	//         setNotices(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const updateNotices = async (Notices: Notices) => {
	//     try {
	//         setButtonLoading(true)
	//         const payload = { Notices: serialize(Notices, Notices) }
	//         const API_URL = generatePath(ApiRoutes.NOTICES_SPECIFIC, { NoticesId: String(Notices.id) })
	//         const response = await axiosInstance.put(API_URL, payload)
	//         const data = deserialize(Notices, response.data["notices"]) as Notices;
	//         setNotices(data)
	//     } catch (error) {
	//         setNotices(null)
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const deleteNotices = async (NoticesId: string) => {
	//     try {
	//         setButtonLoading(true)
	//         const API_URL = generatePath(ApiRoutes.NOTICES_SPECIFIC, { NoticesId })
	//         await axiosInstance.delete(API_URL)
	//         return true
	//     } catch (error) {
	//         return false
	//     } finally {
	//         setButtonLoading(false)
	//     }
	// }

	// const showNotices = async (NoticesId: string) => {
	//     try {
	//         setLoading(true)
	//         const API_URL = generatePath(ApiRoutes.NOTICES_SPECIFIC, { NoticesId })
	//         const response = await axiosInstance.get(API_URL)
	//         const data = deserialize(Notices, response.data["notices"]) as Notices;
	//         setNotices(data)
	//     } catch (error) {
	//         setNotices(null)
	//     } finally {
	//         setLoading(false)
	//     }
	// }

	return {
		loading,
		Notices,
		buttonLoading,
		fetchNotices,
		NoticesPagination,
		NoticesData,
		filterNotices,
	};
};

export default NoticesService;
