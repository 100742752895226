import { StatusId } from '../StatusId/statusId.model';

import { serializable, alias, object, primitive } from 'serializr';

export class OnboardingDocuments {
	@serializable(alias('id', primitive()))
	id?: string;

	@serializable(alias('id_number', primitive()))
	idNumber?: string;

	@serializable(alias('expiry_date', primitive()))
	expiryDate?: string;

	@serializable(alias('file_path', primitive()))
	filePath?: string;

	@serializable(alias('status_id', object(StatusId)))
	statusId?: StatusId;

	@serializable(alias('doc_type', primitive()))
	docType?: string;

	@serializable(alias('doc_type_id', primitive()))
	docTypeId?: number;

	@serializable(alias('doc_name_id', primitive()))
	docNameId?: number;

	@serializable(alias('doc_name', primitive()))
	docName?: string;

	@serializable(alias('doc_for', primitive()))
	docFor?: string;

	@serializable(alias('is_blacklisted', primitive()))
	isBlacklisted?: string;
}
