import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import { NavigationRoutes } from '../../../routes/routeConstants/appRoutes';


const RequireAuth = ({ children }: { children: JSX.Element }) => {
	const Authentication = (props: any) => {

		const { authenticated } = AuthContext();
		const location = useLocation();
		const navigate = useNavigate();
		const userData = JSON.parse(localStorage.getItem('user_token') as string);
		useEffect(() => {
			if (!userData && location.pathname !== NavigationRoutes.LOGIN) {
				return navigate(NavigationRoutes.LOGIN);
			}
			if (
				!userData &&
				authenticated === false &&
				location.pathname !== NavigationRoutes.LOGIN
			) {
				return navigate(NavigationRoutes.LOGIN);
			} else {
				//
			}
		}, [props, authenticated, userData]);



		return children;
	};

	return <Authentication />;
};

export const isAuthenticated = (component: JSX.Element) => {
	return RequireAuth({ children: component });
};

export default isAuthenticated;
