import { serializable, alias, primitive } from 'serializr';

export class RoomId {
	@serializable(alias('room_number', primitive()))
	roomNumber?: string;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('room_type', primitive()))
	roomType?: string;
}
