import React from 'react';
import {
	Navigate,
	Route,
	Routes,
} from 'react-router-dom';

import RegisterForm from '../../views/Auth/RegisterForm';
import LoginForm from '../../views/Auth/LoginForm';
import { RouterProps } from '../../shared/types/route.type';
import {
	AppRoutes,
	NavigationRoutes,
} from '../../routes/routeConstants/appRoutes';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPasssword';
import VerifyEmail from './VerifyEmail';


const authRouter = (): JSX.Element => {
	const routes: RouterProps[] = [
		{ path: AppRoutes.REGISTER, component: <RegisterForm /> },
		{ path: AppRoutes.LOGIN, component: <LoginForm /> },
		{ path: AppRoutes.FORGOT_PASSWORD, component: <ForgotPassword />},
		{ path: AppRoutes.FORGOT_PASSWORD_OTP, component: <VerifyEmail />},
		{ path: AppRoutes.RESET_PASSWORD, component: <ResetPassword />},

	];

	return (
		<Routes>
			{routes.map(({ component, ...routerProps }, index) => (
				<Route {...routerProps} element={component} key={index} />
			))}
			<Route path="*" element={<Navigate to={NavigationRoutes.LOGIN} />} />
		</Routes>
	);
};

export default authRouter;
