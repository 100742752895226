import React from "react";
import { Skeleton, Row, Col } from "antd";
// import "./tableLoader.scss"

export const MaintenanceTicketLoader = () => {




    return <div>
        <Row>
            <Col span={24}>
                <Skeleton.Button key={0} active={true} size="small" />
            </Col>
            <Col span={24}>
                <Skeleton.Button key={0} active={true} size="large" />
            </Col>
            <Col span={24}>
                <Skeleton.Button key={0} active={true} size="large" />
            </Col>
        </Row>




    </div>
}

