import { SelectProps,Select } from 'antd'
import React from 'react'

export default function SelectInput(props:SelectProps) {
    const {onClick=()=>{}}=props
  return (
    <Select onClick={(e:any)=>{
        e.stopPropagation();
        onClick(e);
    }} 
    {...props}/>
  )
}
