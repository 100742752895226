import { serializable, alias, primitive } from 'serializr';

export class Data {
	@serializable(alias('title', primitive()))
	title?: string;

	@serializable(alias('message', primitive()))
	message?: string;

	@serializable(alias('type', primitive()))
	type?: string;
}
