import React from 'react';
import { Row, Col, Tabs } from 'antd';
// Styles
import './bookings.scss';
import WaitingListView from './WaitingListView';
import BookingsComponent from './BookingsComponent';
import { screenTypes } from 'enums/screenTypes';
import { Image } from 'shared/components/BackgroundImage/Image';
import Navbar from 'shared/components/Navbar';
import useResponsive from 'shared/Hooks/useResponsive';
import background from "resources/images/containerBackground.png"

// tabs
// const { TabPane } = Tabs;

const items = [
	{
		label: screenTypes.BOOKINGS,
		key: screenTypes.BOOKINGS,
		children: <BookingsComponent />,
	}, // remember to pass the key prop
	{
		label: screenTypes.WAITING_LIST,
		key: screenTypes.WAITING_LIST,
		children: <WaitingListView />,
	},
];
export const Bookings = () => {
	const { isMobile } = useResponsive();
	return (
		<>
			{!isMobile && (
				<Image
					src={background}
				>
					<Navbar />
				</Image>
			)}
			<Row className="bookingsMain">
				<Col style={{ flexGrow: '1' }}>
					<Tabs
						items={items}
						defaultActiveKey="item-1"
						className="bookings-tabs"
					/>
				</Col>
			</Row>
		</>
	);
};
