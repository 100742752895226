/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect } from 'react';
import TableUpdated from 'shared/components/Table';
import { Image } from 'shared/components/BackgroundImage/Image';
import { Row, Col, Typography, Select, UploadFile, Space } from 'antd';
import { MaintenanceCustom as MaintenanceIcon } from 'resources/Icons';
import ModalComponent, { ModalSize } from 'shared/components/Modal';
import UploadImage from 'shared/components/UploadImage';
// import TextArea from 'antd/lib/input/TextArea';
import type { ColumnsType, TableProps } from 'antd/es/table';
import Navbar from 'shared/components/Navbar';
import MaintenanceService from 'services/MaintenanceService/Maintenance.service';
import { SorterDirection } from 'views/Financials/Payments';
import { useTitleContext } from 'context/TitleComponentContext';
import { TitleComponentPayments } from 'views/Financials/TitleComponentPayments';
import useResponsive from 'shared/Hooks/useResponsive';
import Tags from 'shared/components/Tag';
import moment from 'moment';
import { RcFile } from 'antd/lib/upload';
import './maintenance.scss';
import { MaintenanceTicketLoader } from './MaintenanceTicketLoader';
import { Maintenance } from 'models/Maintenance/maintenance.model';
import { TagText } from 'shared/components/Cards';
import { userDetails } from 'shared/Hooks/user';
import { userCheckInStatus } from 'enums/userStatusTypes';
import MaintenanceModal from './MaintenanceModal';
import { DateFormats } from 'enums/dateFormat';
import background from "resources/images/containerBackground.png"
import SelectInput from 'shared/components/Select/SelectInput';
const { Text } = Typography;
const { Option } = Select;

type DataType = {
	key: React.Key;
	index: number;
	ticketCategory: string;
	description: string;
	raisedOn: number;
	raisedBy: string;
	status: string;
};


const columns: ColumnsType<DataType> = [
	{
		title: 'ID',
		key: 'index',
		render: (text, record, index) => index + 1,
	},
	{
		title: 'What',
		dataIndex: 'ticketCategory',
		sorter: true,
	},
	{
		title: 'Description',
		dataIndex: 'description',
		sorter: true,
		width: '30%'
	},
	{
		title: 'When',
		dataIndex: 'raisedOn',
		sorter: true,
		render: (raisedOn) => {
			const updatedDate = moment(raisedOn).format(DateFormats.SECONDARY);
			return <>{updatedDate}</>;
		  },
	},
	{
		title: 'By',
		dataIndex: 'raisedBy',
		sorter: true,
	},
	{
		title: 'Status',
		dataIndex: 'status',
		sorter: true,
		render: (text) => <Tags tagText={text} />,
	},
];

// 	{
// 		key: '1',
// 		name: 'John Brown',
// 		chinese: 98,
// 		math: 60,
// 		english: 70,
// 	},
// 	{
// 		key: '2',
// 		name: 'Jim Green',
// 		chinese: 98,
// 		math: 66,
// 		english: 89,
// 	},
// 	{
// 		key: '3',
// 		name: 'Joe Black',
// 		chinese: 98,
// 		math: 90,
// 		english: 70,
// 	},
// 	{
// 		key: '4',
// 		name: 'Jim Red',
// 		chinese: 88,
// 		math: 99,
// 		english: 89,
// 	},
// ];

enum Columns {
	ticketCategory = 'what',
	description = 'description',
	raisedOn = 'when',
	raisedBy = 'by',
	status = 'status',
}

export const MaintenanceScreen = () => {
	const { user } = userDetails();
	const [showModal, setShowModal] = useState(false);
	const showHideModal = () => setShowModal(!showModal);
	const [currentPage, setCurrentPage] = useState<number | undefined>();
	const [order, setOrder] = useState<string>();

	const [fileListUpdate, setFileListUpdate] = useState<UploadFile[] | any>([]);
	const [orderField, setOrderField] = useState<string | undefined>();
	const [ticketStatus, setTicketStatus] = useState(false);
	const { date, search } = useTitleContext();
	const [ticketId, setTicketId] = useState<string>();
	const { isMobile } = useResponsive();
	const {
		loading,
		fetchMaintenances,
		Maintenances,
		maintenanceTicketLoader,
		editMaintenanceTicketImage,
		pages,
		getTicketCategories,
		getTicketLocations,
		getTicketData,
		ticketData,
		editMaintenanceTicketStatus,
	} = MaintenanceService();

	const onChange: TableProps<DataType>['onChange'] = (
		pagination,
		filters,
		sorter,
		extra
	) => {
		setCurrentPage(pagination.current);
		// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
		// @ts-ignore
		setOrder(SorterDirection[sorter.order]);
		// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
		// @ts-ignore
		setOrderField(sorter.order !== undefined ? Columns[sorter.field] : null);
	};
	// initial call to get the data
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		fetchMaintenances();
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		getTicketCategories();
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		getTicketLocations();
	}, []);
	useEffect(() => {
		// if (selectedTab !== screenTypes.MAINTENANCE) return;
		let year, month;
		if (date) {
			const parsedDate = moment(date);
			year = parsedDate.year();
		  	month = parsedDate.month() + 1;
		}
		if (date !== undefined) {
			void fetchMaintenances({ year, month });
		}
		if (!date){
			void fetchMaintenances();
		}
	}, [date]);
	useEffect(() => {
		if (
			currentPage !== undefined ||
			order !== undefined ||
			orderField !== undefined ||
			search !== undefined
		) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			fetchMaintenances({
				page: currentPage,
				sort_by: orderField,
				sort_dir: order,
				search,
			});
		}
	}, [currentPage, order, orderField, search]);

	const changeTicketModal = () => setTicketStatus(!ticketStatus);
	const changeTicketStatus = async (id: any) => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		await editMaintenanceTicketStatus({
			ticket_id: ticketId ?? '',
			status_id: id ?? '',
		});
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		getTicketInfo({ id: ticketId });
	};

	const getTicketInfo = async (id: any) => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		// changeTicketModal();
		setTicketStatus(true);
		await getTicketData({ ticketId: id?.id });
		setTicketId(id.id);
	};

	const uploadFileFunction = (file: RcFile): string => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		editMaintenanceTicketImage({ attachment: file, ticketId }).then((res) => {
			console.log(res);
		});

		return '';
	};
	useEffect(() => {
		const imageFiles = [];
		for (const i in ticketData?.attachments) {
			// @ts-expect-error
			imageFiles.push({ thumbUrl: ticketData?.attachments[i] });
		}
		setFileListUpdate(imageFiles);
	}, [ticketData]);

	const MobileResponsiveCards = ({
		data,
		onRowClick,
	}: {
		data: Maintenance[];
		onRowClick: (i: any) => Promise<any>;
	}) => {
		return (
			<>
				{data?.map((item, index) => (
					<Row
						key={index}
						align="middle"
						style={{ paddingBottom: '0.5rem' }}
						onClick={() => {
							void onRowClick(item);
						}}
					>
						<Col span={18} xs={15} xl={18}>
							<Row gutter={[16, 5]} justify="start">
								<Col span={24}>
									<Text>{item?.ticketCategory}</Text>
								</Col>
								<Col span={24}>
									<Text type="secondary">{item?.description}</Text>
								</Col>
							</Row>
						</Col>
						<Col span={6} xs={9} xl={6}>
							<Row justify="end" align="middle" gutter={[16, 5]}>
								<Col>
									<Tags tagText={item?.status as TagText} />
								</Col>
								<Col>
									<Text type="secondary">
										{moment(item?.raisedOn).format(DateFormats.SECONDARY)}
									</Text>
								</Col>
							</Row>
						</Col>
					</Row>
				))}
			</>
		);
	};

	return (
		<>
			<ModalComponent
				className="maintenance-ticket-modal"
				shouldModalOpen={changeTicketModal}
				visible={ticketStatus}
				size={ModalSize.SMALL}
				title={
					<Space direction="horizontal" className="ticket-modal-title">
						{isMobile ? <label className='ticket-modal-title-mobile'>Ticket Number {!maintenanceTicketLoader? ticketData?.ticketId:''}</label>: <label>Ticket Number {!maintenanceTicketLoader? ticketData?.ticketId:''}</label>}
				
					</Space>
				}
				okButtonText="Make Payment"
				cancelButtonText="Cancel"
				showFooter={false}
				onButtonOkClick={() => console.log('clicked')}
			>
				{maintenanceTicketLoader ? (
					<>
						<MaintenanceTicketLoader />
					</>
				) : (
					<>
						<Row gutter={[8, 8]}>
							<Col span={12}>
								<p className="p-noBottomPadding">Raised On</p>
								<h3>{moment(ticketData?.raisedOn).format(DateFormats.SECONDARY)}</h3>
							</Col>
							<Col span={12}>
								<p className="p-noBottomPadding">Location</p>
								<h3>{ticketData?.locationName}</h3>
							</Col>
						</Row>
						<Space direction="vertical" style={{ width: '100%' }}>
							<Row gutter={[8, 8]} style={{ width: '100%' }} >
								<Col span={24}>
									<p>Description</p>
									<h3>{ticketData?.description}</h3>
								</Col>
							</Row>
							<Row gutter={[8, 8]} style={{ width: '100%' }}>
								<Col span={24}>
									<p className="p-noBottomPadding">Status</p>
									<SelectInput
										className="profile-select"
										placeholder="Change Status"
										defaultValue={ticketData?.status}
										onChange={changeTicketStatus}
									>
										{ticketData?.statuses &&
											ticketData?.statuses.map((i) => (
												<Option value={i.id} key={i.id}>
													{i.name}
												</Option>
											))}
									</SelectInput>
								</Col>
							</Row>
							<Row gutter={[8, 8]} style={{ width: '100%' }}>
								<Col span={24}>
									<p className="p-noBottomPadding">Status</p>
									<h3>{ticketData?.ticketCategory}</h3>
									<UploadImage
										setFileList={setFileListUpdate}
										fileList={fileListUpdate}
										allowDeletion={false}
										uploadFunction={(i) => uploadFileFunction(i)}
									/>
								</Col>
							</Row>
						</Space>
					</>
				)}
			</ModalComponent>
			{!isMobile && (
				<Image
					src={background}
				>
					{!isMobile && <Navbar />}
				</Image>
			)}
			<Row className="main-container maintenance">
				<Col span={24}>
					<Row gutter={8} align="middle">
						<Col>
							<MaintenanceIcon color={'#65BCB0'} />
						</Col>
						<Col className='maintenance-heading'>
							<h2>Maintenance Tickets</h2>
						</Col>
					</Row>
				</Col>
				<MaintenanceModal showHideModal={showHideModal} showModal={showModal} />

				<Col span={24}>
					{isMobile ? (
						<>
							<TitleComponentPayments
								onclick={showHideModal}
								buttonText={<MaintenanceIcon color="white"/>}
								placeholder='Search by tickets'
							/>
							<MobileResponsiveCards
								data={Maintenances}
								onRowClick={getTicketInfo}
							/>
						</>
					) : (
						<TableUpdated
							columns={columns}
							data={Maintenances}
							onchange={onChange}
							title={
								<TitleComponentPayments
									onclick={showHideModal}
									buttonText="Raise a Ticket"
									buttonDisabled={
										user?.status?.name === userCheckInStatus.CheckedOut
									}
									placeholder='Search by maintenance tickets'
								/>
							}
							isLoading={loading}
							total={pages}
							onRowClickable={true}
							onRowClick={getTicketInfo}
						/>
					)}
				</Col>
			</Row>
		</>
	);
};
