import { serializable, alias, primitive } from 'serializr';

export class ElectricityToken {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('mpesa_ref_num', primitive()))
	mpesaRefNum?: string;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('electricity_token_status', primitive()))
	electricityTokenStatus?: string;

	@serializable(alias('building_name', primitive()))
	buildingName?: string;

	@serializable(alias('room_number', primitive()))
	roomNumber?: string;

	@serializable(alias('meter_number', primitive()))
	meterNumber?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('token_num', primitive()))
	tokenNum?: string;

	@serializable(alias('status', primitive()))
	status?: 0 | 1 | 2;
}
