import React, { FC } from 'react';
import { ErrorMessage, useField } from 'formik';
import { Input } from 'antd';
import Error from '../Error';
import './input.scss';

type InputFieldProps = {
	type: string;
	name: string;
	placeholder?: string;
	showLabel?: boolean;
};
enum inputPlaceholders {
	email = 'Email',
	password = 'Password'
}
const InputField: FC<InputFieldProps> = (props) => {
	const { name, showLabel=true } = props;
	const [field, meta] = useField(props);

	const isInvalid = () => {
		if (meta.touched && (meta.error != null)) {
			return true;
		}
		return false;
	};

	return (
		<div className="input-group">
			{props.type === 'password' ? <Input.Password {...field}
				{...props}
				required
				status={isInvalid() ? 'error' : undefined} /> :
				<Input
					{...field}
					{...props}
					required
					status={isInvalid() ? 'error' : undefined}
				/>}
			{
				showLabel && 
				<label
				className={isInvalid() ? 'floating-label error' : 'floating-label'}
				>
				{field.name === 'email' ? inputPlaceholders.email : field.name ==='password'? inputPlaceholders.password:field.name}
				</label>
			}
			<ErrorMessage name={name}>
				{(message: string) => <Error message={message} />}
			</ErrorMessage>
		</div>
	);
};

export const InputFieldWithIcon: FC<any> = (props) => {
	return <Input {...props} className="inputWithIcons" />;
};
export const InputFieldWithIconForCommmunity: FC<any> = (props) => {
	return <Input {...props} className="inputWithIconsForCommunity" />;
};

export const InputFieldForDropdown: FC<any> = (props) => {
	return <Input {...props} className="dropdownInput" />;
};
export const InputFieldForEmail: FC<any> = (props) => {
	return <Input {...props} className="antWithEmail" />;
};

export default InputField;
